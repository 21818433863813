import { XMLParser } from 'fast-xml-parser';
export const parseKmlData = (kmlData, existingState) => {
  const parser = new XMLParser({
    ignoreAttributes: false,
    attributeNamePrefix: '',
    parseNodeValue: true,
    parseAttributeValue: false,
  });
  const { Placemark = [], Folder = [] } = parser.parse(kmlData)?.kml?.Document || {};
  const data = { kmlRegions: new Set(), kmlMarkers: new Set() };
  [
    ...(Placemark || []),
    ...((Folder || []).flatMap((folder) => folder?.Placemark || []) || []),
  ].forEach((mark) => {
    const name = mark.name;
    if (mark.Polygon || mark?.MultiGeometry?.Polygon) data.kmlRegions.add(name);
    if (mark.Point) data.kmlMarkers.add(name);
  });
  return ['kmlRegions', 'kmlMarkers'].reduce(
    (a, key) => ({
      ...a,
      [key]: Array.from(data[key])
        .filter(Boolean)
        .sort()
        .map(
          (name) => existingState?.[key]?.find((item) => item?.name === name) || { id: name, name }
        ),
    }),
    {}
  );
};
