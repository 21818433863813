import React, { useState, useEffect, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Text from '../../shared/basicInputs/Text';
import Wysiwyg from '../../shared/basicInputs/Wysiwyg';
import { useDispatch } from 'react-redux';
import { navigateToPluginPage } from '../../store/rootReducer';
import useMapData from '../hooks/useMapData';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import BreadcrumbLink from '../../shared/actionButton/components/BreadcrumbLink';
import log from '../../logger';
import { upsertInfo, upsertMarkerInfo } from '../api/mapsApi';

const MapsKmlEditor = ({ itemId, mapId, kmlType }) => {
  const [displayName, setDisplayName] = useState('');
  const [content, setContent] = useState('');
  const [item, setItem] = useState({});
  const dispatch = useDispatch();
  const navigateToMain = useCallback(() => {
    dispatch(navigateToPluginPage(['new-maps', 'mapsDetailPage', mapId]));
  }, [dispatch, mapId]);

  const { state, setState, handleSave, info } = useMapData(mapId);

  useEffect(() => {
    const stateItem = state[kmlType]?.find((i) => `${i.id}` === itemId);
    const infoItem = info?.find((i) => `${i.id}` === itemId);

    setItem(infoItem || stateItem);
  }, [itemId, state, info, kmlType]);

  useEffect(() => {
    setContent(item?.content || '');
    setDisplayName(item?.name || '');
  }, [item]);

  async function handleUpdateItem(displayName, content) {
    try {
      const newData = { ...item, displayName, content };
      const itemIsMarker = kmlType === 'markers';
      let mapInfo;
      if (itemIsMarker) {
        const data = await upsertMarkerInfo(mapId, newData);
        mapInfo = data.mapMarkerInfo;
      } else {
        const data = await upsertInfo(mapId, newData);
        mapInfo = data.mapInfo;
      }
      mapInfo.name = mapInfo.displayName;
      const updatedState = {
        ...state,
        [kmlType]: state[kmlType].map((item) => (item.id === mapInfo.id ? mapInfo : item)),
      };
      setState(updatedState);
      await handleSave(updatedState);
      navigateToMain();
    } catch (err) {
      log.error('Error updating item', err);
    }
  }

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    await handleUpdateItem(displayName, content);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [itemId]);

  return (
    <>
      <BreadcrumbLink
        pluginId={'new-maps'}
        parentPageName={`mapsDetailPage`}
        parentPageTitle={'Map Detail Page'}
        parentPageId={'maps-detail-page'}
        parentPageArgs={[mapId]}
      />
      <CmsPage title={`Edit ${kmlType === 'kmlRegions' ? 'KML Region' : 'KML Marker'}`}>
        <form onSubmit={handleSaveChanges}>
          <div>
            {item && <Text long disabled defaultValue={item?.id} label="ID *" />}
            {item && (
              <Text
                long
                setValue={setDisplayName}
                defaultValue={item?.displayName || item?.name}
                placeholder="Display Name"
                label="Display Name"
              />
            )}
            <Wysiwyg label="Content" value={content} setValue={setContent} />
          </div>
          <div>
            <Button variant="secondary" onClick={navigateToMain}>
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Save and Close
            </Button>
          </div>
        </form>
      </CmsPage>
    </>
  );
};

export default MapsKmlEditor;
