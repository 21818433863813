import itrsApi from '../api/itrsApi';
import { startCase } from 'lodash';

const CourseColumns = [
  {
    label: 'Course Name',
    valueField: 'name',
    sortable: true,
    initialSort: 'ASC',
  },
  {
    label: 'Status',
    valueField: 'status',
    sortable: true,
    renderer: (item) => {
      const status = item.status || '';
      return startCase(status);
    },
  },
  {
    label: 'Number of Sessions',
    valueField: 'sessionCount',
    sortable: true,
    align: 'center',
  },
];

export default function useCourseConfigurations() {
  return {
    columns: CourseColumns,
    api: itrsApi,
  };
}
