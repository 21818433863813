import React from 'react';
import FormInput from '../../shared/form/components/FormInput';
import FormInputFile from '../../shared/form/components/FormInputFile';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';

const GeneralFilesEditorForm = (props) => {
  const { form, actions } = props;

  return (
    <div className="row">
      <form role="form" className="no-left-padding">
        <FormInput
          label="Title (without extension)"
          fieldName="title"
          formContext={form}
          formFieldOptions={{ required: 'This field is required.' }}
        />

        <FormInputFile
          label="File"
          formContext={form}
          fieldWidth={500}
          formFieldOptions={{ required: 'This field is required.' }}
        />
      </form>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </div>
  );
};

export default GeneralFilesEditorForm;
