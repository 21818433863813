import React, { useState, useMemo, useEffect } from 'react';
import {
  getAccounts,
  getCalendars,
  syncCalendars as syncCalendarCmd,
  saveAccount,
  removeCalendar,
  reauthAccount,
} from '../api/calendarListApi';
import GrowlContainer from '../../shared/growl/components/GrowlContainer';
import { errorGrowl, successGrowl } from '../../store/rootThunks';
import { useDispatch } from 'react-redux';
import log from '../../logger';
import { CalendarListConstants } from '../definitions';
import CalendarAccountModal from './CalendarAccountModal';
import CalendarModal from './CalendarModal';

const CalendarListPage = () => {
  // State variables
  const [pageTitle] = useState('Calendars');
  const [separateAccountsChecked, setSeparateAccountsChecked] = useState(false);
  const [calendarArraysByAccount, setCalendarArraysByAccount] = useState([]);
  const [calendarListArray, setCalendarListArray] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isAcntModalOpen, setIsAcntModalOpen] = useState(false);
  const [isCalModalOpen, setIsCalModalOpen] = useState(false);

  const { defaultPageId } = CalendarListConstants;

  const dispatch = useDispatch();

  //read code and state from the query parameters
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('code');
  const state = queryParams.get('state');

  useEffect(() => {
    async function saveAccountCredentials() {
      try {
        if (state && code) {
          await saveAccount(state, code);
          //clear query parameters
          window.history.replaceState({}, document.title, window.location.pathname);
          dispatch(
            successGrowl({
              groupId: defaultPageId,
              props: 'Account added successfully',
            })
          );
          const accounts = await getAccounts();
          const calendars = await getCalendars();
          calendars.forEach((calendar) => {
            const account = accounts.find((acc) => acc.id === calendar.accountId);
            if (account) {
              if (account.items) {
                account.items.push(calendar);
              } else {
                account.items = [calendar];
              }
            }
          });
          setCalendarArraysByAccount(accounts);
        }
      } catch (e) {
        //clear query parameters
        window.history.replaceState({}, document.title, window.location.pathname);
        log.error(e);
        dispatch(
          errorGrowl({
            groupId: defaultPageId,
            props: 'Failed to add account',
          })
        );
      }
    }
    saveAccountCredentials();
  }, [code, state]);

  useEffect(() => {
    async function fetchData() {
      try {
        const accounts = await getAccounts();
        const calendars = await getCalendars();
        calendars.forEach((calendar) => {
          const account = accounts.find((acc) => acc.id === calendar.accountId);
          if (account) {
            if (account.items) {
              account.items.push(calendar);
            } else {
              account.items = [calendar];
            }
          }
        });
        setCalendarArraysByAccount(accounts);
        setCalendarListArray(calendars);
      } catch (e) {
        log.error(e);
      }
    }
    fetchData();
  }, []);

  // Functions (implement these according to your application logic)
  const manageAccounts = () => {
    setIsAcntModalOpen(true);
  };

  const addCalendar = () => {
    setIsCalModalOpen(true);
  };

  const insertCalendar = async () => {
    const calendars = await getCalendars();
    calendars.forEach((calendar) => {
      const account = calendarArraysByAccount.find((acc) => acc.id === calendar.accountId);
      if (account) {
        if (account.items) {
          account.items.push(calendar);
        } else {
          account.items = [calendar];
        }
      }
    });
    setCalendarListArray(calendars);
  };

  const syncCalendars = async () => {
    try {
      await syncCalendarCmd();
      dispatch(
        successGrowl({
          groupId: defaultPageId,
          props: 'Calendar Sync started',
        })
      );
    } catch (e) {
      log.error(e);
      dispatch(
        errorGrowl({
          groupId: defaultPageId,
          props: 'Calendar Sync error, try again later',
        })
      );
    }
  };

  const deleteCalendar = async (calendarId) => {
    await removeCalendar(calendarId);
    const calendars = calendarListArray.filter((calendar) => calendar.id !== calendarId);
    setCalendarListArray(calendars);
  };

  // Computed variables
  const calendarIdToAccountName = useMemo(() => {
    const map = {};
    calendarArraysByAccount.forEach((account) => {
      if (account.items) {
        account.items.forEach((calendar) => {
          map[calendar.id] = account.name;
        });
      }
    });
    return map;
  }, [calendarArraysByAccount]);

  const filteredCalendarList = useMemo(() => {
    const filtered = calendarListArray.filter(
      (calendar) => calendar.name && calendar.name.toLowerCase().includes(searchText.toLowerCase())
    );
    const sorted = filtered.sort((a, b) => a.name.localeCompare(b.name));
    return sorted;
  }, [calendarListArray, searchText]);

  const sortedCalendarArraysByAccount = useMemo(() => {
    const sortedAccounts = [...calendarArraysByAccount].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    return sortedAccounts;
  }, [calendarArraysByAccount]);

  const filteredAccountItems = (items) => {
    const filtered = (items || []).filter(
      (calendar) => calendar.name && calendar.name.toLowerCase().includes(searchText.toLowerCase())
    );
    const sorted = filtered.sort((a, b) => a.name.localeCompare(b.name));
    return sorted;
  };

  return (
    <div>
      {isAcntModalOpen && (
        <CalendarAccountModal
          onClose={() => setIsAcntModalOpen(false)}
          accounts={calendarArraysByAccount}
          growlId={defaultPageId}
        />
      )}
      {isCalModalOpen && (
        <CalendarModal
          onClose={() => setIsCalModalOpen(false)}
          accounts={calendarArraysByAccount}
          growlId={defaultPageId}
          insertCalendar={insertCalendar}
        />
      )}
      <div className="header row">
        <h1 className="page-title">{pageTitle}</h1>
        <div className="page-filter no-left-padding">
          <div className="no-left-padding no-bottom-padding">
            <div className="col-sm-12 top-margin-10 no-left-padding">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={separateAccountsChecked}
                    onChange={(e) => setSeparateAccountsChecked(e.target.checked)}
                  />
                  &nbsp; Separate Calendars By Account
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="module red row calendar-list neg-left-margin-13">
        <div className="module-header">
          <span className="module-title subtitle">
            Calendars {separateAccountsChecked && <span>By Account</span>}
          </span>
        </div>
        <div className="col-xs-12">
          <div className="module-body">
            <div id="calendarListGrowl"></div>
            <GrowlContainer groupId={defaultPageId} />
            <form name="CalendarList" role="form">
              <div style={{ marginBottom: '10px' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className="header-input pb-2">
                    <input
                      className="form-control"
                      placeholder="Search"
                      autoFocus
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </span>
                  <span
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
                  >
                    <button
                      onClick={manageAccounts}
                      type="button"
                      className="btn"
                      style={{ marginLeft: '1rem' }}
                    >
                      Google Accounts
                    </button>
                    <button
                      onClick={() => {
                        addCalendar();
                      }}
                      type="button"
                      className={`btn ${calendarArraysByAccount.length ? '' : 'disabled'}`}
                      disabled={!calendarArraysByAccount.length}
                      title={calendarArraysByAccount.length ? '' : 'Must Add an Account First'}
                      style={{ marginLeft: '1rem' }}
                    >
                      Add Calendar
                    </button>
                    <button
                      style={{ marginLeft: '1rem' }}
                      onClick={() => {
                        if (calendarListArray.length) {
                          syncCalendars();
                        }
                      }}
                      type="button"
                      className={`btn bump-right ${calendarListArray.length ? '' : 'disabled'}`}
                      disabled={!calendarListArray.length}
                      title={calendarListArray.length ? '' : 'Must Add a Calendar First'}
                    >
                      Sync Events
                    </button>
                  </span>
                </div>
              </div>

              {!separateAccountsChecked &&
                filteredCalendarList.map((calendar, index) => (
                  <div
                    key={calendar.id || index}
                    className={`equal-height-cols padding-bottom border-bottom ${
                      index === 0 ? 'border-top padding-top' : ''
                    }`}
                  >
                    <div
                      className="col-sm-10 no-left-padding no-right-padding"
                      style={{ width: '100%' }}
                    >
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>
                          {calendar.name && <span className="bold">{calendar.name}</span>}
                          {calendarIdToAccountName[calendar.id] && (
                            <>
                              {' - '}
                              {calendarIdToAccountName[calendar.id]}
                            </>
                          )}
                          {calendar?.lastResponse?.error && (
                            <span style={{ color: 'red', paddingLeft: '1rem' }}>
                              Error syncing account
                            </span>
                          )}
                        </span>
                        <span>
                          {calendar?.lastResponse?.error && (
                            <button
                              onClick={() => reauthAccount(calendar.accountId)}
                              type="button"
                              className="btn"
                            >
                              Reauthorize Calendar
                            </button>
                          )}
                          <button
                            onClick={() => deleteCalendar(calendar.id)}
                            style={{ marginLeft: '1rem' }}
                            type="button"
                            className="btn"
                          >
                            Delete
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}

              {separateAccountsChecked &&
                sortedCalendarArraysByAccount.map((account, accountIndex) => (
                  <div key={account.id || accountIndex} className="row">
                    <div className="col-sm-12 no-left-padding">
                      <div className="row">
                        <div className="module-sub-header">{account.name}</div>
                      </div>
                      {filteredAccountItems(account.items).map((calendar, index) => (
                        <div
                          key={calendar.id || index}
                          className={`calendar-info row equal-height-cols padding-bottom border-bottom ${
                            index === 0 ? 'border-top padding-top' : ''
                          }`}
                        >
                          <div className="col-sm-10">
                            <div className="inline-padding">
                              {calendar.name && <span className="bold">{calendar.name}</span>}
                              {calendar?.lastResponse?.error && (
                                <span style={{ color: 'red', paddingLeft: '1rem' }}>
                                  Error syncing account
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-2 text-right no-right-padding">
                            <button
                              onClick={() => deleteCalendar(calendar.id)}
                              type="button"
                              className="btn"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarListPage;
