import classNames from 'classnames';
import React, { useState } from 'react';
import rfc from 'react-fast-compare';
import { useSelector } from 'react-redux';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import { selectAppUserSettings } from '../../store/rootSelectors';
import appUserApi from '../api/appUserApi';
import { AppUserConstants } from '../definitions';
import useAppUserActions from '../hooks/useAppUserActions';
const AppUsersListPage = () => {
  const [customColumns] = useState([]);
  const { defaultPageId, typeName } = AppUserConstants;

  const { add, edit, remove, exportCsv, appUserCustomFields, importUsers, SheetModal } =
    useAppUserActions(AppUserConstants);
  const { allowAppUsersImport, allowAdditionalFields } = useSelector(selectAppUserSettings, rfc);
  //Decision was to hide for time being until design is made for new fields
  // useEffect(() => {
  //   (async () => {
  //     const { columns } = await appUserApi.customColumns();
  //     const cols = columns.map((col) => ({
  //       label: col,
  //       renderer: (item) => item?.customFields?.[col],
  //       sortable: true,
  //       valueField: 'customFields',
  //     }));
  //     setCustomColumns(cols);
  //   })();
  // }, []);

  const columns = [
    { initialSort: 'ASC', label: 'Last Name', sortable: true, valueField: 'lastName' },
    { label: 'First Name', sortable: true, valueField: 'firstName' },
    { label: 'Email', sortable: true, valueField: 'email' },
    {
      label: 'User Groups',
      renderer: (item) => {
        const [showTip, setShowTip] = useState(false);
        const val = item.userGroups && item.userGroups.length;
        const className = classNames('fa', 'fa-solid', { 'fa-users-line': Boolean(val) });
        if (!val) return '';
        return (
          <span className={'tooltip-container'} onClick={() => setShowTip(!showTip)}>
            <span className={className} />
            {showTip && (
              <span className={'tooltip-text-click'}>
                {item.userGroups.map((userGroup) => (
                  <div key={userGroup.name}>{userGroup.name}</div>
                ))}
              </span>
            )}
          </span>
        );
      },
      sortable: false,
      valueField: 'userGroups',
    },
  ].concat(customColumns);

  const toolbarActions = useArray(
    ...(allowAppUsersImport ? [importUsers] : []),
    ...(allowAdditionalFields ? [appUserCustomFields] : []),
    add,
    exportCsv
  );
  const itemActions = useArray(edit, remove);
  return (
    <CmsPage title={'Users'}>
      <CmsItemListSection
        showTotal={true}
        pageId={defaultPageId}
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={columns}
        itemApi={appUserApi}
        searchPlaceholder={'Search by Email, Last Name, or First Name'}
      />
      {SheetModal}
    </CmsPage>
  );
};

export default AppUsersListPage;
