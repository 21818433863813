import React, { useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import HelpIcon from '@mui/icons-material/Help';

export default function HelpPopover({
  helpText = 'Default help text',
  style,
  anchorOrigin,
  transformOrigin,
}) {
  const defaultAnchorOrigin = { vertical: 'center', horizontal: 'right' };
  const defaultTransformOrigin = { vertical: 'center', horizontal: 'left' };

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPosition, setAnchorPosition] = useState(defaultAnchorOrigin);
  const [transformPosition, setTransformPosition] = useState(defaultTransformOrigin);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (anchorOrigin) {
      setAnchorPosition(anchorOrigin);
    } else {
      setAnchorPosition(defaultAnchorOrigin);
    }

    if (transformOrigin) {
      setTransformPosition(transformOrigin);
    } else {
      setTransformPosition(defaultTransformOrigin);
    }
  }, [anchorOrigin, transformOrigin]);

  useEffect(() => {
    const handleScroll = () => {
      setAnchorEl(null); // Close the popover on scroll
    };

    // Add the scroll event listener when the popover is open
    if (anchorEl) {
      window.addEventListener('scroll', handleScroll);
    }

    // Clean up the event listener when the popover is closed
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [anchorEl]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div style={style}>
      <span
        style={{ display: 'flex', color: '#1A76D2', cursor: 'pointer' }}
        className="help-icon"
        onClick={handleClick}
      >
        <HelpIcon />
      </span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorPosition}
        transformOrigin={transformPosition}
      >
        <Typography sx={{ p: 2, color: '#1A76D2' }}>{helpText}</Typography>
      </Popover>
    </div>
  );
}
