import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import GenericTextInput from '../../shared/genericInputs/GenericTextInput';
import ItemTable from '../../shared/itemTable/components/ItemTable';
import { selectHasPrivileges } from '../../store/rootSelectors';
import { markCompletions } from '../api/sessionApi';
import useCancelModal from '../hooks/useCancelModal';
import useSessionDetails from '../hooks/useSessionDetails';
import { BackToCourses, BackToTravelRequests } from './BackButtons';
import EnrollUserModal from './EnrollementModal';
import CancelEnrollmentModal from './itrsSessionViewPageComponents/CancelEnrollmentModal';
import ConfirmCompletionModal from './itrsSessionViewPageComponents/ConfirmCompletionModal';
import Denial from './itrsSessionViewPageComponents/Denial';
import Detail from './itrsSessionViewPageComponents/Detail';
import Heading from './itrsSessionViewPageComponents/Heading';
import useItemActions from './itrsSessionViewPageComponents/useItemActions';
const IcnBtn = ({ Icon, onClick }) => (
  <IconButton sx={(theme) => ({ color: theme.palette.primary.main })} onClick={onClick}>
    <Icon />
  </IconButton>
);

const ItrsSessionViewPage = ({ sessionId, returnTo }) => {
  const theme = useTheme();
  const [appContext, setAppContext] = useState({});
  const [approvalStatus, setApprovalStatus] = useState('');
  const [openEnrollModal, setOpenEnrollModal] = React.useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [unionSearchField, setUnionSearchField] = useState('');

  const [isTrainer, isTravelCoordinator] = useSelector(
    selectHasPrivileges(['itrs.training', 'itrs.travel'])
  );
  const {
    openCancelModal,
    selectedContext,
    closeModal: closeCancelEnrollmentModal,
    handleCancelEnrollment,
    cancelEnrollmentAction,
  } = useCancelModal(sessionId);
  const {
    assistants,
    attendeesColumns,
    confirmCompletionModalProps,
    course,
    dateTitle,
    deleteSession,
    editSession,
    enrollmentColumns,
    enrollments,
    fullRange,
    handleApproval,
    handleDeny,
    instructors,
    invalidate,
    session,
    studentActions,
    students,
    trainingRequests,
    trainingRequestsColumns,
  } = useSessionDetails(
    sessionId,
    returnTo,
    unionSearchField,
    cancelEnrollmentAction,
    isTrainer,
    isTravelCoordinator
  );

  const trainingRequestItemActions = useItemActions({
    setApprovalStatus,
    setAppContext,
    setOpenModal,
  });
  const mb = (space) => ({ mb: theme.spacing(space) });
  return (
    <CmsPage>
      <Box sx={{ p: theme.spacing(0) }}>
        {returnTo === 'travel' ? <BackToTravelRequests /> : <BackToCourses />}
        <Box sx={{ display: 'flex', alignItems: 'center', my: theme.spacing(2) }}>
          <Box>
            <Typography variant="h4" component="h1" sx={{ flexGrow: 1 }}>
              {dateTitle}
            </Typography>
          </Box>
          {isTrainer && (
            <>
              <IcnBtn Icon={EditIcon} onClick={editSession} />
              <IcnBtn Icon={Delete} onClick={deleteSession} />
              <IcnBtn Icon={PersonAddAlt1Icon} onClick={() => setOpenEnrollModal(true)} />
            </>
          )}
        </Box>
        <Detail label="Course" value={`${course.name}`} />
        <Detail label="Start/End Date" value={fullRange} />
        <Detail label="Status" value={session.statusLabel} />
        <Box sx={{ my: theme.spacing(3) }}>
          <Heading gutterBottom sx={mb(1)}>
            Enrollment Overview
          </Heading>
          <ItemTable clientSort columns={enrollmentColumns} items={enrollments} />
        </Box>

        {isTrainer && (
          <Box sx={mb(3)}>
            <Heading gutterBottom sx={mb(1)}>
              Training Requests
            </Heading>
            <ItemTable
              itemActions={trainingRequestItemActions}
              clientSort
              columns={trainingRequestsColumns}
              items={trainingRequests}
            />
          </Box>
        )}

        <Box sx={mb(3)}>
          <Heading>Attendees</Heading>
          <Heading subheading gutterBottom sx={mb(1)}>
            Instructors
          </Heading>
          <ItemTable clientSort columns={attendeesColumns} items={instructors} />
          <Heading subheading gutterBottom sx={mb(1)}>
            Assistants
          </Heading>
          <ItemTable clientSort columns={attendeesColumns} items={assistants} />
          <Heading subheading gutterBottom sx={mb(1)}>
            Students
          </Heading>
          {isTravelCoordinator && (
            <Box sx={mb(3)}>
              <GenericTextInput
                className="form-control"
                label=""
                value={unionSearchField}
                fieldWidth="short"
                onChange={(e) => setUnionSearchField(e?.target?.value)}
                variant="outlined"
              />
              <Box style={{ fontSize: '9px', pl: '2.5rem' }}>Search by Name or Local Union</Box>
            </Box>
          )}
          <ItemTable
            applyCallback={async (rows) => {
              if (rows.length > 0) {
                await markCompletions(
                  sessionId,
                  rows.map((r) => r.enrollmentId)
                );
                await invalidate();
              }
            }}
            applyModalText={`Are you sure you want to mark completion for ${
              (selectedRows.length === 1 && selectedRows[0]?.fullName) ||
              `the ${selectedRows.length} selected user${selectedRows.length > 1 ? 's' : ''}`
            }?`}
            applyModalButtonText="MARK COMPLETION"
            applyModalTitle="Mark Completion"
            applyText={'APPLY COMPLETIONS'}
            clientSort
            columns={attendeesColumns}
            disableSelect={(item) => item?.status === 'COMPLETED'}
            isSelectable
            itemActions={studentActions}
            items={students}
            selectableText={'MARK COMPLETIONS'}
            setSelectedCallback={setSelectedRows}
            useApplyModal
          />
        </Box>
      </Box>

      <Denial
        appContext={appContext}
        approvalStatus={approvalStatus}
        handleApproval={handleApproval}
        handleDeny={handleDeny}
        openModal={openModal}
        setAppContext={setAppContext}
        setOpenModal={setOpenModal}
      />
      <EnrollUserModal
        sessionId={sessionId}
        courseId={course.id}
        enrollments={enrollments}
        invalidate={invalidate}
        open={openEnrollModal}
        setOpen={setOpenEnrollModal}
      />

      <CancelEnrollmentModal
        open={openCancelModal}
        onClose={closeCancelEnrollmentModal}
        context={selectedContext}
        onConfirm={async (...params) => {
          await handleCancelEnrollment(...params);
          await invalidate();
        }}
      />
      <ConfirmCompletionModal {...confirmCompletionModalProps} />
      <Box sx={{ minHeight: 24 }}></Box>
    </CmsPage>
  );
};

export default ItrsSessionViewPage;
