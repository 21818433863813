import get from 'lodash/get';
import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import log from '../../../../logger';
import Spinner from '../../../spinner/components/Spinner';
import useCsvReader from '../../../util/hooks/usCsvReader';
import ConfirmText from './components/ConfirmText';
import Errors from './components/Errors';
import { blobToCsv, readXlsx } from './helpers';

export default function useSheetModalUpload({
  title,
  bodyText,
  onSave: onSaveCallback,
  onSaveButtons,
  onClear: onClearCallback,
  onConfirm: onConfirmCallback,
  sheetProps,
  onConfirmComponent,
}) {
  const confirmSubmitRef = useRef();
  const confirmStepRef = useRef(1);
  const reloadRef = useRef();
  const setConfirmSubmit = (submitFunction) => {
    confirmSubmitRef.current = submitFunction;
  };

  const confirmDataRef = useRef({});
  const setConfirmData = (data) => {
    confirmDataRef.current = { ...confirmDataRef.current, ...data };
  };

  const originalFileRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [saveDataError, setSaveDataError] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [confirmText, setConfirmText] = useState(null);
  const [isRunningConfirmCallback, setIsRunningConfirmCallback] = useState(false);
  const saveCallbackRef = useRef();

  const {
    fileData,
    compressedFileData,
    processFile,
    errors = [],
    onClear,
    isValid,
    processedFile,
  } = useCsvReader(sheetProps);

  const fileDataRef = useRef(fileData);
  useEffect(() => {
    fileDataRef.current = fileData;
  }, [fileData]);

  const closeHandler = async () => {
    onClear();
    if (onClearCallback && typeof onClearCallback === 'function') await onClearCallback();
    setVisible(false);
    confirmStepRef.current = 1;
    confirmDataRef.current = {};
    setIsConfirming(false);
    setConfirmText(null);
    confirmSubmitRef.current = null;
  };

  const handleInputChange = async (event) => {
    const file = get(event, 'target.files[0]');
    originalFileRef.current = file;
    if (file) {
      processFile(file.name.endsWith('.xlsx') ? await readXlsx(file) : file, event);
    } else {
      event.target.value = null;
    }
  };

  const onSave = async (saveCallback) => {
    try {
      setIsConfirming(false);
      setConfirmText(null);
      setProcessing(true);
      setSaveDataError(false);
      await saveCallback({
        compressedData: compressedFileData,
        csv: blobToCsv(processedFile, originalFileRef.current.name),
        data: fileDataRef.current,
        file: originalFileRef.current,
        confirmData: confirmDataRef.current,
        reload: reloadRef.current,
      });
      closeHandler();
    } catch (err) {
      log.error(err);
      log.error('Error saving data', {
        fileData: fileDataRef.current,
        originalFile: originalFileRef.current,
        processedFile,
      });
      setSaveDataError(true);
    } finally {
      setProcessing(false);
    }
  };

  const proceedToNextStep = async () => {
    confirmStepRef.current += 1;
    setIsConfirming(true);
    setConfirmText('Loading Confirmation...');
    setIsRunningConfirmCallback(true);
    try {
      const confirmMessage = await onConfirmCallback({
        csv: blobToCsv(processedFile, originalFileRef.current.name),
        data: fileDataRef.current,
        file: originalFileRef.current,
        setConfirmData,
        proceedToNextStep,
        confirmStep: confirmStepRef.current,
        confirmData: confirmDataRef.current,
      });

      if (typeof confirmMessage === 'string' || React.isValidElement(confirmMessage)) {
        if (React.isValidElement(confirmMessage)) {
          setConfirmText(React.cloneElement(confirmMessage, { setConfirmSubmit }));
        } else {
          setConfirmText(confirmMessage);
        }
        setIsRunningConfirmCallback(false);
      } else {
        setIsRunningConfirmCallback(false);
        setIsConfirming(false);
        onSave(saveCallbackRef.current);
      }
    } catch (err) {
      log.error(err);
      setIsRunningConfirmCallback(false);
      setIsConfirming(false);
    }
  };

  const onConfirmSave = async (onClickCallback, skipConfirmation = false) => {
    if ((onConfirmComponent || onConfirmCallback) && !skipConfirmation) {
      setIsConfirming(true);
      setConfirmText('Loading Confirmation...');
      setIsRunningConfirmCallback(true);
      saveCallbackRef.current = onClickCallback;

      try {
        const confirmMessage = await onConfirmCallback({
          csv: blobToCsv(processedFile, originalFileRef.current.name),
          data: fileDataRef.current,
          file: originalFileRef.current,
          setConfirmData,
          proceedToNextStep,
          confirmStep: confirmStepRef.current,
          confirmData: confirmDataRef.current,
        });

        if (typeof confirmMessage === 'string' || React.isValidElement(confirmMessage)) {
          if (React.isValidElement(confirmMessage)) {
            setConfirmText(React.cloneElement(confirmMessage, { setConfirmSubmit }));
          } else {
            setConfirmText(confirmMessage);
          }
          setIsRunningConfirmCallback(false);
        } else {
          setIsRunningConfirmCallback(false);
          setIsConfirming(false);
          await onSave(onClickCallback);
        }
      } catch (err) {
        log.error(err);
        setIsRunningConfirmCallback(false);
        setIsConfirming(false);
      }
    } else {
      await onSave(onClickCallback);
    }
  };

  const SheetModal = (
    <Modal show={visible} animation={false}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isConfirming ? (
          <div>
            {typeof confirmText === 'string' ? <ConfirmText text={confirmText} /> : confirmText}
          </div>
        ) : (
          <div>
            <div>{bodyText || 'Upload File'}</div>
            {processing && <Spinner />}
            <div>
              <input
                disabled={processing}
                type="file"
                className="form-control"
                accept={'.xlsx, .csv'}
                onChange={handleInputChange}
              />
            </div>
            <Errors errors={errors} saveDataError={saveDataError} />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {isConfirming ? (
          <>
            {typeof confirmText === 'string' ? (
              <Button disabled={isRunningConfirmCallback} onClick={proceedToNextStep}>
                Ok
              </Button>
            ) : (
              <>
                {onSaveCallback ? (
                  <Button
                    variant={'success'}
                    disabled={!isValid || isRunningConfirmCallback}
                    onClick={() => {
                      if (confirmSubmitRef.current) {
                        confirmSubmitRef.current({ reload: reloadRef.current });
                      } else {
                        proceedToNextStep();
                      }
                    }}
                  >
                    Next
                  </Button>
                ) : (
                  (onSaveButtons || []).map(({ variant = 'success', text, onClick }, idx) => (
                    <Button
                      key={idx}
                      variant={variant}
                      disabled={!isValid || isRunningConfirmCallback}
                      onClick={() => {
                        if (confirmSubmitRef.current) {
                          confirmSubmitRef.current({ reload: reloadRef.current });
                        } else if (typeof onClick === 'function') {
                          onClick({ reload: reloadRef.current });
                        } else {
                          proceedToNextStep();
                        }
                      }}
                    >
                      {!!text && typeof text === 'string' ? text : 'Next'}
                    </Button>
                  ))
                )}
              </>
            )}
          </>
        ) : (
          <>
            {onSaveCallback ? (
              <Button
                variant={'success'}
                disabled={!isValid || isRunningConfirmCallback}
                onClick={() => {
                  onConfirmSave(onSaveCallback, false);
                }}
              >
                Save
              </Button>
            ) : (
              (onSaveButtons || []).map(({ variant = 'success', text, onClick }, idx) => (
                <Button
                  key={idx}
                  variant={variant}
                  disabled={!isValid || isRunningConfirmCallback}
                  onClick={() => {
                    onConfirmSave(onClick, false);
                  }}
                >
                  {text}
                </Button>
              ))
            )}
          </>
        )}
        <Button variant="cancel" onClick={closeHandler}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return {
    SheetModal,
    close: closeHandler,
    open: (props) => {
      setVisible(true);
      if (typeof props?.reload === 'function') reloadRef.current = props.reload;
    },
  };
}
