import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Box, TableCell, Autocomplete, TextField, Chip, FormHelperText } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTableEditorContext } from './TableContext';

const TableCellAutocomplete = ({
  cellAlign = 'left',
  cellWidth,
  field,
  idx,
  tableCellProps = {},
  options = [],
  viewOnly,
  error,
  setCursor,
  value,
  colIndex,
  disabled,
  defaultViewValue,
  onChipAreaHover,
}) => {
  const { editField, handleFieldClick, setRowField, editIdx, canDrag, fontSize } =
    useTableEditorContext();
  const [isLocalEditing, setLocalIsEditing] = useState(false);
  const autocompleteRef = useRef(null);
  const isEditing = editIdx === idx && editField === field;
  const [isChipAreaHovered, setIsChipAreaHovered] = useState(false);

  useEffect(() => {
    if (setCursor && autocompleteRef.current && colIndex === 0) {
      autocompleteRef.current.focus();
    }
  }, [setCursor, colIndex]);

  useEffect(() => {
    if (isLocalEditing && !isEditing) {
      setLocalIsEditing(false);
    } else if (isEditing && !isLocalEditing) {
      setLocalIsEditing(true);
    }
  }, [isLocalEditing, isEditing]);

  const handleOnChange = useCallback(
    (event, newValue) => {
      setRowField(idx, field, newValue);
    },
    [setRowField, idx, field]
  );

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }

      const items = Array.from(value || []);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setRowField(idx, field, items);
    },
    [value, setRowField, idx, field]
  );

  const handleChipAreaMouseEnter = () => {
    setIsChipAreaHovered(true);
    onChipAreaHover && onChipAreaHover(true);
  };

  const handleChipAreaMouseLeave = () => {
    setIsChipAreaHovered(false);
    onChipAreaHover && onChipAreaHover(false);
  };

  return (
    <TableCell
      component="th"
      scope="row"
      onClick={() => handleFieldClick(idx, field)}
      align={cellAlign}
      sx={{
        borderBottom: '1px solid #e0e0e0',
        margin: 0,
        width: cellWidth,
        maxWidth: cellWidth,
        fontSize,
        wordWrap: 'break-word',
        verticalAlign: 'center',
        position: 'relative',
        '&:hover': { ...(!viewOnly && { backgroundColor: 'rgba(0, 0, 0, 0.04)' }) },
        '& .MuiAutocomplete-root': { verticalAlign: 'middle', border: 'none' },
        '& .MuiInputBase-root': { padding: '2px' },
      }}
      {...tableCellProps}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex' }}>
          {canDrag && colIndex === 0 && !isChipAreaHovered && (
            <Box sx={{ display: 'flex', mr: 2, alignItems: 'center', justifyContent: 'center' }}>
              <i className="far fa-ellipsis-v reorder-icon" aria-hidden="true"></i>
              <i className="far fa-ellipsis-v reorder-icon" aria-hidden="true"></i>
            </Box>
          )}
          <Autocomplete
            multiple
            freeSolo={!disabled}
            options={options}
            value={value || defaultViewValue || []}
            onChange={handleOnChange}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder={value && value.length > 0 ? '' : 'Select options'}
                inputRef={autocompleteRef}
                InputProps={{ ...params.InputProps, readOnly: disabled }}
                error={!!error}
                sx={{
                  '& .MuiInputBase-root': { fontSize },
                  '& .MuiInputBase-input': { padding: '0.5rem' },
                }}
              />
            )}
            disabled={viewOnly || disabled}
            fullWidth
            sx={{
              '& .MuiAutocomplete-endAdornment': {
                top: 'calc(50% - 14px)',
              },
            }}
          />
        </Box>
        {error && (
          <FormHelperText error sx={{ ml: 1, mt: 0.5 }}>
            {error.message}
          </FormHelperText>
        )}
        {disabled ? (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 0.5,
              mt: 1,
              maxHeight: '85px',
              overflowY: 'auto',
            }}
            onMouseEnter={handleChipAreaMouseEnter}
            onMouseLeave={handleChipAreaMouseLeave}
          >
            {(value || []).map((option) => (
              <Chip key={option} label={option} size="small" />
            ))}
          </Box>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="dnd-input-chips" direction="horizontal">
              {(provided) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5,
                    mt: 1,
                    maxHeight: '85px',
                    overflowY: 'auto',
                  }}
                  onMouseEnter={handleChipAreaMouseEnter}
                  onMouseLeave={handleChipAreaMouseLeave}
                >
                  {(value || []).map((option, index) => (
                    <Draggable key={option} draggableId={option} index={index}>
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Chip
                            label={option}
                            onDelete={() => {
                              const newValue = (value || []).filter((v) => v !== option);
                              setRowField(idx, field, newValue);
                            }}
                            size="small"
                          />
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Box>
    </TableCell>
  );
};

export default TableCellAutocomplete;
