import { TextField, Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import useFieldProps from '../hooks/useFieldProps';

export const parseDate = (dt) => {
  return dt && typeof dt === 'string' && dt.includes('T') ? dt.split('T')[0] : dt;
};

const FormDateOnly = (props) => {
  const {
    defaultValue,
    fieldName,
    fieldWidth,
    formContext,
    formFieldOptions = {},
    label,
    variant = 'standard',
    validateFunction,
  } = props;

  const [hasSet, setHasSet] = useState(false);
  const { isRequired, value, width, errorMessage } = useFieldProps(props, true);

  const val = parseDate(value);
  const { register, setValue } = formContext;
  const def = useMemo(() => parseDate(defaultValue), [defaultValue]);

  useEffect(() => {
    if (!hasSet && (val || def)) {
      setHasSet(true);
      setValue(fieldName, parseDate(val || def));
    }
  }, [def, hasSet, val, fieldName, setValue]);

  const { ref, ...rest } = register(fieldName, {
    setValueAs: parseDate,
    validate: (value) => {
      if (typeof validateFunction === 'function') {
        const validated = validateFunction(value);
        if (validated !== true) {
          return validated;
        }
      }
      if (isRequired) {
        return value !== '' || formFieldOptions.required || 'Invalid date';
      }
      return true;
    },
    ...formFieldOptions,
  });
  return (
    <Box mx={0} my={'1.5rem'} p={0}>
      <TextField
        label={label}
        variant={variant}
        type="date"
        fullWidth={fieldWidth === 'full'}
        defaultValue={def}
        error={!!errorMessage}
        helperText={errorMessage}
        InputLabelProps={{ shrink: true }}
        inputRef={ref}
        required={isRequired}
        {...rest}
        sx={{ width }}
      />
    </Box>
  );
};

export default FormDateOnly;
