import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { updateRuleFieldLogicType } from '../../../../../store';
import { selectRuleFieldLogicData } from '../../../../../store/selectors';

export default function SelectFieldLogicType(props) {
  const { location, fieldName } = props;
  const { options = [], value, isDisabled } = selectRuleFieldLogicData(fieldName, location);
  const dispatch = useDispatch();

  const handleChange = useCallback(
    (field) => {
      dispatch(updateRuleFieldLogicType({ field, location, fieldName }));
    },
    [dispatch, location, fieldName]
  );
  const opts = useMemo(() => options || [], [options]);
  return (
    <Select
      styles={
        value?.value === 'appUserCustomFieldValue'
          ? {
              container: (base) => ({
                ...base,
                position: 'absolute',
                left: '-9999px',
                width: 0,
                height: 0,
                opacity: 0,
                pointerEvents: 'none',
                overflow: 'hidden',
              }),
            }
          : {}
      }
      isDisabled={isDisabled}
      placeholder="Logic Type"
      getOptionLabel={(field) => field.label}
      getOptionValue={(field) => field.value}
      onChange={handleChange}
      value={isDisabled ? '' : value}
      options={opts}
      className="inline-select"
    />
  );
}
