import reduce from 'lodash/reduce';
import toNumber from 'lodash/toNumber';
import toString from 'lodash/toString';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import FormControl from 'react-bootstrap/FormControl';

/**
 * NumberInput:
 * - When `isInt = true`, preserves exactly what is typed (leading zeros, etc.),
 *   and never converts locally to a number.
 * - When `isInt = false`, uses a numeric <input> and
 *   converts the final value to a real number on blur.
 */
const NumberInput = ({
  fieldKey,
  validation = {},
  label,
  setInput,
  value,
  isDisabled,
  isInt = false,
}) => {
  const [currentValue, setCurrentValue] = useState('');

  // Keep local state in sync with incoming parent value
  useEffect(() => {
    setCurrentValue(toString(value));
  }, [value]);

  // Extract min/max if present in `validation`
  const inputProps = useMemo(() => {
    return reduce(
      validation,
      (acc, v, k) => ({
        ...acc,
        ...(k === 'min' && { min: v }),
        ...(k === 'max' && { max: v }),
      }),
      {}
    );
  }, [validation]);

  /**
   * On blur, decide how to store the value in the parent.
   *
   * - If `isInt = true`, we store the exact string (e.g. "007"),
   *   so the parent sees the leading zeros.
   *
   * - If `isInt = false`, we convert to a real number using `toNumber()`.
   *   That means "007.5" becomes numeric 7.5 in the parent.
   */
  const handleSetInputValue = useCallback(() => {
    if (isInt) {
      // Store the string verbatim, e.g., "007" remains "007"
      setInput(fieldKey, currentValue);
    } else {
      // Convert string to a number, so "007.5" => 7.5
      setInput(fieldKey, toNumber(currentValue));
    }
  }, [fieldKey, currentValue, isInt, setInput]);

  /**
   * On change, decide how to treat the user's keystrokes.
   *
   * - If `isInt = true`, only allow digits [0-9], preserving leading zeros.
   * - If `isInt = false`, just let the user type into the numeric input.
   */
  const handleChange = useCallback(
    (e) => {
      let newValue = e.target.value;

      if (isInt) {
        // Remove any non-digit characters
        newValue = newValue.replaceAll(/\D/g, '');
      }

      setCurrentValue(newValue);
    },
    [isInt]
  );

  return (
    <FormControl
      onBlur={handleSetInputValue}
      value={isDisabled ? '' : currentValue}
      disabled={isDisabled}
      onChange={handleChange}
      placeholder={label}
      // Use text for integers so the user sees exactly what they type (leading zeros).
      // Use number for floating-point usage so typed decimals appear as the browser formats them.
      type={isInt ? 'text' : 'number'}
      {...inputProps}
    />
  );
};

export default memo(NumberInput);
