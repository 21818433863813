import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, TextField } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  width: '795px',
  borderRadius: 2,
  boxShadow: 24,
};

const DenialModal = (props) => {
  const {
    handleClose,
    handleSave,
    handleChange,
    title,
    description,
    actionTitle,
    inputLabel,
    hasInput,
    open,
  } = props;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ px: 4, py: 1 }}>
          <Typography id="modal-modal-title" variant="body1" sx={{ fontWeight: '600' }}>
            {title}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ px: 4 }}>
          <Typography id="modal-modal-description" variant="body2" sx={{ mt: 2 }}>
            {description}
          </Typography>
          {hasInput && (
            <TextField
              multiline
              rows={4}
              sx={{ mt: 2 }}
              label={inputLabel}
              variant="outlined"
              onChange={handleChange}
              fullWidth
            />
          )}
        </Box>
        <Box sx={{ px: 4, pb: 2 }}>
          <Button variant="contained" onClick={handleSave} sx={{ mt: 2 }}>
            {actionTitle}
          </Button>
          <Button onClick={handleClose} sx={{ ml: 2, mt: 2 }}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DenialModal;
