import React, { useState, useEffect } from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import { ItrsCourseConstants } from '../definitions';
import BreadcrumbLink from '../../shared/actionButton/components/BreadcrumbLink';
import { getReportingUrl } from '../api/itrsApi';

const ITRSReportsPage = () => {
  const [embedUrl, setEmbedUrl] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState('');

  useEffect(() => {
    getReportUserUrl();
  }, []);

  const getReportUserUrl = async () => {
    try {
      setLoading(true);
      const { data } = await getReportingUrl();
      setEmbedUrl(data.EmbedUrl);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BreadcrumbLink
        pluginId={ItrsCourseConstants.pluginId}
        parentPageId={ItrsCourseConstants.defaultPageId}
        parentPageName={ItrsCourseConstants.defaultPageName}
        parentPageTitle={'Courses'}
      />
      <CmsPage title={'ITRS Reporting'}>
        {loading && <div>Loading...</div>}
        {error && <div style={{ color: 'red' }}>{error}</div>}
        {embedUrl && <iframe src={embedUrl} style={{ width: '100%', height: '85vh' }}></iframe>}
      </CmsPage>
    </>
  );
};

export default ITRSReportsPage;
