import React, { useEffect, useMemo, useState } from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import manageGroupsEditApi from '../api/manageGroupsEditApi';
import { ManageGroupsConstants } from '../definitions';
import useManageGroupActions from '../hooks/useManageGroupActions';
import useManageUserActions from '../hooks/useManageUserActions';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import useRefreshActionCallback from '../../shared/cmsPage/hooks/useRefreshActionCallback';

const ManageGroupsEditMembers = (props) => {
  const { groupId, groupName, groupKey, groupCanAssign } = props;
  const canAssign = groupCanAssign === 'true';
  const [inGroup, setInGroup] = useState(true);
  const [checkedUsers, setCheckedUsers] = useState({});

  const handleCheckUser = (e) => {
    setCheckedUsers({ ...checkedUsers, [e.target.value]: e.target.checked });
  };

  useEffect(() => {
    setCheckedUsers({});
  }, [inGroup]);

  const { managePageName, typeName } = ManageGroupsConstants;
  const { hiddenButton, refreshCallback } = useRefreshActionCallback(managePageName);

  const { exportCsv } = useManageGroupActions(ManageGroupsConstants, groupId);
  const actions = useManageUserActions(
    {
      groupId,
      groupName,
      groupKey,
      inGroup,
      checkedUsers,
      groupCanAssign: canAssign,
    },
    refreshCallback
  );
  const pageSize = 10;

  const columns = [
    {
      label: 'User Select',
      renderer: (item) => {
        const isChecked = checkedUsers[item.id.toString()] || false;
        return (
          <input
            value={item.id.toString()}
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckUser}
          />
        );
      },
      sortable: false,
      valueField: 'id',
    },
    { initialSort: 'ASC', label: 'Last Name', sortable: true, valueField: 'lastName' },
    { initialSort: 'ASC', label: 'First Name', sortable: true, valueField: 'firstName' },
    { initialSort: 'ASC', label: 'Email', sortable: true, valueField: 'email' },
  ];

  const toolbarActions = useArray(exportCsv, hiddenButton);
  const itemActions = useArray();
  const memoizedAdditionalLoaderParams = useMemo(
    () => ({ groupId, groupName, groupKey }),
    [groupId, groupName, groupKey]
  );
  return (
    <>
      <CmsPage title={`Manage Group - ${groupName}`}>
        <CmsItemListSection
          showSelect={false}
          showTotal={true}
          pageSize={pageSize}
          pageId={managePageName}
          typeName={typeName}
          toolbarActions={toolbarActions}
          itemActions={itemActions}
          columns={columns}
          itemApi={manageGroupsEditApi}
          memoizedAdditionalLoaderParams={memoizedAdditionalLoaderParams}
          onAdditionalSearchFieldsChange={(fields) => {
            setInGroup(fields.inGroup !== false);
          }}
          additionalSearchFields={[
            {
              key: 'inGroup',
              noSelectText: 'Show Members',
              placeholder: 'Members Shown',
              type: 'select',
              options: [{ label: 'Show Non-Members', value: false }],
            },
          ]}
          searchPlaceholder={'Search by User'}
        />
        <CmsPageSectionFooter actions={actions} />
      </CmsPage>
    </>
  );
};

export default ManageGroupsEditMembers;
