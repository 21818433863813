import { useMemo } from 'react';
import manageGroupsEditApi from '../../manage-groups/api/manageGroupsEditApi';
import logger from '../../logger';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../store/rootReducer';
const useManageUserActions = (
  { groupId: rawGroupId, groupKey, inGroup, checkedUsers, groupCanAssign },
  refreshCallback
) => {
  const dispatch = useDispatch();
  const groupId = Number.parseInt(rawGroupId);
  const filteredUsers = useMemo(
    () =>
      Object.entries(checkedUsers)
        .filter(([k, v]) => k && v)
        .map(([id]) => Number.parseInt(id)),
    [checkedUsers]
  );

  async function addUsersToGroup(groupId, groupKey, filteredUsers, action = 'add') {
    try {
      let rejectedUsers = 0;
      await Promise.all(
        filteredUsers.map(async (id) => {
          const resp = await manageGroupsEditApi.addUser(groupId, groupKey, id, action);
          if (resp?.noOccupancy) rejectedUsers++;
        })
      );
      if (rejectedUsers) {
        dispatch(
          showSnackbar({
            message: `Group Has Reached Maximum Occupancy, ${rejectedUsers} User${
              rejectedUsers > 1 ? 's' : ''
            } Not Added`,
          })
        );
      }
    } catch (error) {
      logger.error('Error occurred while adding users to group:', error);
    }
  }

  async function removeUsersFromGroup(groupId, groupKey, filteredUsers, action = 'remove') {
    try {
      await Promise.all(
        filteredUsers.map((id) => manageGroupsEditApi.removeUser(groupId, groupKey, id, action))
      );

      await refreshCallback();
    } catch (error) {
      logger.error('Error occurred while removing users from group:', error);
    }
  }

  return useMemo(
    () => [
      {
        label: inGroup ? 'Remove User' : 'Add User',
        disabled: !groupCanAssign || filteredUsers.length === 0,
        onClick: async () => {
          const callback = inGroup ? removeUsersFromGroup : addUsersToGroup;
          await callback(groupId, groupKey, filteredUsers);
          await refreshCallback();
        },
      },
    ],
    [inGroup, groupId, groupKey, filteredUsers]
  );
};

export default useManageUserActions;
