import React, { useState, useEffect } from 'react';
import { Box, Modal, Divider, Typography, CircularProgress } from '@mui/material';
import useUserSearch from './hooks/useUserSearch';
import ModalHeader from './ModalHeader';
import UserTypeSelector from './UserTypeSelector';
import UserSearchForm from './UserSearchForm';
import UserResultsList from './UserResultsList';
import ModalFooter from './ModalFooter';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  width: 550,
  borderRadius: 2,
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
};

const EnrollUserModal = ({ open, setOpen, sessionId, enrollments, courseId, invalidate }) => {
  const [userType, setUserType] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [lastSearchedVal, setLastSearchedVal] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectionError, setSelectionError] = useState('');

  const { isLoading, results, noResultsFound, errorMessage, searchUsers } = useUserSearch({
    courseId,
    sessionId,
    userType,
  });

  const handleUserTypeChange = (e) => {
    setUserType(e?.target?.value ?? '');
    setSearchValue('');
    setLastSearchedVal('');
    setSelectedUser(null);
    setSelectionError('');
  };
  const handleClose = async () => {
    handleUserTypeChange('');
    setOpen(false);
  };

  const handleSelectUser = (e) => {
    setSelectedUser(results.find((u) => u.id.toString() === e.target.value));
  };

  useEffect(() => {
    if (selectedUser?.approvedPending) {
      setSelectionError(
        'The selected user is already enrolled or has an enrollment that is currently pending approval.'
      );
    } else {
      setSelectionError('');
    }
  }, [selectedUser]);
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <ModalHeader title="Enroll User" onClose={handleClose} />
        <Divider />
        <Box sx={{ px: 3, py: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <UserTypeSelector
            enrollments={enrollments}
            userType={userType}
            onChange={handleUserTypeChange}
          />
          {userType && (
            <UserSearchForm
              searchUsers={searchUsers}
              searchValue={searchValue}
              setLastSearchedVal={setLastSearchedVal}
              setSearchValue={setSearchValue}
              setSelectedUser={setSelectedUser}
              userType={userType}
            />
          )}
          {isLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <CircularProgress size={24} />
            </Box>
          )}
          {noResultsFound && (
            <Typography sx={{ mt: 2, color: '#92949C', fontStyle: 'italic' }}>
              {`No users found that match the specified ${
                userType === 'STUDENT' ? 'registration number' : 'email address'
              }.`}
            </Typography>
          )}
          {(errorMessage || selectionError) && (
            <Typography sx={{ mt: 2, color: 'red' }}>{errorMessage || selectionError}</Typography>
          )}
          {results.length > 0 && (
            <UserResultsList
              results={results}
              selectedUserId={selectedUser?.id.toString() || ''}
              onSelectUser={handleSelectUser}
              lastSearchedVal={lastSearchedVal}
              userType={userType}
            />
          )}
        </Box>
        <ModalFooter
          sessionId={sessionId}
          courseId={courseId}
          userType={userType}
          selectedUser={selectedUser}
          onCancel={handleClose}
          invalidate={invalidate}
        />
      </Box>
    </Modal>
  );
};

export default EnrollUserModal;
