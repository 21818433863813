import createPageConstants from '../shared/util/createPageConstants';
const pluginId = 'custom-plugins/iuoe/itrs';

export const ItrsCourseConstants = createPageConstants(pluginId, 'ITRS Course', {
  defaultDefault: 'itrsDashboardPage',
  course: 'itrsCourseListPage',
  editor: 'itrsCourseCreatePage',
  view: 'itrsCourseViewPage',
  travelRequests: 'itrsTravelRequestsPage',
  reports: 'itrsReportsPage',
  mailLog: 'itrsMailLogPage',
  mailLogItem: 'itrsMailLogItemPage',
});
export const ItrsSessionConstants = createPageConstants(pluginId, 'ITRS Session', {
  default: 'itrsSessionViewPage',
  editor: 'itrsSessionCreatePage',
  view: 'itrsSessionViewPage',
  travelRequests: 'itrsTravelRequestsPage',
  travelConfirmation: 'itrsTravelConfirmationPage',
});
