import moment from 'moment';
import travelApi from '../api/travelApi';
const SessionColumns = [
  {
    label: 'Session',
    valueField: 'startDate',
    sortable: true,
    renderer: (item) =>
      `${moment.utc(item.startDate).format('MMMM DD, YYYY')} - ${moment
        .utc(item.endDate)
        .format('MMMM DD, YYYY')}`,
  },
  {
    label: 'Course',
    valueField: 'name',
    sortable: true,
    renderer: (item) => item?.course?.name,
  },
  {
    label: 'Status',
    valueField: 'status',
    sortable: true,
  },
  {
    label: 'Enrollment',
    valueField: 'submittedApprovals',
    sortable: false,
    renderer: (item) => {
      const { enrollmentCaps, submittedApprovals } = item;
      return `${submittedApprovals?.approved || 0} of ${enrollmentCaps?.studentMax || 0}`;
    },
  },
  {
    label: 'Travel Submitted',
    valueField: 'submittedApprovals',
    sortable: false,
    renderer: (item) => {
      const { travelRequests } = item;
      return `${travelRequests?.length || 0}`;
    },
  },
  {
    label: 'Travel Confirmed',
    valueField: 'submittedApprovals',
    sortable: false,
    renderer: (item) => {
      const { travelRequests } = item;
      //count the number of requests that have a travel confirmation
      const travelConfirmations = travelRequests?.filter(
        (req) => req.travelConfirmation && req.travelConfirmation.status === 'confirmed'
      );

      return `${travelConfirmations?.length || 0}`;
    },
  },
];

export default function useSessionConfigurations(apiParams) {
  return {
    columns: SessionColumns,
    api: travelApi(apiParams),
  };
}
