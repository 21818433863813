import { useEffect } from 'react';
export const useSpinner = (spinnerProvider, showSpinner) => {
  useEffect(() => {
    if (showSpinner && spinnerProvider) {
      const { add, dismiss } = spinnerProvider;
      const id = '' + Date.now();
      add(id);
      return () => dismiss(id);
    }
  }, [spinnerProvider, showSpinner]);
};
