import React, { useState, useContext } from 'react';
import { CmsPageContext } from '../../shared/cmsPage/definitions';
import { useSpinner } from '../../shared/spinner/hooks/useSpinner';
import FormCheckBox from '../../shared/form/components/FormCheckBox';
import FormInput from '../../shared/form/components/FormInput';
import ProvidedSelect from '../../shared/form/components/ProvidedSelect';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import useServiceData from '../hooks/useServiceData';
const EMPTY_TEMPLATE = {
  requiredFields: [],
  value: 'none',
  label: 'None',
};

const AchievementEditorForm = (props) => {
  const { form, actions } = props;
  const [templateSet, templSet] = useState(false);
  const { options, requestPending } = useServiceData('achievementTemplates');
  const templateOpts = options.templates;
  const fieldOptions = options.fields;
  const initial = form.getValues();
  const defaultTemplate =
    initial.template && !templateSet && !requestPending
      ? templateOpts.find((to) => to.value === initial.template)
      : false;
  const [selectedTemplate, setTemplate] = useState(EMPTY_TEMPLATE);

  if (defaultTemplate) {
    templSet(true);
    setTemplate(defaultTemplate);
  }

  const { spinnerProvider } = useContext(CmsPageContext);
  useSpinner(spinnerProvider, requestPending);
  return (
    !requestPending && (
      <div className="row">
        <form role="form" className="no-left-padding">
          <FormInput
            label="Name"
            fieldName="name"
            formContext={form}
            formFieldOptions={{
              required: 'This field is required.',
            }}
          />
          <div style={{ marginBottom: 20 }}>
            <ProvidedSelect
              label={'Printable Template'}
              fieldName={'template'}
              fieldWidth="normal"
              formContext={form}
              formFieldOptions={{
                required: 'This field is required.',
              }}
              placeholder="Choose a template"
              selectOptions={templateOpts}
              selectChange={(selected) => setTemplate(selected)}
            />
          </div>
          <label>Fields to Include *</label>
          <div className="checkboxes">
            {fieldOptions.map((o) => (
              <FormCheckBox
                id={o.id}
                key={o.id}
                label={
                  <span>
                    {o.displayName}{' '}
                    <span className="form-input-description">({o.description})</span>
                  </span>
                }
                fieldName={`included.${o.id}`}
                formContext={form}
                type="checkbox"
                forceCheck={selectedTemplate.requiredFields.includes(o.id)}
                preChecked={initial && initial.included && initial.included[o.id]}
              />
            ))}
          </div>
        </form>
        <div className="row module-footer">
          <CmsPageSectionFooter actions={actions} />
        </div>
      </div>
    )
  );
};

export default AchievementEditorForm;
