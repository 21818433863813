import React, { useMemo } from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import useArray from '../../shared/util/hooks/useArray';
import { ItrsCourseConstants } from '../definitions';
import useItrsActions from '../hooks/useItrsActions';
import useSessionConfigurations from '../hooks/useSessionConfigurations';

const SessionTable = ({ courseId, hideOptions, reloadParent, hidePastSessions = false }) => {
  const { coursePageId, typeName } = ItrsCourseConstants;
  const { addSession, editSession, viewSession, removeSession } = useItrsActions(reloadParent);
  const { columns, api } = useSessionConfigurations();
  const toolbarActions = useArray(addSession);
  const itemActions = useArray(editSession, viewSession, removeSession);
  const memoizedAdditionalLoaderParams = useMemo(
    () => ({ courseId, hidePastSessions }),
    [courseId, hidePastSessions]
  );
  return (
    <div style={{ width: '95%', margin: '0 auto', marginTop: '30px', marginBottom: '27px' }}>
      <CmsItemListSection
        pageId={coursePageId}
        typeName={typeName}
        toolbarActions={hideOptions ? [] : toolbarActions}
        itemActions={itemActions}
        columns={columns}
        itemApi={api}
        memoizedAdditionalLoaderParams={memoizedAdditionalLoaderParams}
        showSearchBar={!hideOptions}
      />
    </div>
  );
};

export default SessionTable;
