const validPhone = (val) => {
  if (val.includes('+')) return 'Must not have the country code';
  const digits = val.replaceAll(/[\s()-]/g, '');
  if (Number.isNaN(digits) || digits.length !== 10) return 'Must be a valid phone number';
};
export const UNHANDLED = 'IMPORT_UNHANDLED_FIELDS';
export const RESTRICTED_FIELDS = new Set([
  'subtenantId',
  'userType',
  'email',
  'firstName',
  'lastName',
  'phone',
]);
export const PRIMARY_FIELDS = new Set(['firstName', 'lastName', 'userType', 'subtenantId']);

export default function useFieldValidators(fields = []) {
  const fieldTypes = {};
  const fieldToId = {};
  const customFieldsSet = new Set();
  const fieldValidators = fields?.reduce(
    (a, field) => {
      fieldTypes[field.field] = field.type;
      fieldToId[field.field] = field.id;
      if (!RESTRICTED_FIELDS.has(field.field)) customFieldsSet.add(field.field);
      return PRIMARY_FIELDS.has(field.field)
        ? a
        : {
            ...a,
            [field.field]: (val) => {
              if (!val) return;
              if (field.field === 'phone') {
                const err = validPhone(val);
                if (err) return err;
              }
              switch (field.type) {
                case 'date': {
                  try {
                    if (Number.isNaN(new Date(val)?.getTime())) return 'Must be a valid date';
                  } catch {
                    return 'Must be a valid date';
                  }
                  break;
                }
                case 'number': {
                  break;
                }
                case 'integer': {
                  break;
                }
                case 'phone': {
                  const err = validPhone(val);
                  if (err) return err;
                  break;
                }
                case 'float': {
                  break;
                }
                case 'multiselect':
                case 'checkbox': {
                  try {
                    let valArr = Array.isArray(val) ? val : undefined;
                    if (!valArr) {
                      const parsed = JSON.parse(val);
                      valArr = Array.isArray(parsed) ? parsed : undefined;
                    }
                    if (!valArr) return 'Must be a valid array';
                  } catch {
                    return 'Must be a valid array';
                  }
                  break;
                }

                case 'text':
                case 'textarea':
                case 'select':
                case 'radio': {
                  return true;
                }
                default: {
                  return true;
                }
              }
            },
          };
    },
    { lastName: 'truthy', firstName: 'truthy', email: 'emailOrEmpty' }
  );
  return { fieldValidators, fieldTypes, fieldToId, customFieldsSet };
}
