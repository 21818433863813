import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CmsPageSectionFooter from '../../../shared/cmsPage/components/CmsPageSectionFooter';
import useItemEditActions from '../../../shared/cmsPage/hooks/useItemEditActions';
import Select from '../../../shared/form/components/Select';
import { useCmsForm } from '../../../shared/form/hooks/useCmsForm';
import ActionButton from '../../../shared/muiButtons/muiButton';
import { selectHasPrivileges } from '../../../store/rootSelectors';
import { upsertTravelConfirmation } from '../../api/travelApi';
import { ItrsSessionConstants } from '../../definitions';
import ItrsTravelSegmentForm from './ItrsTravelSegmentForm';

const Heading = ({ children, subheading = false, sx = {}, ...rest }) => (
  <Typography
    variant={subheading ? 'subtitle1' : 'h6'}
    sx={(theme) => ({
      backgroundColor: subheading ? theme.palette.bootstrap.grey : theme.palette.bootstrap.blue,
      color: 'white',
      padding: theme.spacing(1, 1),
      ...sx,
    })}
    {...rest}
  >
    {children}
  </Typography>
);

const Detail = ({ label, value }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Box>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
        {`${label}:`}
      </Typography>
    </Box>
    <Box sx={{ pl: '1rem' }}>
      <Typography variant="body1" gutterBottom>
        {value}
      </Typography>
    </Box>
  </Box>
);

//statuses: Sequelize.ENUM('none', 'submitted', 'confirmed', 'cancelled'),
const statuses = [
  { label: 'None', value: 'none' },
  { label: 'Submitted', value: 'submitted' },
  { label: 'Confirmed', value: 'confirmed' },
  { label: 'Cancelled', value: 'cancelled' },
];

const TravelConfirmSection = ({ travelConfirmation, theme, travelRequestId }) => {
  const [showConfirmView, setShowConfirmView] = useState(true);
  const form = useCmsForm(travelConfirmation);
  const hasItrsTravelPrivileges = useSelector(selectHasPrivileges('itrs.travel'));
  const { setValue } = form;
  const actions = useItemEditActions(
    upsertTravelConfirmation(travelRequestId),
    form,
    ItrsSessionConstants,
    travelRequestId,
    '',
    { refreshPageParams: { hardRefresh: true }, cancelOverride: () => setShowConfirmView(true) }
  );
  const { travelTo: to = {}, departFrom: from = {} } = travelConfirmation || {};

  const reloadForm = () => {
    setValue('status', travelConfirmation.status);
    setValue('travelToAirport', to.Airport);
    setValue('travelToAirline', to.Airline);
    setValue('travelToFlight', to.Flight);
    setValue('travelToDepartDate', to.DepartDate);
    setValue('travelToArrivalDate', to.ArrivalDate);
    setValue('travelToConfirmation', to.Confirmation);
    setValue('departFromAirport', from.Airport);
    setValue('departFromAirline', from.Airline);
    setValue('departFromFlight', from.Flight);
    setValue('departFromDepartDate', from.DepartDate);
    setValue('departFromArrivalDate', from.ArrivalDate);
    setValue('departFromConfirmation', from.Confirmation);
  };

  const travelToAirport = to?.Airport || '';
  const travelToAirline = to?.Airline || '';
  const travelToFlight = to?.Flight || '';
  const travelToDepartDate = to?.DepartDate || '';
  //const travelToArrivalDate = to?.ArrivalDate||''
  const travelToConfirmation = to?.Confirmation || '';

  const departFromAirport = from?.Airport || '';
  const departFromAirline = from?.Airline || '';
  const departFromFlight = from?.Flight || '';
  const departFromDepartDate = from?.DepartDate || '';
  //const departFromArrivalDate = from?.ArrivalDate||''
  const departFromConfirmation = from?.Confirmation || '';

  const bookedBy = travelConfirmation?.bookerEmail || '';
  const flightInfo = (airline, flight, confirm) =>
    `${airline}${flight ? ` Flight ${flight}` : ''} ${
      confirm ? ` (Confirmation No. ${confirm})` : ''
    }`;
  return (
    <Box sx={{ my: theme.spacing(3) }}>
      <Heading gutterBottom>Traveller Confirmation</Heading>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          pt: '1rem',
        }}
      >
        {showConfirmView && hasItrsTravelPrivileges && (
          <ActionButton
            variant="contained"
            type="button"
            label={`Enter Travel Confirmation`}
            onClick={() => {
              if (travelConfirmation) {
                reloadForm();
              } else {
                form.reset();
              }
              setShowConfirmView(false);
            }}
          />
        )}
      </Box>

      {showConfirmView && travelConfirmation && (
        <Box sx={{ my: theme.spacing(3) }}>
          <Detail label="Departing From (Airport)" value={travelToAirport} />
          <Detail
            label="Departing Flight"
            value={flightInfo(travelToAirline, travelToFlight, travelToConfirmation)}
          />
          <Detail
            label="Departing Date"
            value={
              travelToDepartDate
                ? moment(travelToDepartDate).format('dddd, MMMM DD, YYYY - hh:mm A')
                : ''
            }
          />
          <Detail label="Returning From (Airport)" value={departFromAirport} />
          <Detail
            label="Returning Flight"
            value={flightInfo(departFromAirline, departFromFlight, departFromConfirmation)}
          />
          <Detail
            label="Returning Date"
            value={
              departFromDepartDate
                ? moment(departFromDepartDate).format('dddd, MMMM DD, YYYY - hh:mm A')
                : ''
            }
          />
          <Detail label="Booked By" value={bookedBy} />
        </Box>
      )}

      {!showConfirmView && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '612px',
            }}
          >
            <Select
              labelKey="label"
              label={<span style={{ paddingInlineStart: '1rem' }}>Status</span>}
              options={statuses}
              formContext={form}
              placeholder={'Status'}
              fieldName="status"
              type="select"
              valueKey={'value'}
              value={''}
              fieldWidth="short"
              fontWeight={500}
              findValueInOptions={true}
              formFieldOptions={{
                required: 'This field is required.',
              }}
              variant="outlined"
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'stretch',
                justifyContent: 'space-between',
              }}
            >
              <ItrsTravelSegmentForm
                form={form}
                theme={theme}
                prefix="travelTo"
                heading="Travel to Training"
              />
              <div
                style={{
                  width: '1px',
                  backgroundColor: '#EEEEEE',
                  minHeight: '1px',
                }}
              />
              <ItrsTravelSegmentForm
                form={form}
                theme={theme}
                prefix="departFrom"
                heading="Depart from Training"
              />
            </Box>

            <div className="row module-footer">
              <CmsPageSectionFooter actions={actions} />
            </div>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TravelConfirmSection;
