import React, { useCallback, useContext } from 'react';
import { CmsContext } from '../../../context/definitions';
import FormInputMenu from './FormInputMenu';

const LinkFinder = (props) => {
  const { fieldName, formContext, required, defaultValue, allowEmbeddedMenus } = props;
  const formFieldOptions = required
    ? {
        required: 'This field is required.',
      }
    : {};
  const { showLinkSearch } = useContext(CmsContext);
  const findLink = useCallback(async () => {
    const currentValue = formContext.getValues()[fieldName];
    const linkExpr = await showLinkSearch(currentValue, allowEmbeddedMenus);
    formContext.setValue(fieldName, linkExpr || currentValue, {
      shouldDirty: !!linkExpr,
      shouldTouch: true,
      shouldValidate: true,
    });
  }, [formContext, fieldName, showLinkSearch, allowEmbeddedMenus]);

  return (
    <div>
      <div className="link-finder">
        <div>
          <label>Link Item to </label>
          <a
            className="clickable"
            onClick={findLink}
            style={{ fontWeight: 700, marginLeft: 3, color: '#345784' }}
          >
            (Select)
          </a>
        </div>
      </div>

      <div>
        <FormInputMenu
          readOnly={true}
          fieldName={fieldName}
          defaultValue={defaultValue}
          formContext={formContext}
          formFieldOptions={formFieldOptions}
        />
      </div>
    </div>
  );
};

export default LinkFinder;
