import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';
import toNumber from 'lodash/toNumber';
import update from 'lodash/update';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import { showSnackbar } from '../../store/rootReducer';
import appUserApi from '../api/appUserApi';
import { CUSTOM_FIELD_PREFIX } from '../definitions';

export default function useModifiedItemPersistance(appUserId) {
  const { item: data, loadItem, saveItem } = useItemPersistence(appUserApi);
  const { appUser: item, fields } = data || {};
  const [userGroups, setUserGroups] = useState([]);
  const dispatch = useDispatch();
  const modifiedSave = useCallback(
    async (item) => {
      item.appUserCustomFieldInstances = [];
      Object.entries(item)
        .filter(([k]) => k.startsWith(CUSTOM_FIELD_PREFIX))
        .forEach(([k, v]) => {
          item.appUserCustomFieldInstances.push({
            id: toNumber(k.replace(CUSTOM_FIELD_PREFIX, '')),
            value: v,
          });
          delete item[k];
        });
      update(item, 'userGroups', (grps = []) => [
        ...grps,
        ...userGroups.filter((group) => !group.canAssign),
      ]);
      const resp = await saveItem(item, async (error) => {
        if (error?.message === 'Group Has Reached Maximum Occupancy') {
          dispatch(showSnackbar({ message: error?.message }));
        }
        throw error;
      });
      return resp;
    },
    [saveItem, userGroups, dispatch]
  );
  const initLoad = useCallback(async () => {
    if (appUserId) {
      const { appUser } = (await loadItem(appUserId)) || {};
      setUserGroups(get(appUser, 'userGroups', []));
    }
  }, [loadItem, appUserId, setUserGroups]);

  useEffect(() => {
    if (appUserId) {
      initLoad(appUserId);
    }
  }, [appUserId, initLoad]);
  const modifiedItem = useMemo(
    () =>
      isPlainObject(item)
        ? {
            ...item,
            ...(item.userGroups && { userGroups: item.userGroups.filter((grp) => grp.canAssign) }),
          }
        : item,
    [item]
  );
  return {
    saveItem: modifiedSave,
    item: modifiedItem,
    fields,
  };
}
