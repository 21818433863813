import React, { useCallback, useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import ItemTableHeaderCell from './ItemTableHeaderCell';
import ItemTableRow from './ItemTableRow';
import OrderedItemTableRowSelector from './OrderedItemTableRowSelector';
import { useDispatch } from 'react-redux';
import { errorGrowl } from '../../../store/rootThunks';
const OrderedItemTable = (props) => {
  const {
    items,
    columns,
    itemActions,
    reorder,
    parentItemId,
    growlId,
    emptyListMessage,
    injectedRowCount = 0,
    reorderDisabled = false,
  } = props;
  const [displayItems, setDisplayItems] = useState(items.length > 0 ? items : []);
  const dispatch = useDispatch();
  const reorderItems = useCallback(
    (evt) => {
      if (reorderDisabled) return;
      const { from, oldIndex, newIndex } = evt;
      try {
        const ids = Array.from(from.children).map((el) => el.dataset.id);
        const [removed] = ids.splice(oldIndex, 1);
        ids.splice(newIndex, 0, removed);
        reorder(parentItemId, ids);
      } catch (e) {
        dispatch(errorGrowl({ groupId: growlId, props: e.message }));
        setDisplayItems(items);
      }
    },
    [reorder, displayItems, parentItemId, dispatch, reorderDisabled]
  );

  useEffect(() => {
    setDisplayItems(items);
  }, [items]);

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th />
          {columns.map((column, index) => (
            <ItemTableHeaderCell key={index} column={column} />
          ))}
          {itemActions && <th />}
        </tr>
      </thead>
      {displayItems?.length ? (
        <ReactSortable
          tag="tbody"
          list={displayItems}
          draggable={reorderDisabled ? '' : '.draggable'}
          setList={setDisplayItems}
          onEnd={reorderItems}
        >
          {displayItems.map((item, itemIndex) => (
            <tr
              key={item.id}
              className={
                itemIndex >= injectedRowCount && !reorderDisabled
                  ? 'draggable'
                  : itemIndex === injectedRowCount - 1 && !reorderDisabled
                  ? 'static-end'
                  : 'static'
              }
            >
              {itemIndex >= injectedRowCount ? <OrderedItemTableRowSelector /> : <td />}
              <ItemTableRow key={item.id} item={item} columns={columns} itemActions={itemActions} />
            </tr>
          ))}
        </ReactSortable>
      ) : (
        <tbody>
          <tr>
            <td />
            <td className="table-message" colSpan={columns.length}>
              {emptyListMessage}
            </td>
            {itemActions && <td />}
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default OrderedItemTable;
