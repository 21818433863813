import { useEffect, useState } from 'react';
import { getUsersToEnroll } from '../../../api/sessionApi';

const useUserSearch = ({ courseId, sessionId, userType }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setResults([]);
    setNoResultsFound(false);
    setErrorMessage('');
  }, [userType]);

  const searchUsers = async ({ searchValue, userType }) => {
    setIsLoading(true);
    setResults([]);
    setNoResultsFound(false);
    setErrorMessage('');
    try {
      const users = await getUsersToEnroll({
        searchValue,
        courseId,
        sessionId,
        type: userType,
      });

      if (users && users.length > 0) {
        setResults(users.map((u) => ({ ...u, name: `${u.lastName}, ${u.firstName}` })));
        setNoResultsFound(false);
      } else {
        setResults([]);
        setNoResultsFound(true);
      }
    } catch {
      setErrorMessage('An error occurred while searching for users. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, results, noResultsFound, errorMessage, searchUsers };
};

export default useUserSearch;
