import React, { useEffect, useState } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import useLoadEditorItem from '../../shared/cmsPage/hooks/useLoadEditorItem';
import NumberedPageHeader from '../../shared/form/components/NumberedPageHeader';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import sessionApi from '../api/sessionApi';
import { ItrsCourseConstants, ItrsSessionConstants } from '../definitions';
import AttendeeDetailForm from './AttendeeDetailForm';
import { BackToCourses } from './BackButtons';
import ResourceRequirementForm from './ResourceRequirementForm';
import SessionDetailForm from './SessionDetailForm';

const PAGES = ['Session Details', 'Attendee Details', 'Resource Requirements'];
const FIELDS = [
  [
    'status',
    'startDate',
    'endDate',
    'cutoffDays',
    'name',
    'billingCategory',
    'description',
    'trainingLocation',
    'category',
    'program',
  ],
  ['student', 'instructor', 'assistant'].flatMap((k) => [`${k}Min`, `${k}Max`]),
  ['contact', 'equipment', 'addNeeds', 'instrNeeds', 'roomReservations'],
];
const { defaultPageId: sessionPageId } = ItrsSessionConstants;
const ItrsSessionCreatePage = ({ sessionId, courseId }) => {
  const { loadCourse } = sessionApi;
  const { item, saveItem, loadItem } = useItemPersistence(sessionApi);

  const form = useCmsForm(item);
  useLoadEditorItem(sessionId, loadItem, () => {}, item, ItrsCourseConstants);
  const { setValue, trigger, register } = form;
  const [saveNoValidation, cancel] = useItemEditActions(
    saveItem,
    form,
    {
      ...ItrsSessionConstants,
      defaultPageId: sessionPageId,
      defaultPageName: sessionPageId,
    },
    undefined,
    'id'
  );
  const [idx, setIdx] = useState(0);
  const [courseLoaded, setCourseLoaded] = useState(false);
  const [courseDuration, setCourseDuration] = useState(0);
  const [sessionName, setSessionName] = useState('');

  useEffect(() => {
    const setRecords = async () => {
      const course = await loadCourse(courseId);
      setValue('category', course.category);
      setValue('program', course.program);
      setCourseLoaded(true);
      setCourseDuration(course.duration);

      const foundSession = course.sessions.find((s) => s.id === sessionId);
      setSessionName(foundSession?.name);
    };
    const loadData = async () => {
      if (courseId) {
        await setRecords();
        setCourseLoaded(true);
      }
    };
    loadData();
  }, [sessionId, courseId]);

  const back = { onClick: () => setIdx((p) => p - 1), label: 'Back' };
  const next = {
    onClick: async () => (await trigger(FIELDS[idx])) && setIdx((p) => p + 1),
    label: 'Continue',
  };

  const save = {
    ...saveNoValidation,
    onClick: async () => {
      setValue('courseId', courseId);
      const isValid = await trigger(FIELDS[idx]);
      if (isValid && saveNoValidation.onClick) {
        await saveNoValidation.onClick();
      }
    },
    label: 'Save',
  };
  const actions = [idx === 2 ? save : next, idx === 0 ? cancel : back];
  const page = PAGES[idx];

  if (!courseLoaded) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <BackToCourses />
      <CmsPage
        title={`${sessionId && sessionId !== 'new' ? 'Update' : 'Add'} Session${
          sessionName ? ` - ${sessionName}` : ''
        }`}
      >
        <NumberedPageHeader headers={PAGES} selectedHeader={page} />
        <form>
          <input type="hidden" value={courseId} {...register('courseId')} />
          {idx === 0 && (
            <SessionDetailForm
              form={form}
              sessionId={sessionId}
              courseDuration={courseDuration}
              sessionName={sessionName}
            />
          )}
          {idx === 1 && <AttendeeDetailForm form={form} />}
          {idx === 2 && <ResourceRequirementForm form={form} />}
        </form>

        <CmsPageSectionFooter actions={actions} />
      </CmsPage>
    </>
  );
};

export default ItrsSessionCreatePage;
