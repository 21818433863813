import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

const ModalHeader = ({ title, onClose }) => (
  <Box
    sx={{ px: 4, py: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
  >
    <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: 16 }}>
      {title}
    </Typography>
    <IconButton onClick={onClose}>
      <CloseOutlined />
    </IconButton>
  </Box>
);

export default ModalHeader;
