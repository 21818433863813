import React from 'react';

export default function Errors({ errors = [], saveDataError }) {
  return [...errors, ...(saveDataError ? ['Error Saving Data'] : [])].map((error, idx) => {
    let msg = error?.message ? error?.message : error;
    if (typeof msg === 'string') msg = msg.split('\n');
    return (
      <div className="alert alert-danger" role="alert" key={`${error}-${idx}`}>
        {Array.isArray(msg) ? msg.map((line, i) => <div key={i}>{line}</div>) : msg}
      </div>
    );
  });
}
