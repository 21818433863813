import axios from 'axios';
import log from '../../logger';
import { EMPTY_APP_USER_REVISION } from '../definitions';
const baseUrl = '/user-api';

const loadRecords = async (params) => {
  const { sort, ...otherParams } = params;
  const { sortField, order } = sort;
  const sortReverse = sortField ? order === 'DESC' : undefined;
  let result;
  try {
    const { data } = await axios.get(`${baseUrl}/getUsers`, {
      params: { ...otherParams, sortField, sortReverse },
      timeout: 10_000,
    });
    const { appUsers, count, offset } = data;
    result = { records: appUsers, numRecords: count, offset };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load appUsers');
  }
  return result;
};

const loadRecord = async (appUserId) => {
  try {
    const { data } = await axios.get(
      `${baseUrl}/${appUserId === 'new' ? `getAppUserCustomFields` : `getUserById`}`,
      {
        params: { appUserId, includeCustomFields: true },
        timeout: 10_000,
      }
    );
    return {
      appUser: appUserId === 'new' ? EMPTY_APP_USER_REVISION : data.appUser,
      fields: data.fields,
      id: appUserId,
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load appUser.');
  }
};

const saveRecord = async (item) => {
  try {
    const { id, ...revisionProps } = item;
    const { data } = await axios.post(
      `${baseUrl}/upsertUser`,
      { ...revisionProps, id },
      { timeout: 60_000 }
    );
    return data.appUser;
  } catch (e) {
    const msg = e?.response?.data?.error || 'Failed to save appUser.';
    log.error(msg);
    throw new Error(msg);
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(`${baseUrl}/deleteUser`, { id }, { timeout: 10_000 });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove appUser.');
  }
};

const createReport = async (otherEmails) => {
  try {
    const { data } = await axios.get(
      `${baseUrl}/getAllUsers?otherEmails=${JSON.stringify(otherEmails)}`,
      { timeout: 10_000 }
    );
    return data;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to create report.');
  }
};

const customColumns = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getCustomFieldColumns`, {
      timeout: 10_000,
    });
    return data;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to create report.');
  }
};

export const updateAppUserCustomFields = async (appUserCustomFields) => {
  try {
    const { data } = await axios.post(`${baseUrl}/updateAppUserCustomFields`, {
      appUserCustomFields,
    });
    return data;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to update custom fields.');
  }
};

export const getAppUserCustomFields = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getAppUserCustomFields`);
    return data.fields;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to get custom fields.');
  }
};

export const importAppUsers = async ({ data: users, uniqueFields, overwriteFields }) => {
  try {
    const { data } = await axios.post(`${baseUrl}/importAppUsers`, {
      users,
      uniqueFields,
      overwriteFields,
    });
    return data;
  } catch (err) {
    log.error(err);
    throw new Error('Failed to import app users');
  }
};

const appUserApi = {
  loadRecords,
  loadRecord,
  saveRecord,
  removeRecord,
  createReport,
  customColumns,
};

export default appUserApi;
