import React, { memo, useState, useCallback, useEffect } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import toString from 'lodash/toString';

const TextInput = ({ fieldKey, label, setInput, value, isDisabled }) => {
  const [currentValue, setCurrentValue] = useState('');
  useEffect(() => {
    setCurrentValue(toString(value));
  }, [value]);

  const handleChange = useCallback(
    (e) => {
      setCurrentValue(e.target.value);
      setInput(fieldKey, e.target.value);
    },
    [setInput, fieldKey]
  );

  return (
    <FormControl
      type="text"
      placeholder={label}
      disabled={isDisabled}
      value={isDisabled ? '' : currentValue}
      onChange={handleChange}
    />
  );
};

export default memo(TextInput);
