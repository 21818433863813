import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import { CmsPageContext } from '../../shared/cmsPage/definitions';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import useLoadEditorItem from '../../shared/cmsPage/hooks/useLoadEditorItem';
import AudienceSelector from '../../shared/form/components/AudienceSelector';
import CreateableSelect from '../../shared/form/components/CreatableSelect';
import FormImage from '../../shared/form/components/FormImage';
import FormInput from '../../shared/form/components/FormInput';
import FormInputTextArea from '../../shared/form/components/FormInputTextArea';
import FormSwitch from '../../shared/form/components/FormSwitch';
import FormWysiwyg from '../../shared/form/components/FormWysiwyg';
import { HELP_TEXT_KEYS, helpText } from '../../shared/form/components/help-icon-text.js';
import HelpPopover from '../../shared/form/components/HelpPopover';
import LinkFinderInline from '../../shared/form/components/LinkFinderInline';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { useSpinner } from '../../shared/spinner/hooks/useSpinner';
import collectionApi, { loadCategories } from '../api/collectionApi';
import collectionItemApi from '../api/collectionItemApi';
import { CollectionEditorConstants } from '../definitions';

import FormDate from '../../shared/form/components/FormDate';
import Select from '../../shared/form/components/Select';

const defaultPage = 'cms://default';
const CollectionItemEditor = ({ itemId, collectionId, growlId, onItemChange, changeTitle }) => {
  const { spinnerProvider } = useContext(CmsPageContext);
  const { item, loadItem, saveItem, requestPending } = useItemPersistence(
    collectionItemApi,
    collectionId
  );

  const form = useCmsForm(item);
  const { setValue, watch } = form;
  const actions = useItemEditActions(saveItem, form, CollectionEditorConstants, collectionId);

  const [imageBlock, setImageBlock] = useState(true);
  const [showTimeInputs, setShowTimeInputs] = useState(true);
  const [linkUsed, setLinkUsed] = useState(false);
  const [detailsUsed, setDetailsUsed] = useState(false);
  const [lockedUsed, setLockedUsed] = useState(false);
  const [currentCollectinType, setCurrentCollectinType] = useState('');

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const {
    item: collection,
    loadItem: loadCollection,
    requestPending: collectionRequestPending,
  } = useItemPersistence(collectionApi);

  const watchTimed = watch('timed');
  const watchLink = watch('useLink');
  const watchDetails = watch('useDetails');
  const disLabel = watch('displayLabel');
  const locked = watch('locked');
  const startTimeValue = watch('startTime');
  const endTimeValue = watch('endTime');

  useEffect(() => {
    if (item) {
      setLockedUsed(!!locked);
      setValue('lockedText', locked ? item?.lockedText || 'Locked' : '');
    }
  }, [locked, item]);

  useEffect(() => {
    if (item) {
      if (
        typeof item.objectReference === 'string' &&
        item.objectDetail != null &&
        item.objectDetail.plugin != 'default'
      ) {
        setValue('displayLabel', item.objectDetail.displayName || item.objectDetail.plugin);
      }
      if (typeof item.objectReference === 'string' && item.objectDetail == null) {
        setValue('displayLabel', 'cms://default');
        setValue('objectReference', 'cms://default');
      }
    }
  }, [item]);

  useEffect(() => {
    if (item && typeof disLabel === 'object') {
      setValue('displayLabel', disLabel.targetLabel);
      setValue('objectReference', disLabel.returnVal);
    }
  }, [disLabel]);

  useEffect(() => {
    if (collectionId) (async () => await loadCollection(collectionId))();
  }, [collectionId, loadCollection]);

  useEffect(() => {
    if (collection) {
      setCurrentCollectinType(collection.type);
      changeTitle(collection.name);
      setImageBlock(collection.useImage);
    }
  }, [collection]);

  useEffect(() => {
    if (item) {
      setShowTimeInputs(watchTimed === 'timed');
    }
  }, [watchTimed, item]);

  useEffect(() => {
    if (item) {
      setValue('timed', watchTimed);
    }
  }, [item, watchTimed]);

  useEffect(() => {
    if (item) {
      setLinkUsed(!!watchLink);
      setValue('actionButtonText', watchLink ? item.actionButtonText || 'View' : '');
    }
  }, [watchLink, item]);

  useEffect(() => {
    if (item) {
      setDetailsUsed(!!watchDetails);
      setValue('infoButtonText', watchDetails ? item.infoButtonText || 'Details' : '');
    }
  }, [watchDetails, item]);

  useEffect(() => {
    form.trigger('startTime');
    form.trigger('endTime');
  }, [startTimeValue, endTimeValue]);

  useSpinner(spinnerProvider, requestPending || collectionRequestPending);
  useLoadEditorItem(itemId, loadItem, onItemChange, item, CollectionEditorConstants);
  const getCategories = useCallback(async () => loadCategories(collectionId), [collectionId]);

  const setRatio = ['icons', 'itrs'].includes(currentCollectinType) ? 300 / 300 : 325 / 200;

  return (
    <>
      <div className="mt-2">
        <form role="form">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormInput
              label="Collection Item Name"
              fieldName="label"
              formContext={form}
              fieldWidth="normal"
              formFieldOptions={{ required: 'This field is required.' }}
            />
            <HelpPopover helpText={helpText[HELP_TEXT_KEYS.LABEL]} style={{ marginLeft: 5 }} />
          </div>

          <div className="my-4" style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              label="Timed/Untimed *"
              options={[
                { label: 'Untimed', value: 'untimed' },
                { label: 'Timed', value: 'timed' },
              ]}
              formContext={form}
              fieldName="timed"
              fieldWidth="normal"
              value={watchTimed || 'untimed'}
            />
            <HelpPopover helpText={helpText[HELP_TEXT_KEYS.TIMED]} style={{ marginLeft: 5 }} />
          </div>

          <div className={showTimeInputs ? 'col-sm-12 mb-4' : 'undisplayed'}>
            <FormSwitch
              switchLabel="Display Time"
              fieldName="displayTime"
              selectedName="displayTime"
              formContext={form}
            />
          </div>

          <div className={showTimeInputs ? 'col-sm-12 mb-2' : 'undisplayed'}>
            <FormDate
              label={`Start of Event (${timeZone}) - `}
              fieldName="startTime"
              fieldWidth="normal"
              formContext={form}
              formFieldOptions={{
                required: showTimeInputs && 'This field is required.',
                validate: (value) => {
                  if (endTimeValue && new Date(value) > new Date(endTimeValue)) {
                    return 'Start time cannot be after end time';
                  }
                  return true; // Validation passed
                },
              }}
            />
          </div>

          <div className={showTimeInputs ? 'col-sm-12 mb-4' : 'undisplayed'}>
            <FormDate
              label={`End of Event (${timeZone}) - `}
              fieldName="endTime"
              fieldWidth="normal"
              formContext={form}
              formFieldOptions={{
                required: showTimeInputs && 'This field is required.',
                validate: (value) => {
                  if (startTimeValue && new Date(value) < new Date(startTimeValue)) {
                    return 'End time cannot be before start time';
                  }
                  return true; // Validation passed
                },
              }}
            />
          </div>

          <div
            className="col-sm-12 mb-4"
            style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', minHeight: 62 }}
          >
            <div style={{ display: 'flex' }}>
              <FormSwitch switchLabel="Link" fieldName="useLink" formContext={form} />
              <HelpPopover helpText={helpText[HELP_TEXT_KEYS.LINK]} style={{ marginLeft: 5 }} />
            </div>

            {linkUsed && (
              <div style={{ marginLeft: 20 }}>
                <FormInput
                  fieldName="actionButtonText"
                  formContext={form}
                  placeholder="View"
                  fieldWidth={383}
                  className=""
                  disabled={!linkUsed}
                />
              </div>
            )}

            {showTimeInputs && (
              <div className="col-sm-12 mt-2">
                <FormSwitch
                  switchLabel="Show Link outside active time"
                  fieldName="alwaysShowLink"
                  formContext={form}
                />
              </div>
            )}
          </div>

          {linkUsed && (
            <div className="left-margin-12 row">
              <LinkFinderInline
                formContext={form}
                label="Link Item To:"
                fieldName="displayLabel"
                fieldWidth={474}
                labelWeight={800}
                defaultValue={defaultPage}
              />
            </div>
          )}

          <div
            className="col-sm-12 mb-4"
            style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', minHeight: 62 }}
          >
            <div style={{ display: 'flex' }}>
              <FormSwitch switchLabel="Details Page" fieldName="useDetails" formContext={form} />
              <HelpPopover helpText={helpText[HELP_TEXT_KEYS.DETAIL]} style={{ marginLeft: 5 }} />
            </div>

            {watchDetails && (
              <div style={{ display: 'inline-block', marginLeft: 20 }}>
                <FormInput
                  fieldName="infoButtonText"
                  formContext={form}
                  placeholder="Details"
                  fieldWidth={305}
                  disabled={!detailsUsed}
                />
              </div>
            )}
          </div>

          {watchDetails && (
            <div className="col-sm-10">
              <FormWysiwyg
                fieldWidth="normal"
                tag="textarea"
                label=""
                formContext={form}
                fieldName="infoText"
                growlId={growlId}
              />
            </div>
          )}

          {imageBlock && (
            <div className="col-sm-12 my-4">
              <HelpPopover
                helpText={helpText[HELP_TEXT_KEYS.DISPLAY_IMAGE]}
                style={{ position: 'absolute', marginLeft: 118 }}
              />
              <FormImage
                label="Display Image"
                uploadButtonText="Upload"
                existingAssetIdField="tileAssetId"
                fieldName="tileAssetUpload"
                formContext={form}
                defaultAssetId="collection-item-default.png"
                useCropper={true}
                croppedRatio={setRatio}
                dropzoneSettings={{
                  maxSize: 5 * 1024 * 1024, // 5MB
                }}
              />
            </div>
          )}

          <div className="col-sm-12 mb-4">
            <HelpPopover
              helpText={helpText[HELP_TEXT_KEYS.DESCRIPTION]}
              style={{ position: 'absolute', marginLeft: 98, zIndex: 1000 }}
            />
            <FormInputTextArea
              placeholder="Description of collection"
              label="Description"
              fieldWidth="normal"
              fieldName="description"
              formContext={form}
            />
          </div>

          <div className="col-sm-12 mb-4">
            <div style={{ display: 'flex', height: '10px' }}>
              <p style={{ color: '#888888' }}>Category</p>
              <HelpPopover
                helpText={helpText[HELP_TEXT_KEYS.CATEGORY]}
                style={{ position: 'absolute', marginLeft: 78 }}
              />
            </div>

            <CreateableSelect
              label="Category"
              fieldName="category"
              fieldWidth="normal"
              formContext={form}
              optionsLoader={getCategories}
            />
          </div>

          <div className="col-sm-12 mb-4" style={{ display: 'flex' }}>
            <AudienceSelector
              selectTextOnNewLine={false}
              className="pa-2"
              formContext={form}
              label="Available To "
              fieldName="condition"
              growlId={growlId}
              selectFieldText="Select"
            />
            <HelpPopover helpText={helpText[HELP_TEXT_KEYS.AUDIENCE]} style={{ marginLeft: 5 }} />
          </div>

          <div
            className="col-sm-12"
            style={{ alignItems: 'baseline', display: 'flex', flexWrap: 'wrap', minHeight: 62 }}
          >
            <div style={{ display: 'flex' }}>
              <FormSwitch switchLabel="Display As Locked" fieldName="locked" formContext={form} />
              <HelpPopover helpText={helpText[HELP_TEXT_KEYS.LABEL]} style={{ marginLeft: 5 }} />
            </div>

            {lockedUsed && (
              <div style={{ display: 'inline-block', marginLeft: 20 }}>
                <FormInput
                  fieldName="lockedText"
                  formContext={form}
                  placeholder="Locked"
                  className="neg-top-margin"
                  disabled={!lockedUsed}
                  fieldWidth={198}
                />
              </div>
            )}
          </div>
        </form>
      </div>
      <div className="col col-lg-12 col-md-12 col-sm-12">
        <div className="row module-footer">
          <CmsPageSectionFooter actions={actions} />
        </div>
      </div>
    </>
  );
};

export default CollectionItemEditor;
