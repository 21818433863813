import { Box, Button, Divider, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Spinner from '../../../shared/spinner/components/Spinner';
import { markCompletions } from '../../api/sessionApi';

const ConfirmCompletionModal = ({ sessionId, enrollmentId, fullName, invalidate }) => {
  const [localOpen, setLocalOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const handleConfirm = async () => {
    setProcessing(true);
    await markCompletions(sessionId, [enrollmentId]);
    await invalidate();
    setLocalOpen(false);
    setProcessing(false);
  };
  const handleClose = () => {
    setLocalOpen(false);
  };
  const key = `${sessionId ?? ''}${enrollmentId ?? ''}${fullName ?? ''}`;

  useEffect(() => {
    if (key) setLocalOpen(true);
  }, [key]);
  return (
    <Modal open={localOpen} onClose={handleClose} aria-labelledby="cancel-enrollment-title">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          width: '600px',
          borderRadius: 2,
          boxShadow: 24,
          p: 2,
        }}
      >
        <Typography fontSize="16px" fontWeight="bold">
          {`Mark Completion`}
        </Typography>
        <Divider sx={{ my: 2 }} />
        {processing ? (
          <Spinner />
        ) : (
          <Typography variant="body1" fontSize="14px" gutterBottom>
            {`Are you sure you want to mark completion for ${fullName}?`}
          </Typography>
        )}
        <Box display="flex" justifyContent="flex-start" mt={3}>
          <Button
            disabled={processing}
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            sx={{ mr: 2 }}
          >
            Mark Completion
          </Button>
          <Button disabled={processing} variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmCompletionModal;
