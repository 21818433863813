import React from 'react';
import Switch from 'react-switch';
const FormSwitch = ({ state, setState }) => {
  const handleChange = (jobSitesEnabled) => {
    setState((st) => ({ ...st, jobSitesEnabled: !!jobSitesEnabled }));
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
      <Switch
        onChange={handleChange}
        checked={!!state?.jobSitesEnabled}
        onColor="#8CBAE8"
        onHandleColor="#1976D2"
        height={14}
        width={32}
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      />
      <label>
        <div style={{ display: 'flex' }}>
          <span style={{ marginLeft: '8px', marginTop: '4px', fontWeight: 'normal' }}>
            {`Jobsites Enabled`}
          </span>
        </div>
      </label>
    </div>
  );
};

export default FormSwitch;
