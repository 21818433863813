import { useCallback, useState } from 'react';
export const useSubItemsLoader = (subItemsLoader) => {
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const loadItems = useCallback(
    async (parentItemId) => {
      setLoading(true);

      try {
        const { records } = await subItemsLoader({ parentItemId });
        setRecords(records);
      } catch (e) {
        setRecords([]);
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [subItemsLoader, setLoading, setRecords]
  );
  return {
    loadItems,
    loading,
    records,
  };
};
