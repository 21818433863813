import Typography from '@mui/material/Typography';
import React from 'react';

const Heading = ({ children, subheading = false, sx = {}, ...rest }) => (
  <Typography
    variant={subheading ? 'subtitle1' : 'h6'}
    sx={(theme) => ({
      backgroundColor: subheading ? theme.palette.bootstrap.grey : theme.palette.bootstrap.blue,
      color: 'white',
      padding: theme.spacing(1, 1),
      ...sx,
    })}
    {...rest}
  >
    {children}
  </Typography>
);

export default Heading;
