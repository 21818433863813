import React from 'react';
import AudienceSelector from '../../shared/form/components/AudienceSelector';
import FormImage from '../../shared/form/components/FormImage';
import FormLinksImage from '../../shared/form/components/FormLinksImage';
import FormCheckBox from '../../shared/form/components/FormCheckBox';
import FormDate from '../../shared/form/components/FormDate';
import FormDateOnly from '../../shared/form/components/FormDateOnly';
import FormInput from '../../shared/form/components/FormInput';
import FormInputFile from '../../shared/form/components/FormInputFile';
import FormInputInline from '../../shared/form/components/FormInputInline';
import FormInputMenu from '../../shared/form/components/FormInputMenu';
import FormInputTextArea from '../../shared/form/components/FormInputTextArea';
import FormRadioButton from '../../shared/form/components/FormRadioButton';
import FormSwitch from '../../shared/form/components/FormSwitch';
import FormSwitchControlled from '../../shared/form/components/FormSwitchControlled';
import FormWysiwyg from '../../shared/form/components/FormWysiwyg';
import LinkFinder from '../../shared/form/components/LinkFinder';
import LinkFinderInline from '../../shared/form/components/LinkFinderInline';
import LinkFinderMenu from '../../shared/form/components/LinkFinderMenu';
import MultiSelect from '../../shared/form/components/MultiSelect';
import ProvidedSelect from '../../shared/form/components/ProvidedSelect';
import Select from '../../shared/form/components/Select';
import SelectSwitch from '../../shared/form/components/SelectSwitch';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';

const TemplateItemsEditorForm = ({ form, growlId, actions }) => {
  return (
    <div className="row">
      <form role="form" className="no-left-padding">
        <FormInput
          label="Form Input"
          fieldName="FormInput"
          type="text"
          fieldWidth={500}
          fontWeight={800}
          formContext={form}
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />

        <AudienceSelector
          selectTextOnNewLine={false}
          className="pa-2"
          formContext={form}
          label="AudienceSelector"
          fieldName="AudienceSelector"
          growlId={growlId}
          selectFieldText="Select"
        />

        <FormImage
          label="FormImage"
          uploadButtonText="Upload"
          existingAssetIdField="tileAssetId"
          fieldName="FormImage"
          formContext={form}
          defaultAssetId="collection-item-default.png"
          useCropper={true}
          croppedRatio={13 / 8}
          dropzoneSettings={{
            maxSize: 5 * 1024 * 1024, // 5MB
          }}
        />

        <FormLinksImage
          label="FormLinksImage"
          uploadButtonText="Upload"
          existingAssetIdField="linksAssetId"
          fieldName="FormLinksImage"
          formContext={form}
          fontWeight={500}
          defaultAssetId="collection-links-default.png"
          useCropper={true}
          croppedRatio={16 / 9}
          formFieldOptions={{
            required: true,
          }}
        />

        <FormCheckBox
          label={'FormCheckBox'}
          fieldName={`FormCheckBox`}
          formContext={form}
          type="checkbox"
          forceCheck={false}
          preChecked={false}
        />

        <FormDate
          label={`Date and Time of Event - `}
          fieldName="FormDate"
          fieldWidth="normal"
          formContext={form}
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />

        <FormDateOnly
          label={`Date of Event`}
          fieldWidth="normal"
          fieldName="date"
          formContext={form}
        />

        <div>
          <FormInputFile label="FormInputFile" formContext={form} />
        </div>

        <FormInputInline
          label={'Form Input Inline'}
          fieldName={`FormInputInline`}
          fieldWidth="normal"
          variant="outlined"
          formContext={form}
          type="text"
          labelWeight={800}
          formFieldOptions={{
            required: 'This field is required.',
            maxLength: 255,
          }}
        />

        <FormInputMenu
          readOnly={true}
          fieldName={'FormInputMenu'}
          defaultValue={''}
          formContext={form}
        />

        <FormInputTextArea
          label={'FormInputTextArea'}
          fieldName="FormInputTextArea"
          formContext={form}
        />

        <FormRadioButton
          disabled={false}
          name="FormRadioButton"
          label={'FormRadioButton'}
          fieldName={'scheduleType'}
          formContext={form}
          type="radio"
          value="value"
        />

        <FormSwitch
          switchLabel="FormSwitch"
          fieldName="FormSwitch"
          selectedName="Selected"
          formContext={form}
        />

        <FormSwitchControlled
          checked={true}
          switchLabel={'FormSwitchControlled'}
          fontWeight={300}
          fieldName={'FormSwitchControlled'}
          formContext={form}
          disabled={false}
          updateParent={() => {}} // eslint-disable-line
        />

        <FormWysiwyg
          tag="textarea"
          label="FormWysiwyg"
          formContext={form}
          fieldName="FormWysiwyg"
          growlId={growlId}
        />

        <LinkFinder
          disabled={false}
          formContext={form}
          label="LinkFinder"
          fieldName="LinkFinder"
          allowEmbeddedMenus={true}
          required={false}
        />

        <LinkFinderInline
          formContext={form}
          label="LinkFinderInline"
          fieldName="LinkFinderInline"
          fieldWidth="normal"
          defaultValue={'cms://default'}
        />

        <LinkFinderMenu
          formContext={form}
          label="LinkFinderMenu"
          fieldName="LinkFinderMenu"
          defaultValue={'cms://default'}
          allowEmbeddedMenus={false}
          required={false}
        />

        <MultiSelect
          isSingleSelect={false}
          labelKey="name"
          label="MultiSelect"
          fieldWidth="normal"
          fontWeight={500}
          options={[
            { id: 1, name: 'Option 1' },
            { id: 2, name: 'Option 2' },
            { id: 3, name: 'Option 3' },
          ]}
          formContext={form}
          placeholder="Select"
          fieldName="MultiSelect"
          valueKey="id"
          findValueInOptions={true}
          formFieldOptions={{
            required: true,
          }}
        />

        <ProvidedSelect
          label={'Cohort'}
          fieldName={'cohortId'}
          fieldWidth="normal"
          fontWeight={500}
          formContext={form}
          placeholder="Choose a cohort"
          selectOptions={[
            { id: 1, name: 'Cohort 1' },
            { id: 2, name: 'Cohort 2' },
            { id: 3, name: 'Cohort 3' },
          ].map((cohort) => {
            return { label: cohort.name, value: cohort.id };
          })}
          selectChange={(selected) => form.setValue('cohortId', selected.value)}
          formFieldOptions={{
            required: true,
          }}
        />

        <Select
          label="Select"
          options={[
            {
              label: 'Manual Entry',
              value: 'manual',
            },
            {
              label: 'Choose From List',
              value: 'list',
            },
          ]}
          formContext={form}
          fieldName="select"
          fieldWidth={500}
          fontWeight={500}
          defaultValue="manual"
          formFieldOptions={{
            required: true,
          }}
        />

        <SelectSwitch
          label="SelectSwitch"
          fieldWidth="normal"
          fontWeight={500}
          formContext={form}
          fieldName="SelectSwitch"
          defaultValue="option1"
          disabled={false}
          options={[
            { value: 'option1', label: 'Option 1' },
            { value: 'option2', label: 'Option 2' },
            { value: 'option3', label: 'Option 3' },
          ]}
          updateView={() => {}} // eslint-disable-line
          formFieldOptions={{
            required: true,
          }}
        />
      </form>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </div>
  );
};

export default TemplateItemsEditorForm;
