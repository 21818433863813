import { Grid, Checkbox, FormControlLabel, InputLabel } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect } from 'react';

const FormCheckBoxGroup = ({
  form,
  label,
  name,
  options = [],
  fieldWidth = 'normal',
  formFieldOptions = {},
  columns = 1, // default to 1 column
}) => {
  const {
    formState: { errors },
    watch,
    setValue,
    getValues,
    register,
    trigger,
  } = form;

  const { required } = formFieldOptions || {};
  const selectedValues = watch(name) || [];
  const err = errors?.[name];
  const msg = err?.message;

  const registeredProps = register(name, {
    validate: (value) => {
      if (
        required &&
        (!value || value.length === 0 || !value.some((v) => options?.some((o) => o.value === v)))
      ) {
        return `${label} is required and at least one valid option must be selected`;
      }
      return true;
    },
    ...formFieldOptions,
  });

  useEffect(() => {
    if (!Array.isArray(getValues(name))) {
      setValue(name, []);
    }
  }, [name, getValues, setValue]);

  const handleCheckChange = (opt) => {
    const val = new Set(getValues(name) || []);
    val[val.has(opt) ? 'delete' : 'add'](opt);
    setValue(name, Array.from(val), { shouldDirty: true });
    trigger(name);
  };

  return (
    <div
      className={classNames(`input-${fieldWidth}`, { 'has-error': !!err })}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
    >
      <InputLabel error={!!err} required={required} sx={{ pb: 1 }}>
        {label}
      </InputLabel>
      <Grid container spacing={1}>
        {options?.map(({ label: optLabel, value }, idx) => {
          const isChecked = selectedValues.includes(value);
          return (
            <Grid item xs={12} sm={Math.floor(12 / columns)} key={`${name}-${value}-${idx}`}>
              <FormControlLabel
                sx={{ '& .MuiButtonBase-root': { py: 0 } }}
                label={optLabel}
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={(e) => {
                      handleCheckChange(value);
                      registeredProps.onChange(e);
                    }}
                    onBlur={registeredProps.onBlur}
                  />
                }
              />
            </Grid>
          );
        })}
      </Grid>
      {err && <p style={{ color: '#dc3545', fontSize: '.875em', marginTop: '.25rem' }}>{msg}</p>}
    </div>
  );
};

export default FormCheckBoxGroup;
