import React, { useState } from 'react';
import { addAccount, removeAccount } from '../api/calendarListApi';
import Modal from 'react-bootstrap/Modal';
import GrowlContainer from '../../shared/growl/components/GrowlContainer';
function CalendarAccountModal({ onClose, growlId, accounts }) {
  // State variables
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [newAccountOpen, setNewAccountOpen] = useState(false);
  const [newAccountTitle, setNewAccountTitle] = useState({ title: '' });
  const [allData, setAllData] = useState(accounts);
  const [accountsOrderBy] = useState('name');
  const [deletionData, setDeletionData] = useState({ id: null, name: '', items: [] });

  // Functions
  const deleteAccount = (id) => {
    setAllData(allData.filter((account) => account.id !== id));
    setShowDeleteConfirmation(false);
    removeAccount(id);
  };

  const newAccount = () => {
    const newId = Date.now();
    const newAccountData = {
      id: newId,
      name: newAccountTitle.title,
      items: [],
    };
    setAllData([...allData, newAccountData]);
    setNewAccountTitle({ title: '' });
    setNewAccountOpen(false);
    addAccount(newAccountTitle.title);
  };

  const showDeleteModal = (id) => {
    const accountToDelete = allData.find((account) => account.id === id);
    if (accountToDelete) {
      setDeletionData({
        id: accountToDelete.id,
        name: accountToDelete.name,
        items: accountToDelete.items || [],
      });
      setShowDeleteConfirmation(true);
    }
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <Modal show={true} animation={false}>
      <GrowlContainer groupId={growlId} />
      <div className="module red">
        <div className="modal-header module-header">
          <button type="button" className="btn btn-default close" onClick={handleClose}>
            <i className="fa fa-times"></i>
          </button>
          <span className="modal-title">Manage Google Accounts</span>
        </div>

        {showDeleteConfirmation ? (
          <div className="modal-body module-body mb-16 pt-0">
            <form name="accountDialog" role="form">
              <div className="row border-bottom">
                <div className="form-horizontal">
                  <div className="form-group mb-16">
                    <p className="mt-16">
                      Are you sure you want to delete account <strong>{deletionData.name}</strong>?
                    </p>
                    {deletionData.items.length > 0 && (
                      <>
                        <span>The following calendars will also be deleted:</span>
                        <ul>
                          {deletionData.items.map((item, index) => (
                            <li className="disc-list" key={index}>
                              {item.name}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                    <button
                      className="btn"
                      type="button"
                      onClick={() => deleteAccount(deletionData.id)}
                    >
                      Confirm
                    </button>
                    <button
                      className="btn"
                      type="button"
                      onClick={() => setShowDeleteConfirmation(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="modal-body module-body">
            <div id="accountModalGrowl"></div>
            <form name="accountDialog" role="form">
              <div className="row border-bottom">
                <div className="form-horizontal">
                  <div className="form-group">
                    <button
                      id="addAccountBtn"
                      disabled={newAccountOpen}
                      onClick={() => setNewAccountOpen(!newAccountOpen)}
                      type="button"
                      className="btn"
                    >
                      Link a Google Account
                    </button>
                  </div>
                </div>
                {newAccountOpen && (
                  <div className="col-xs-10 padding-bottom">
                    <div className="form">
                      <div className="form-group">
                        <label htmlFor="newAccountName" className="col-form-label">
                          Create a name for a new linked Google account *
                        </label>
                        <input
                          type="text"
                          value={newAccountTitle.title}
                          onChange={(e) => setNewAccountTitle({ title: e.target.value })}
                          className="form-control"
                          name="newAccountName"
                          id="newAccountName"
                          placeholder="Account Name"
                        />
                      </div>
                      <div className="form-group">
                        <button
                          type="button"
                          onClick={() => {
                            setNewAccountOpen(false);
                            setNewAccountTitle({ title: '' });
                          }}
                          className="btn"
                        >
                          Cancel
                        </button>
                        <button
                          id="newAccountBtn"
                          onClick={newAccount}
                          type="button"
                          className="btn"
                          disabled={!newAccountTitle.title}
                        >
                          Choose Account from Google to Link...
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {allData
                .sort((a, b) => a[accountsOrderBy].localeCompare(b[accountsOrderBy]))
                .map((account, index) => (
                  <div
                    className={`row ${index === allData.length - 1 ? '' : 'border-bottom'}`}
                    key={account.id}
                  >
                    <div className="col-xs-12">
                      <div className="form-horizontal left-margin-12">
                        <div className="form-group">
                          <label
                            htmlFor={`deleteAccount${account.id}Btn`}
                            className="col-sm-10 control-label"
                            style={{ textAlign: 'left' }}
                          >
                            {account.name}
                          </label>
                          <div className="col-sm-2">
                            <button
                              id={`deleteAccount${account.id}Btn`}
                              onClick={() => showDeleteModal(account.id)}
                              type="button"
                              className="btn"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </form>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default CalendarAccountModal;
