import { Box, FormControl, InputLabel, FormHelperText } from '@mui/material';
import classNames from 'classnames';
import castArray from 'lodash/castArray';
import React, { Fragment } from 'react';
import Spinner from '../../spinner/components/Spinner';
import useFieldProps from '../hooks/useFieldProps';
import FormFieldError from './FormFieldError';
const FieldWrapper = (props) => {
  const {
    label,
    className: providedClassName,
    cn,
    children,
    showLabel,
    showError,
    fontWeight,
    showSpinner = false,
    controlProps,
    type,
  } = props;

  const showControl = !!controlProps;

  const { isRequired, width, fieldError, hasError } = useFieldProps(props);

  const Base = showControl ? FormControl : Box;
  const ErrorWrapper = showControl ? FormHelperText : Fragment;

  return (
    <Box>
      <Base
        className={classNames(
          providedClassName,
          { 'has-error': !!fieldError },
          ...(cn ? castArray(cn) : [])
        )}
        sx={{ my: '1rem', width }}
        {...(showControl && { ...controlProps, error: hasError })}
      >
        {showSpinner ? (
          <Box>
            <Spinner />
          </Box>
        ) : (
          <>
            {showLabel &&
              (type === 'select' ? (
                <InputLabel style={{ fontWeight, left: -15 }}>
                  {label}
                  {isRequired && <span className="required-annotation">*</span>}
                </InputLabel>
              ) : (
                <InputLabel style={{ fontWeight }}>
                  {label}
                  {isRequired && <span className="required-annotation">*</span>}
                </InputLabel>
              ))}
            {children}
            {showError && (
              <ErrorWrapper>
                <FormFieldError error={fieldError} />
              </ErrorWrapper>
            )}
          </>
        )}
      </Base>
    </Box>
  );
};

export default FieldWrapper;
