import React from 'react';
import FieldWrapper from './FieldWrapper';
import useFieldProps from '../hooks/useFieldProps';

const FormInput = (props) => {
  const {
    fieldName,
    placeholder,
    formFieldOptions,
    formContext,
    readOnly,
    defaultValue,
    type: providedType,
  } = props;
  const { inputAttributes } = useFieldProps(props);
  return (
    <FieldWrapper {...props} showError classes="form-group">
      <input
        type={providedType || 'text'}
        readOnly={readOnly}
        defaultValue={defaultValue}
        className="form-control-no-border"
        placeholder={placeholder}
        maxLength={255}
        {...inputAttributes}
        {...formContext.register(fieldName, formFieldOptions)}
      />
    </FieldWrapper>
  );
};

export default FormInput;
