/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid';

export interface WysiwygListener {
  onImageInserted(editor: any): void;
  onVideoInserted(editor: any): void;
  onInitialized(editor: any): void;
  onCodeViewChanged(active: any, editor: any): void;
}

export type CustomFroalaErrorCallback = (errorMsg: string | any) => void;

const getFileExtension = (image: Blob) => {
  const parts = image.type.split('/');
  const lastPart = parts[parts.length - 1];
  const finalPart = lastPart.includes('+') ? lastPart.split('+')[0] : lastPart;
  return finalPart;
}

export const addWysiwygEvents = (
  options: any,
  wysiwygListener: WysiwygListener,
  customFroalaErrorCallback: CustomFroalaErrorCallback | boolean | undefined | null
) => {
  options.events = {};

  options.events['video.beforeUpload'] = function(videos: any) {
    const editor: any = this;
    let name: string = uuidv4();
    const extn: string = getFileExtension(videos[0]);
    name += '.' + extn;

    const data = editor.opts.videoUploadParams.data
      ? editor.opts.videoUploadParams.data
      : {};

    const group: string = data && data.asset && data.asset.group ? data.asset.group : 'default';
    editor.opts.videoUploadParams.data = JSON.stringify({
      asset: {
        type: 'file',
        group: group,
        id: name,
        ignoreRevisionNumber: true,
        publishLive: true,
      },
    });
    return true;
  };

  options.events['image.beforeUpload'] = function(images: any) {
    const editor: any = this;
    let name: string = uuidv4();
    if (images.length > 0 && images[0].size > 5242880) {
      if (typeof customFroalaErrorCallback === 'function') {
        customFroalaErrorCallback('The image is too large. Maximum size is 5MB.');
      }
      return false;
    }

    const extn: string = getFileExtension(images[0]);
    name += '.' + extn;

    const data = editor.opts.imageUploadParams.data ? editor.opts.imageUploadParams.data : {};
    const group: string = data && data.asset && data.asset.group ? data.asset.group : 'default';
    editor.opts.imageUploadParams.data = JSON.stringify({
      asset: {
        type: 'file',
        group: group,
        id: name,
        ignoreRevisionNumber: true,
        publishLive: true,
      },
    });
  };

  const insertImageFunc = function($img: any, response: any) {
    const editor: any = this;
    const res = JSON.parse(response);

    if (res && res.asset && res.asset.id) {
      $img.attr('ng-src', 'cms://' + res.asset.id);
      clearImageDataAttrs($img);
    } else if ($img.data('id')) {
      $img.attr('ng-src', 'cms://' + $img.data('id'));
      clearImageDataAttrs($img);
    } else {
      $img.remove();
    }

    editor.events.trigger('contentChanged', [], true);
    wysiwygListener.onImageInserted(editor);

    return true;
  };

  const insertVideoFunc = function($videoContainer: any, response: any) {
    //if the video is embedded, response is null and no action needed
    if(!response) return;
    console.log('insertVideoFunc $videoContainer', $videoContainer, response);
    const editor: any = this;
    const $video = $videoContainer.find('video');
    const res = JSON.parse(response);

    if (res && res.asset && res.asset.id) {
      $video.attr('ng-src', 'cms://' + res.asset.id);
      clearImageDataAttrs($video);
    } else if ($video.data('id')) {
      $video.attr('ng-src', 'cms://' + $video.data('id'));
      clearImageDataAttrs($video);
    } else {
      $video.remove();
    }

    editor.events.trigger('contentChanged', [], true);
    wysiwygListener.onVideoInserted(editor);

    return true;
  };

  const clearImageDataAttrs = ($img: any) => {
    for (const key in $img.data()) {
      if (key !== 'id') {
        $img.removeData(key);
        $img.removeAttr('data-' + key);
      }
    }
  };

  options.events['image.inserted'] = insertImageFunc;
  options.events['image.replaced'] = insertImageFunc;

  options.events['video.inserted'] = insertVideoFunc;
  options.events['video.replaced'] = insertVideoFunc;

  options.events['initialized'] = function (e: any, editor: any) {
    wysiwygListener.onInitialized(editor);
  };

  options.events['imageManager.beforeDeleteImage'] = function(
    $img: any
  ) {
    const editor: any = this;
    const assetId = $img.data('id');
    editor.opts.imageManagerDeleteParams = editor.opts.imageManagerDeleteParams
      ? editor.opts.imageManagerDeleteParams
      : {};
    editor.opts.imageManagerDeleteParams.assetId = assetId;
  };

  options.events['commands.after'] = function (cmd: any) {
    const editor = this
    if (cmd === 'html') {
      wysiwygListener.onCodeViewChanged(editor.codeView.isActive(), editor);
    }
  };

  options.getHTMLContent = () => {
    let retVal = '';
    if (typeof options.froalaEditor === 'function') {
      retVal = options.froalaEditor('html.get');
    }
    return retVal ? retVal : '';
  };

  return options;
};
