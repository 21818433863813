import { useCallback, useState } from 'react';
import { EMPTY_SORT } from '../../itemTable/definitions';
export const useListLoader = (listLoader) => {
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    limit: 0,
    numRecords: 0,
    offset: 0,
    pageCount: 1,
  });
  const [sort, setSort] = useState(EMPTY_SORT);
  const loadItems = useCallback(
    async (params = {}) => {
      const { limit = Number.MAX_SAFE_INTEGER } = params;
      setLoading(true);

      try {
        const listLoaded = await listLoader(params);
        const { records, offset, numRecords } = listLoaded;
        const pageCount = Math.ceil(numRecords / limit);
        setRecords(records);
        setPagination({ currentPage: offset / limit + 1, limit, numRecords, offset, pageCount });
        setSort(params.sort);
      } catch (e) {
        setRecords([]);
        setPagination({ currentPage: 1, limit: 0, numRecords: 0, offset: 0, pageCount: 1 });
        setSort(EMPTY_SORT);
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [listLoader, setLoading, setRecords, setPagination, setSort]
  );
  return { loadItems, loading, pagination, records, sort };
};
