import { wysiwygConfigDefaults } from '../../common/wysiwygConfigDefaults';
import { merge } from 'lodash';

(function () {
  'use strict';
  angular
    .module('root', ['wysiwyg-config-service', 'preview-service', 'content-api-service'])
    .controller('RootCtrl', [
      '$rootScope',
      '$scope',
      '$timeout',
      '$state',
      'configService',
      'wysiwygConfigService',
      'previewService',
      'internalLinkService',
      'reportingService',
      'importService',
      'contentApiService',
      function (
        $rootScope,
        $scope,
        $timeout,
        $state,
        configService,
        wysiwygConfigService,
        previewService,
        internalLinkService,
        reportingService,
        importService,
        contentApiService
      ) {
        $scope.navigateToState = $state.go;
        $scope.addReport = reportingService.addReport;
        $scope.getReports = reportingService.getReports;
        $scope.notifyContentUpdated = _.debounce(() => {
          $rootScope.$broadcast('contentUpdated');
        }, 1000);
        $scope.showImportDialog = importService.showImportDialog;
        wysiwygConfigService
          .getWysiwygOptions(undefined, undefined, (message = '') =>
            $rootScope.$broadcast('CustomFroalaError', message)
          )
          .then(function (wysiwygOptions) {
            $rootScope.wysiwygOptions = merge(wysiwygOptions, wysiwygConfigDefaults);
            $timeout(() => {
              $scope.ready = true;
            });
          });
        configService.getConfig().then(function (config) {
          $scope.cmsConfig = config;
          $scope.tenants = config.accessibleTenants;
          $scope.showMultiTenant =
            $scope.tenants.length > 1 || Object.values($scope.tenants[0].subtenants).length > 1;
        });

        contentApiService.getAssetMetadata('cms-logo.png').then(function (meta) {
          if (meta?.latestRevision?.previewPath && !_.isEmpty(meta?.instanceMappings || {})) {
            const cmsLogo = meta.latestRevision.previewPath;
            $scope.cmsLogoImage = cmsLogo;
            $scope.bannerLogo = true;
          } else {
            $scope.bannerLogo = false;
          }
        });

        // Side bar support
        $scope.collapseSideBar = false;
        $scope.toggleSideBar = function () {
          $scope.collapseSideBar = !$scope.collapseSideBar;
        };
        $scope.launchPreview = previewService.launchPreview;
        $scope.showLinkSearch = (currentHref, allowEmbeddedMenus) => {
          return internalLinkService.showPopup(currentHref, 'normalizedLink', allowEmbeddedMenus);
        };
        window.onbeforeunload = function () {
          previewService.closePreviewWindow();
        };
        $rootScope.$on('defaultContentUpdated', previewService.reloadPreview);
        $rootScope.$on('contentUpdated', previewService.reloadPreview);
        $rootScope.$on('assetUpdated', previewService.reloadPreview);
        $rootScope.$on('dataUpdated', previewService.reloadPreview);
        $rootScope.$on('cmsInstanceChanged', function () {
          if (previewService.previewWindowIsValid()) previewService.initPreviewWindow();
        });
      },
    ])
    .controller('RootInitCtrl', [
      'navigationService',
      function (navigationService) {
        navigationService.navigateToFirstMenuItem();
      },
    ]);
})();
