import ContentPaste from '@mui/icons-material/ContentPaste';
import Flight from '@mui/icons-material/Flight';
import MailOutline from '@mui/icons-material/MailOutline';
import SchoolOutlined from '@mui/icons-material/SchoolOutlined';
import { Box } from '@mui/material';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigateToPluginPage } from '../../store/rootReducer';
import { ItrsCourseConstants } from '../definitions';
import DashboardItem from './ItrsDashboardItem';
import { selectHasPrivileges } from '../../store/rootSelectors';

const ItrsDashboardPage = () => {
  const dispatch = useDispatch();
  const hasTrainingPrivileges = useSelector(selectHasPrivileges('itrs.training'));
  const hasTravelPrivileges = useSelector(selectHasPrivileges('itrs.travel'));
  const hasReportingPrivileges = useSelector(selectHasPrivileges('itrs.reporting'));
  const hasMailPrivileges = useSelector(selectHasPrivileges('itrs.mail'));
  const nav = useCallback(
    (path) => () => {
      dispatch(
        navigateToPluginPage([ItrsCourseConstants.pluginId, ItrsCourseConstants[`${path}PageId`]])
      );
    },
    [dispatch]
  );

  return (
    <Box
      sx={{
        px: 0,
        mx: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexGrow: 1,
        pt: '4rem',
        height: '100vh',
      }}
    >
      <Box>
        {hasTrainingPrivileges && (
          <DashboardItem
            title="Courses"
            description="Manage courses, sessions, enrollments, etc."
            Icon={SchoolOutlined}
            onClick={nav('course')}
          />
        )}
        {hasTravelPrivileges && (
          <DashboardItem
            title="Travel requests"
            description="View and confirm travel requests"
            Icon={Flight}
            onClick={nav('travelRequests')}
          />
        )}
        {hasReportingPrivileges && (
          <DashboardItem
            title="Reports"
            description="Access detailed summaries, analytics, and insights"
            Icon={ContentPaste}
            onClick={nav('reports')}
          />
        )}
        {hasMailPrivileges && (
          <DashboardItem
            title="Mail log"
            description="View and track the history of email notifications"
            Icon={MailOutline}
            onClick={nav('mailLog')}
          />
        )}
      </Box>
    </Box>
  );
};

export default ItrsDashboardPage;
