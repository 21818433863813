import classNames from 'classnames';
import get from 'lodash/get';
import React, { useContext } from 'react';
import { ImageContext } from '../store';
import Cropper from './Cropper';

function ImageSelector(props) {
  const { useCropper, croppedRatio } = props;
  const {
    availableFilesMap,
    getInputProps,
    getRootProps,
    isDragAccept,
    isDragReject,
    isFocused,
    selectedFilePath,
    setCroppedImage,
  } = useContext(ImageContext);
  const { preview: src = '' } = get(availableFilesMap, `${selectedFilePath}`, {});
  return (
    <div style={{ display: 'flex' }}>
      <div
        {...getRootProps({
          className: classNames('dz-link', {
            'dz-focused': isFocused,
            'dz-accepted': isDragAccept,
            'dz-rejected': isDragReject,
          }),
        })}
      >
        <input {...getInputProps()} />
        {useCropper && selectedFilePath !== 'DEFAULT_FILE' ? (
          <Cropper src={src} croppedRatio={croppedRatio} setCroppedImage={setCroppedImage} />
        ) : (
          <div>
            <img src={src} className="dz-img" />
          </div>
        )}
      </div>
    </div>
  );
}

export default ImageSelector;
