import axios from 'axios';
import log from '../../logger';
const baseUrl = '/plugins/calendar-list/api';
export const getAccounts = async () => {
  let result;
  try {
    const { data } = await axios.get(`${baseUrl}/getAccounts`, {
      timeout: 10_000,
    });
    result = data.calendarAccounts;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load accounts');
  }
  return result;
};
export const getCalendars = async () => {
  let result;
  try {
    const { data } = await axios.get(`${baseUrl}/getCalendars`, {
      timeout: 10_000,
    });
    result = data.calendars;
    const lastResponses = data.lastResponses;
    for (const calendar of result) {
      calendar.lastResponse = lastResponses[calendar.id];
    }
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load calendars');
  }
  return result;
};
export const syncCalendars = async () => {
  try {
    await axios.post(`${baseUrl}/syncCalendars`, {
      timeout: 10_000,
    });
  } catch (e) {
    log.error(e);
    throw new Error('Failed to sync calendars');
  }
};
export const addAccount = async (accountName) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/addAccount`,
      { accountName },
      {
        timeout: 10_000,
      }
    );
    const { authUrl } = data;
    window.location.href = authUrl;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to add account');
  }
};
export const reauthAccount = async (accountId) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/reauthAccount`,
      { accountId },
      {
        timeout: 10_000,
      }
    );
    const { authUrl } = data;
    window.location.href = authUrl;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to add account');
  }
};
export const removeAccount = async (accountId) => {
  try {
    await axios.post(
      `${baseUrl}/removeAccount`,
      { accountId },
      {
        timeout: 10_000,
      }
    );
  } catch (e) {
    log.error(e);
    throw new Error('Failed to delete account');
  }
};
export const saveAccount = async (state, code) => {
  try {
    await axios.post(
      `${baseUrl}/saveAccountCredentials`,
      { code, state },
      {
        timeout: 10_000,
      }
    );
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save account');
  }
};
export const addCalendar = async (data) => {
  try {
    const response = await axios.post(`${baseUrl}/addCalendar`, data, {
      timeout: 10_000,
    });
    return response?.data?.calendar;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to save calendar');
  }
};
export const listAccountCalendars = async (accountId) => {
  let result = [];
  try {
    const { data } = await axios.get(`${baseUrl}/listAccountCalendars`, {
      params: { accountId },
      timeout: 10_000,
    });
    result = data.calendars;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to list account calendars');
  }
  return result;
};
export const removeCalendar = async (calendarId) => {
  try {
    await axios.post(
      `${baseUrl}/removeCalendar`,
      { calendarId },
      {
        timeout: 10_000,
      }
    );
  } catch (e) {
    log.error(e);
    throw new Error('Failed to delete calendar');
  }
};
