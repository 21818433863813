import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, FormHelperText, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FieldWrapper from './FieldWrapper';
import useFieldProps from '../hooks/useFieldProps';

const FormInputFile = (props) => {
  const { defaultValue, fieldName, fieldWidth, formContext, formFieldOptions, placeholder } = props;
  const { inputAttributes } = useFieldProps(props);
  const { register } = formContext || useFormContext();
  const [uploadedFileName, setUploadedFileName] = useState(defaultValue || '');
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedFileName(file.name);
    }
  };

  return (
    <FieldWrapper
      {...props}
      showError
      controlProps={{ fullWidth: !!fieldWidth, variant: 'outlined' }}
    >
      <Box display="flex" alignItems="center" mt={1}>
        <Button variant="contained" component="label" startIcon={<CloudUploadIcon />}>
          Upload File
          <input
            defaultValue={defaultValue}
            type="file"
            hidden
            placeholder={placeholder}
            {...inputAttributes}
            {...register(fieldName || 'file', {
              ...formFieldOptions,
              onChange: (e) => {
                handleFileChange(e);
                formFieldOptions?.onChange?.(e);
              },
            })}
          />
        </Button>
        {uploadedFileName && (
          <Typography sx={{ pl: 2, overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {uploadedFileName}
          </Typography>
        )}
      </Box>
      <FormHelperText>{placeholder}</FormHelperText>
    </FieldWrapper>
  );
};

export default FormInputFile;
