import React from 'react';
export default function ConfirmText({ text }) {
  return (
    <div>
      {text &&
        text.split('\n').map((line, i, lines) => (
          <React.Fragment key={i}>
            <span
              style={{
                color: line.includes('Please remove duplicates and try again.') ? 'red' : 'inherit',
              }}
            >
              {line}
            </span>
            {i < lines.length - 1 && <br />}
          </React.Fragment>
        ))}
    </div>
  );
}
