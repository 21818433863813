import { Box, Button, Divider, Modal, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import Spinner from '../../../shared/spinner/components/Spinner';

const ApplyModal = ({
  applyCallback,
  applyModalButtonText,
  applyModalCancelButtonText,
  applyModalText,
  applyModalTitle,
  open,
  selectedRows,
  setIsInSelectionMode,
  setOpen,
  setSelectedRows,
}) => {
  const [processing, setProcessing] = useState(false);
  const handleOnClose = () => {
    setOpen(false);
    setSelectedRows([]);
    setIsInSelectionMode(false);
  };
  const handleConfirm = async () => {
    setProcessing(true);
    await applyCallback(selectedRows);
    handleOnClose();
    setProcessing(false);
  };

  return (
    <Modal open={open} onClose={handleOnClose} aria-labelledby="cancel-enrollment-title">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          width: '600px',
          borderRadius: 2,
          boxShadow: 24,
          p: 2,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography fontSize="16px" fontWeight="bold">
          {applyModalTitle}
        </Typography>
        <Divider sx={{ my: 2 }} />
        {processing ? (
          <Spinner />
        ) : (
          <Typography variant="body1" fontSize="14px" gutterBottom>
            {applyModalText}
          </Typography>
        )}
        <Box display="flex" justifyContent="flex-start" mt={3}>
          <Button
            disabled={processing}
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            sx={{ mr: 2 }}
          >
            {applyModalButtonText}
          </Button>
          <Button disabled={processing} variant="outlined" onClick={handleOnClose}>
            {applyModalCancelButtonText}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ApplyModal;
