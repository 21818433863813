import reduce from 'lodash/reduce';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import toString from 'lodash/toString';
import padStart from 'lodash/padStart';
const today = new Date();
const month = padStart(`${today.getMonth() + 1}`, 2, '0');
const day = padStart(`${today.getDate()}`, 2, '0');
export const defaultDate = `${today.getFullYear()}-${month}-${day}`;

const DateInput = ({ fieldKey, validation = {}, label, setInput, value, isDisabled }) => {
  const [currentValue, setCurrentValue] = useState(defaultDate);
  useEffect(() => void setCurrentValue(toString(value)), [value]);
  const inputProps = useMemo(
    () =>
      reduce(
        validation,
        (a, v, k) => ({ ...a, ...(k === 'min' && { min: v }), ...(k === 'max' && { max: v }) }),
        {}
      ),
    [validation]
  );
  const handleSetInputValue = useCallback(() => {
    setInput(fieldKey, currentValue || defaultDate);
  }, [setInput, fieldKey, currentValue, setCurrentValue]);
  const handleChange = useCallback(
    (e) => {
      setCurrentValue(toString(e.target.value || defaultDate));
      setInput(fieldKey, toString(currentValue || defaultDate));
    },
    [setInput, fieldKey]
  );
  return (
    <FormControl
      onBlur={handleSetInputValue}
      value={isDisabled ? '' : currentValue}
      type="date"
      disabled={isDisabled}
      onChange={handleChange}
      placeholder={label}
      required={true}
      {...inputProps}
    />
  );
};

export default memo(DateInput);
