export const tryParse = (obj, def, errorCallback) => {
  if (typeof obj === 'string') {
    try {
      obj = JSON.parse(obj);
    } catch (err) {
      if (errorCallback && typeof errorCallback === 'function') errorCallback(err);
    }
  }
  return obj || def;
};
