import api from '../api/itrsMailLogApi';

const columns = [
  {
    label: 'Date Sent',
    valueField: 'createdAt',
    sortable: true,
    initialSort: 'ASC',
  },
  {
    label: 'Mailkey',
    valueField: 'templateId',
    sortable: true,
  },
  {
    label: 'Language',
    valueField: 'language',
    sortable: true,
  },
  {
    label: 'To',
    valueField: 'to',
    sortable: true,
  },
  {
    label: 'From',
    valueField: 'from',
    sortable: true,
  },
  {
    label: 'Subject',
    valueField: 'subject',
    sortable: true,
  },
];

export default function useMailLogConfigurations() {
  return {
    columns,
    api,
  };
}
