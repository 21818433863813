import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { startCase, toNumber } from 'lodash';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TextField,
} from '@mui/material';
import FieldWrapper from '../../shared/form/components/FieldWrapper';

const MIN_VALUE = 0;
const MAX_VALUE = 10_000_000;

const validateField = (form, minFieldName, maxFieldName, isMin) => (value) => {
  if (value === '' || value === null || value === undefined) return true;
  const val = toNumber(value);
  if (Number.isNaN(val)) return 'Please enter a valid number.';
  if (val < MIN_VALUE) return `Minimum value is ${MIN_VALUE}`;
  if (val > MAX_VALUE) return `Maximum value is ${MAX_VALUE.toLocaleString()}`;

  const pairValue = form.getValues(isMin ? maxFieldName : minFieldName);
  if (pairValue !== '' && pairValue !== null && pairValue !== undefined) {
    const pairVal = toNumber(pairValue);
    if (!Number.isNaN(pairVal)) {
      if (isMin && val > pairVal) return 'Minimum cannot be greater than Maximum';
      if (!isMin && val < pairVal) return 'Maximum cannot be less than Minimum';
    }
  }

  return true;
};

const ControlledStyledInput = ({
  field,
  fieldState,
  placeholder,
  type,
  maxLength,
  onChange: providedOnChange,
  onBlur: providedOnBlur,
  inputProps: passedInputProps = {},
  fontWeight,
  variant = 'standard',
  sx = {},
  fieldWidth,
  fullWidth = false,
}) => {
  const [value, setValue] = useState(field.value ?? '');
  const hasError = !!fieldState.error;
  const errorMessage = fieldState.error?.message;

  const isNumeric = type === 'number' || type === 'integer';
  const inputMode = isNumeric ? 'numeric' : 'text';
  const pattern = isNumeric ? '[0-9]*' : undefined;

  const combinedInputProps = {
    ...passedInputProps,
    ...(maxLength && !Number.isNaN(maxLength) && { maxLength }),
    ...(isNumeric ? { inputMode, pattern } : {}),
    style: {
      textAlign: 'center',
      ...passedInputProps.style,
    },
  };

  const handleChange = (e) => {
    let v = e.target.value;
    if (maxLength && !Number.isNaN(maxLength)) {
      v = v.slice(0, maxLength);
    }
    setValue(v);
    field.onChange(v);
    if (providedOnChange) providedOnChange(e);
  };

  const handleBlur = (e) => {
    field.onBlur();
    if (providedOnBlur) providedOnBlur(e);
  };

  return (
    <FieldWrapper controlProps fieldWidth={fieldWidth} fullWidth={fullWidth}>
      <TextField
        label="" // no label text
        InputLabelProps={{
          sx: { display: 'none' }, // hide the label element entirely
        }}
        fontWeight={fontWeight}
        value={value}
        placeholder={placeholder}
        type={type === 'integer' ? 'number' : type || 'text'}
        step={type === 'number' ? 'any' : '1'}
        error={hasError}
        helperText={hasError ? errorMessage : ' '} // always reserve space
        variant={variant}
        size="small"
        onChange={handleChange}
        onBlur={handleBlur}
        inputProps={combinedInputProps}
        FormHelperTextProps={{
          sx: {
            margin: 0,
            padding: 0,
            lineHeight: '1em',
            fontSize: '0.75rem',
            minHeight: '0px',
            height: '1em', // fixed line height
          },
        }}
        sx={{
          ...sx,
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
          '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
            {
              background: 'transparent',
              border: 'none',
              opacity: 1,
              display: 'block',
            },
          '& .MuiFormControl-root, .MuiFormControl-marginNormal, .MuiFormControl-marginDense': {
            margin: 0,
            padding: 0,
          },
        }}
      />
    </FieldWrapper>
  );
};

const Row = ({ name, form, index }) => {
  const min = `${name}Min`;
  const max = `${name}Max`;
  return (
    <TableRow
      sx={{
        backgroundColor: index % 2 === 1 ? 'inherit' : '#EEEEEE',
        padding: 0,
      }}
    >
      <TableCell padding="none">
        <Typography sx={{ marginLeft: '10px' }} variant="body1">
          {startCase(name)}
        </Typography>
      </TableCell>

      <TableCell padding="none" align="center" sx={{ verticalAlign: 'middle' }}>
        <Controller
          name={min}
          control={form.control}
          defaultValue={0}
          rules={{
            validate: validateField(form, min, max, true),
            setValueAs: (val) => {
              if (val === '' || val === null || val === undefined) return val;
              const numVal = Number(val);
              return Number.isNaN(numVal) ? val : numVal;
            },
          }}
          render={({ field, fieldState }) => (
            <ControlledStyledInput
              field={field}
              fieldState={fieldState}
              type="number"
              variant="standard"
              fontWeight={undefined}
              fieldWidth="short"
              fullWidth={false}
              sx={{ maxWidth: '230px' }}
              inputProps={{
                min: '0',
                max: '10000000',
              }}
              onChange={() => {
                form.trigger([min, max]);
              }}
              onBlur={() => {
                form.trigger([min, max]);
              }}
            />
          )}
        />
      </TableCell>

      <TableCell padding="none" align="center" sx={{ verticalAlign: 'middle' }}>
        <Controller
          name={max}
          control={form.control}
          defaultValue={0}
          rules={{
            validate: validateField(form, min, max, false),
            setValueAs: (val) => {
              if (val === '' || val === null || val === undefined) return val;
              const numVal = Number(val);
              return Number.isNaN(numVal) ? val : numVal;
            },
          }}
          render={({ field, fieldState }) => (
            <ControlledStyledInput
              field={field}
              fieldState={fieldState}
              type="number"
              variant="standard"
              fontWeight={undefined}
              fieldWidth="short"
              fullWidth={false}
              sx={{ maxWidth: '230px' }}
              inputProps={{
                min: '0',
                max: '10000000',
              }}
              onChange={() => {
                form.trigger([min, max]);
              }}
              onBlur={() => {
                form.trigger([min, max]);
              }}
            />
          )}
        />
      </TableCell>
    </TableRow>
  );
};

const AttendeeDetailForm = ({ form }) => {
  const names = ['student', 'instructor', 'assistant'];

  return (
    <>
      <div
        style={{
          width: '100%',
          padding: 10,
          fontWeight: 'bold',
          backgroundColor: '#1B76D2',
          color: 'white',
          marginBottom: 20,
        }}
      >
        <p style={{ marginBottom: 0, marginLeft: 10 }}>Attendee Details</p>
      </div>
      <Table sx={{ marginBottom: 3 }}>
        <TableHead>
          <TableRow>
            <TableCell padding="none" sx={{ borderBottom: 'none' }}>
              <Typography sx={{ fontWeight: 'bold', paddingLeft: 1 }}>Attendee Type</Typography>
            </TableCell>
            <TableCell padding="none" sx={{ borderBottom: 'none', textAlign: 'center' }}>
              <Typography sx={{ fontWeight: 'bold' }}>Minimum Seats</Typography>
            </TableCell>
            <TableCell padding="none" sx={{ borderBottom: 'none', textAlign: 'center' }}>
              <Typography sx={{ fontWeight: 'bold' }}>Maximum Seats</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{ padding: 0, height: 20, borderBottom: '2px solid #747474' }}
              colSpan={3}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {names.map((name, index) => (
            <Row key={name} name={name} form={form} index={index} />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default AttendeeDetailForm;
