export function finalizeWysiwygContent(content) {
  const PBF = `<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>`;
  if (content.includes(PBF)) {
    //remove all PBF strings from content
    content = content.replaceAll(PBF, '');
  }
  content = content.replaceAll('%3A', ':');
  const el = $(content).clone();
  if (!el.hasClass('wysiwyg-page')) {
    let overflowScrollAttr = '';
    if (content.includes('<embed-video')) {
      overflowScrollAttr = ' overflow-scroll="true"';
    }
    return (
      '<div class="wysiwyg-page"><ion-content class="has-subheader main-content has-header"' +
      overflowScrollAttr +
      '><div class="reset-this"><div class="wysiwyg_pageContainer has-dynamic-header wysiwyg-content fr-view" style="width:100%;">' +
      content +
      '</ion-content></div></div></div>'
    );
  }
  return content;
}

export function finalizeFormWysiwygContent(content) {
  content = content.replaceAll('%3A', ':');
  return content;
}
