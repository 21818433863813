import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

const Detail = ({ label, value }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Box>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
        {`${label}:`}
      </Typography>
    </Box>
    <Box sx={{ pl: '1rem' }}>
      <Typography variant="body1" gutterBottom>
        {value}
      </Typography>
    </Box>
  </Box>
);

export default Detail;
