import React, { useCallback } from 'react';
import { Box, Button } from '@mui/material';
import { cmsRegisterAnotherUserForSession } from '../../api/sessionApi';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../store/rootReducer';
const ModalFooter = ({ onCancel, userType, sessionId, courseId, selectedUser, invalidate }) => {
  const dispatch = useDispatch();

  const handleEnroll = useCallback(async () => {
    await cmsRegisterAnotherUserForSession(courseId, sessionId, selectedUser.id, userType);
    dispatch(
      showSnackbar({ message: 'User enrolled successfully', duration: 3000, severity: 'success' })
    );
    await onCancel();
    await invalidate();
  }, [userType, sessionId, courseId, selectedUser, onCancel, dispatch, invalidate]);
  return (
    <Box sx={{ px: 3, py: 2, display: 'flex', justifyContent: 'flex-start', gap: 2 }}>
      <Button
        variant="contained"
        onClick={handleEnroll}
        disabled={!selectedUser || selectedUser.approvedPending}
      >
        Enroll User
      </Button>
      <Button onClick={onCancel}>Cancel</Button>
    </Box>
  );
};

export default ModalFooter;
