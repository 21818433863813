import React, { useState } from 'react';
import TravelTab from './TravelTab';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { BackToDashboard } from '../BackButtons';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const ItrsTravelRequestsPage = () => {
  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div className="App">
      <Box sx={{ borderBottom: 1, borderColor: 'divider', m: 'auto' }}>
        <Box sx={{ display: 'flex', width: '100%', mb: '1rem' }}>
          <Box sx={{ pl: '1.5rem' }}>
            <BackToDashboard />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
        </Box>

        <Container maxWidth="sm" sx={{ marginLeft: '0' }}>
          <Tabs value={tab} onChange={handleChange} aria-label="">
            <Tab label="Travel To Book" />
            <Tab label="Completed" />
          </Tabs>
        </Container>
      </Box>
      <TabPanel value={tab} index={0}>
        <TravelTab apiParams={{ complete: false }} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <TravelTab apiParams={{ complete: true }} />
      </TabPanel>
    </div>
  );
};

export default ItrsTravelRequestsPage;
