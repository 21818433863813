import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import omit from 'lodash/omit';

export default function useFieldProps(props, shouldWatch = false) {
  const { fieldName, formContext, formFieldOptions, fieldWidth, value: providedValue } = props;

  const inputAttributes = useMemo(
    () =>
      omit(props, [
        'className',
        'cols',
        'defaultValue',
        'fieldName',
        'fieldWidth',
        'filterFunction',
        'findValueInOptions',
        'fontWeight',
        'formContext',
        'formFieldOptions',
        'isDateOnly',
        'isSingleSelect',
        'label',
        'labelKey',
        'maxLength',
        'onBlur',
        'onChange',
        'options',
        'optionsLoader',
        'placeholder',
        'readOnly',
        'selectChange',
        'selectOptions',
        'sort',
        'sortTagsKey',
        'type',
        'valueKey',
        'variant',
      ]),
    [props]
  );

  const form = formContext || useFormContext();
  const errors = form?.formState?.errors || {};
  const value = shouldWatch ? form?.watch?.(fieldName) ?? providedValue : providedValue;

  const fieldError = errors?.[fieldName];

  const hasError = useMemo(() => !!fieldError, [fieldError]);
  const errorMessage = fieldError
    ? fieldError?.types
      ? Object.values(fieldError.types).join(' ')
      : fieldError.message || ''
    : '';

  const width = useMemo(() => {
    switch (fieldWidth) {
      case 'tiny': {
        return '8em';
      }
      case 'short': {
        return '16em';
      }
      case 'normal': {
        return '32em';
      }
      case 'long':
      case 'full': {
        return '100%';
      }
      default: {
        if (typeof fieldWidth === 'number') {
          return `${fieldWidth}px`; // Treat a number as a pixel value
        }
        if (![null, undefined, ''].includes(fieldWidth)) {
          return fieldWidth;
        }
      }
    }
    return '32em';
  }, [fieldWidth]);
  const isRequired = useMemo(() => !!formFieldOptions?.required, [formFieldOptions]);

  return { width, inputAttributes, isRequired, value, fieldError, hasError, errorMessage };
}
