export const wysiwygConfigDefaults = {
  attribution: false,
  iconTemplate: 'font_awesome',
  imageUploadParams: {
    data: {
      asset: {
        type: 'file',
        group: 'pluginData/wysiwyg',
        id: 'assetId',
      },
    },
  },
  toolbarSticky: false,
  imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif', 'svg+xml', 'svg', 'webp'],
  imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
  imageUploadParam: 'assetData',
  imageUploadURL: '/content-api/uploadAsset',
  imageManagerLoadURL: '/content-api/getAllImages',
  imageManagerDeleteURL: '/content-api/removeOverride',
  imageManagerLoadParams: {
    group: 'pluginData/wysiwyg',
  },
  videoInsertButtons: ['videoBack', '|', 'videoUpload', 'videoEmbed'],
  videoUploadParams: {
    data: {
      asset: {
        type: 'file',
        group: 'pluginData/wysiwyg',
        id: 'assetId',
      },
    },
  },
  videoUploadParam: 'assetData',
  videoUploadURL: '/content-api/uploadAsset',
  inlineStyles: {
    'Big Red': 'font-size: 20px;color: red;',
    'Small Blue': 'font-size: 14px; color: blue;',
  },
  linkAlwaysBlank: true,
  linkInsertButtons: ['linkBack', '|', 'internalLink'],
  linkEditButtons: ['linkStyle', 'linkEdit', 'linkRemove'],
  linkAutoPrefix: '',
  tableColors: [
    '#61BD6D',
    '#1ABC9C',
    '#54ACD2',
    '#2C82C9',
    '#9365B8',
    '#475577',
    '#CCCCCC',
    '#41A85F',
    '#00A885',
    '#3D8EB9',
    '#2969B0',
    '#553982',
    '#28324E',
    '#000000',
    '#F7DA64',
    '#FBA026',
    '#EB6B56',
    '#E25041',
    '#A38F84',
    '#EFEFEF',
    '#FFFFFF',
    '#FAC51C',
    '#F37934',
    '#D14841',
    '#B8312F',
    '#7C706B',
    '#D1D5D8',
    'REMOVE',
  ],
  colorsBackground: [
    '#61BD6D',
    '#1ABC9C',
    '#54ACD2',
    '#2C82C9',
    '#9365B8',
    '#475577',
    '#CCCCCC',
    '#41A85F',
    '#00A885',
    '#3D8EB9',
    '#2969B0',
    '#553982',
    '#28324E',
    '#000000',
    '#F7DA64',
    '#FBA026',
    '#EB6B56',
    '#E25041',
    '#A38F84',
    '#EFEFEF',
    '#FFFFFF',
    '#FAC51C',
    '#F37934',
    '#D14841',
    '#B8312F',
    '#7C706B',
    '#D1D5D8',
    'REMOVE',
  ],
  colorsText: [
    '#C5B783',
    '#61BD6D',
    '#1ABC9C',
    '#54ACD2',
    '#2C82C9',
    '#9365B8',
    '#475577',
    '#CCCCCC',
    '#41A85F',
    '#00A885',
    '#3D8EB9',
    '#2969B0',
    '#553982',
    '#28324E',
    '#000000',
    '#F7DA64',
    '#FBA026',
    '#EB6B56',
    '#E25041',
    '#A38F84',
    '#EFEFEF',
    '#FFFFFF',
    '#FAC51C',
    '#F37934',
    '#D14841',
    '#B8312F',
    '#7C706B',
    '#D1D5D8',
    'REMOVE',
  ],
  toolbarButtons: [
    [
      'fullscreen',
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    'subscript',
    'superscript',
    'fontFamily',
    'fontSize',
  ],

    [
      'backgroundColor',
    'textColor',
    'emoticons',
    'inlineStyle',
    'paragraphStyle',
  ],

    [
      'paragraphFormat',
    'align',
    'formatOL',
    'formatUL',
    'outdent',
    'indent',
    'quote',
    'insertHR',
    ],
    [
    'insertLink',
    'insertImage',
    'insertVideo',
    'insertTable',
    'undo',
    'redo',
    'clearFormatting',
    'selectAll',
    'html',]
  ],
  toolbarButtonsMD: [
   [ 
    'fullscreen',
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    'subscript',
    'superscript',
    'fontFamily',
    'fontSize'
  ],

    [
      'backgroundColor',
    'textColor',
    'emoticons',
    'inlineStyle',
    'paragraphStyle',
  ],

    [
      'paragraphFormat',
    'align',
    'formatOL',
    'formatUL',
    'outdent',
    'indent',
    'quote',
    'insertHR',
    ],
    [
    'insertLink',
    'insertImage',
    'insertVideo',
    'insertTable',
    'undo',
    'redo',
    'clearFormatting',
    'selectAll',
    'html',
  ]
  ],
  toolbarButtonsSM: [
    'fullscreen',
    'bold',
    'italic',
    'underline',
    'fontFamily',
    'fontSize',
    'insertLink',
    'insertImage',
    'insertVideo',
    'insertTable',
    'undo',
    'redo',
  ],
  toolbarButtonsXS: ['bold', 'italic', 'fontFamily', 'fontSize', 'undo', 'redo'],
  fontFamily: {
    'Arial,Helvetica,sans-serif': 'Arial',
    'Georgia,serif': 'Georgia',
    'Impact,Charcoal,sans-serif': 'Impact',
    'Tahoma,Geneva,sans-serif': 'Tahoma',
    "'Times New Roman',Times,serif": 'Times New Roman',
    'Verdana,Geneva,sans-serif': 'Verdana',
    'DINOT-BLACK': 'Dinot Black',
    'DINOT-BLACK-ITALIC': 'Dinot Black Italic',
    'DINOT-BOLD': 'Dinot Bold',
    'DINOT-BOLD-ITALIC': 'Dinot Bold Italic',
    'DINOT-COND-BLACK': 'Dinot Cond Black',
    'DINOT-COND-BOLD': 'Dinot Cond Bold',
    'DINOT-COND-LIGHT': 'Dinot Cond Light',
    'DINOT-COND-MEDIUM': 'Dinot Cond Medium',
    'DINOT-COND-REGULAR': 'Dinot Cond Regular',
    'DINOT-LIGHT': 'Dinot Light',
    'DINOT-LIGHT-ITALIC': 'Dinot Light Italic',
    'DINOT-MEDIUM': 'Dinot Medium',
    'DINOT-MEDIUM-ITALIC': 'Dinot Medium Italic',
    'DINOT-REGULAR': 'Dinot Regular',
    'DINOT-MITTEL': 'Dinot Mittel',
  },
  fontFamilySelection: true,
  fontSizeSelection: false,
  zIndex: '1000',
  imageResizeWithPercent: 'true',
  imageDefaultWidth: '25%',
  videoDefaultWidth: '25%',
  htmlAllowedAttrs: [
    'accept',
    'accept-charset',
    'accesskey',
    'action',
    'align',
    'alt',
    'async',
    'autocomplete',
    'autofocus',
    'autoplay',
    'autosave',
    'background',
    'bgcolor',
    'border',
    'charset',
    'cellpadding',
    'cellspacing',
    'checked',
    'cite',
    'class',
    'color',
    'cols',
    'colspan',
    'content',
    'contenteditable',
    'contextmenu',
    'controls',
    'coords',
    'data',
    'data-.*',
    'datetime',
    'default',
    'defer',
    'dir',
    'dirname',
    'disabled',
    'download',
    'draggable',
    'dropzone',
    'enctype',
    'for',
    'form',
    'formaction',
    'headers',
    'height',
    'hidden',
    'high',
    'href',
    'hreflang',
    'http-equiv',
    'icon',
    'id',
    'ismap',
    'itemprop',
    'keytype',
    'kind',
    'label',
    'lang',
    'language',
    'list',
    'loop',
    'low',
    'max',
    'maxlength',
    'media',
    'method',
    'min',
    'multiple',
    'name',
    'novalidate',
    'open',
    'optimum',
    'pattern',
    'ping',
    'placeholder',
    'poster',
    'preload',
    'pubdate',
    'radiogroup',
    'readonly',
    'rel',
    'required',
    'reversed',
    'rows',
    'rowspan',
    'sandbox',
    'scope',
    'scoped',
    'scrolling',
    'seamless',
    'selected',
    'shape',
    'size',
    'sizes',
    'span',
    'src',
    'srcdoc',
    'srclang',
    'srcset',
    'start',
    'step',
    'summary',
    'spellcheck',
    'style',
    'tabindex',
    'target',
    'title',
    'type',
    'translate',
    'usemap',
    'value',
    'valign',
    'width',
    'wrap',
    'ng-.*',
    'scroll-to-option-value',
    'animate-show',
    'scroll-to-anchor',
    'cms-style',
    'compile',
    'scrub-ion-content',
    'ion-radio-fix',
    'menu-list',
    'ua-background-image',
    'wysiwyg-content',
    'ng-src',
    'ng-href',
    'frameborder',
    'allowfullscreen',
    'vid',
    'list',
    'group-id',
    'group-key',
    'add-label',
    'remove-label',
    'additional-class',
  ],
  htmlAllowedTags: [
    'a',
    'abbr',
    'address',
    'area',
    'article',
    'aside',
    'audio',
    'b',
    'base',
    'bdi',
    'bdo',
    'blockquote',
    'br',
    'button',
    'canvas',
    'caption',
    'cite',
    'code',
    'col',
    'colgroup',
    'datalist',
    'dd',
    'del',
    'details',
    'dfn',
    'dialog',
    'div',
    'dl',
    'dt',
    'em',
    'embed',
    'embed-video',
    'fieldset',
    'figcaption',
    'figure',
    'footer',
    'form',
    'group-button',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'header',
    'hgroup',
    'hr',
    'i',
    'iframe',
    'img',
    'input',
    'ins',
    'kbd',
    'keygen',
    'label',
    'legend',
    'li',
    'link',
    'main',
    'map',
    'mark',
    'menu',
    'menuitem',
    'meter',
    'nav',
    'noscript',
    'object',
    'ol',
    'optgroup',
    'option',
    'output',
    'p',
    'param',
    'pre',
    'progress',
    'queue',
    'rp',
    'rt',
    'ruby',
    's',
    'samp',
    'script',
    'style',
    'section',
    'select',
    'small',
    'source',
    'span',
    'strike',
    'strong',
    'sub',
    'summary',
    'sup',
    'table',
    'tbody',
    'td',
    'textarea',
    'tfoot',
    'th',
    'thead',
    'time',
    'title',
    'tr',
    'track',
    'u',
    'ul',
    'var',
    'video',
    'wbr',
  ],
  htmlAllowedEmptyTags: [
    'textarea',
    'a',
    'embed-video',
    'iframe',
    'object',
    'video',
    'style',
    'script',
    '.fa',
  ],
  quickInsertButtons: ['ul', 'ol', 'hr'],
};
