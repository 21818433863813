import { Box } from '@mui/material';
import React from 'react';
import ActionButton from '../../shared/actionButton/components/ActionButton';
import FormInput from '../../shared/form/components/FormInput';
import FormInputTextArea from '../../shared/form/components/FormInputTextArea';
import MultiSelect from '../../shared/form/components/MultiSelect';
import api from '../api/itrsApi';
import useRooms from '../hooks/useRooms';
const ResourceRequirementForm = ({ form }) => {
  const { addRow, PreRenderedTable } = useRooms(form);
  return (
    <>
      <div
        style={{
          width: '100%',
          padding: 10,
          fontWeight: 'bold',
          backgroundColor: '#1B76D2',
          color: 'white',
          marginBottom: 20,
        }}
      >
        <p style={{ marginBottom: 0, marginLeft: 10 }}>Resource Requirements</p>
      </div>

      <div style={{ marginBottom: 20 }}>
        <FormInput
          label="On-Site Contact"
          fieldName="contact"
          defaultValue=""
          formContext={form}
          fieldWidth={500}
        />
      </div>

      <div
        style={{
          width: '100%',
          height: 1,
          borderTop: '1px solid #D6D6D680',
          paddingTop: 20,
        }}
      ></div>

      <Box sx={{ display: 'flex', flexWrap: 'wrap', flexGrow: 1 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexGrow: 1,
            flexBasis: '100%',
            my: 1,
          }}
        >
          <Box style={{ fontWeight: 'bold' }}> Room Reservations</Box>
          <Box>
            <ActionButton type="button" label={`Add Reservation`} onClick={addRow} />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexGrow: 1,
            flexBasis: '100%',
          }}
        >
          <Box sx={{ width: '100%' }}>{PreRenderedTable}</Box>
        </Box>
      </Box>

      <div
        style={{
          width: '100%',
          height: 1,
          borderBottom: '1px solid #D6D6D680',
          paddingTop: 20,
        }}
      ></div>

      <div style={{ marginTop: 10 }}>
        <MultiSelect
          defaultValue="none"
          fieldName="equipment"
          findValueInOptions={true}
          formContext={form}
          formFieldOptions={{ required: 'Equipment is required' }}
          isSingleSelect={true}
          label="Classroom Equipment"
          labelColor="#949494"
          labelKey="label"
          optionsLoader={api.getEquipment}
          placeholder={'Select Equipment'}
          sort
          valueKey={'value'}
          fieldWidth={500}
          fontWeight={500}
        />
      </div>

      <FormInputTextArea
        label="Additional Needs"
        fieldName="addNeeds"
        formContext={form}
        fieldWidth={500}
        labelWeight={500}
        labelColor="#949494"
      />
      <FormInputTextArea
        label="Instructor Needs"
        fieldName="instrNeeds"
        formContext={form}
        fieldWidth={500}
        labelWeight={500}
        labelColor="#949494"
      />
    </>
  );
};

export default ResourceRequirementForm;
