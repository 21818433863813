import { IconButton, TableCell } from '@mui/material';
import React, { useCallback, useContext } from 'react';
import { useTableEditorContext } from './TableContext';
import { CmsContext } from '../../context/definitions';
export default function TableCellRemove({ rowIdx, confirm: confirmProps, disabled }) {
  const { removeRow } = useTableEditorContext();
  const { confirm } = useContext(CmsContext);
  const handleClick = useCallback(async () => {
    if (confirmProps) {
      const confirmation = await confirm(confirmProps);
      if (confirmation) removeRow(rowIdx);
    } else {
      removeRow(rowIdx);
    }
  }, [confirm, rowIdx, removeRow, confirmProps]);
  return (
    <TableCell align="right">
      <IconButton onClick={handleClick} aria-label="delete" disabled={disabled}>
        <div
          style={{ color: disabled ? 'grey' : 'black', fontSize: '.875rem' }}
          className="fa-solid fa-trash"
        />
      </IconButton>
    </TableCell>
  );
}
