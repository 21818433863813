import React from 'react';
import { Card, CardContent, Grid, Typography, Box } from '@mui/material';

const ItrsDashboardItem = ({ title, description, Icon, onClick }) => {
  return (
    <Card
      sx={{ display: 'flex', mb: '2rem', cursor: 'pointer', borderRadius: '10px' }}
      elevation={3}
      onClick={onClick}
    >
      <Grid container>
        <Grid item xs={9}>
          <CardContent>
            <Typography
              style={{ fontWeight: 'bold', fontSize: '1.4rem', mb: '.25rem' }}
              component="div"
            >
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          </CardContent>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#E3F2FD',
          }}
        >
          <Box>
            <Icon sx={{ fontSize: '45px', '& .MuiSvgIcon-root': { fontSize: '45px' } }} />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ItrsDashboardItem;
