import { Box, Typography } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import log from '../../logger';
import useKmlTableOptions from './GoogleMapComponent/hooks/useKmlTableOptions';
import { navigateToPluginPage } from '../../store/rootReducer';
import { useDispatch } from 'react-redux';
const MapsTable = ({ setState, state, stateKey, title, hideDelete, info, mapId }) => {
  const [item, setItem] = useState(undefined);
  const options = useKmlTableOptions();
  const dispatch = useDispatch();
  async function handleEdit(idx) {
    try {
      const itm = state[stateKey][idx];
      const resp = info?.find((i) => `${i.id}` === item?.name);

      setItem(resp || itm);
      dispatch(
        navigateToPluginPage(['new-maps', 'mapsKmlEditor', resp?.id || itm?.name, mapId, stateKey])
      );
    } catch (err) {
      log.error('Error getting item', err);
    }
  }
  async function handleDelete(idx) {
    setState((st) => ({
      ...st,
      [stateKey]: st[stateKey].filter((_, index) => index !== idx),
    }));
  }
  const columns = [
    { name: 'name', label: 'Name', options: { filter: true, sort: true } },
    { name: 'displayName', label: 'Display', options: { filter: true, sort: true } },
    {
      label: '',
      name: '',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (idx) => (
          <Box style={{ textAlign: 'right' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => handleEdit(idx)}
              >
                Edit
              </Button>
              <Box sx={{ width: '.5rem' }} />
              {!hideDelete && (
                <Button
                  variant="contained"
                  color="danger"
                  size="small"
                  onClick={() => handleDelete(idx)}
                >
                  Delete
                </Button>
              )}
            </Box>
          </Box>
        ),
      },
    },
  ];
  return (
    <>
      <Box
        sx={{
          mt: '1rem',
          '& .MuiTablePagination-selectLabel': { mb: 0 },
          '& .MuiTablePagination-displayedRows': { mb: 0 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexShrink: 1,
            alignItems: 'center',
          }}
        >
          <Box sx={{ mr: '.5rem', display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{`${title}s`}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
              {state?.[stateKey]?.length || 'None'}
            </Typography>
          </Box>
        </Box>
        {!!state?.[stateKey]?.length && (
          <MUIDataTable
            title={`${title}s`}
            data={state[stateKey]}
            columns={columns}
            options={options}
          />
        )}
      </Box>
    </>
  );
};

export default MapsTable;
