import React from 'react';
import { Controller } from 'react-hook-form';
import FormRadioButton from './FormRadioButton';

const RadioButtonGroup = ({
  form,
  label,
  name,
  options,
  vertical = true,
  labelWeight = 500,
  labelColor = '#000',
  formFieldOptions,
  defaultValue,
}) => {
  const {
    formState: { errors },
    control,
  } = form;

  const error = errors?.[name];

  return (
    <div
      style={{
        display: 'flex',
        padding: 0,
        ...(vertical
          ? { flexDirection: 'column', alignItems: 'flex-start' }
          : { flexDirection: 'row', justifyContent: 'flex-start' }),
      }}
    >
      <label
        style={{ fontWeight: labelWeight, marginBottom: 10, color: labelColor }}
        className={error ? 'error' : ''}
      >{`${label}${formFieldOptions ? ' *' : ''}`}</label>

      <Controller
        name={name}
        control={control}
        rules={formFieldOptions}
        defaultValue={defaultValue}
        render={({ field }) => (
          <>
            {options.map(({ key, label: optionLabel, value, id, disabled = false }) => (
              <FormRadioButton
                key={key || value}
                id={id || value}
                label={optionLabel}
                value={value}
                disabled={disabled}
                checked={field.value === value}
                onChange={() => field.onChange(value)}
                onBlur={field.onBlur}
                name={field.name}
                labelStyle={{ fontWeight: '100' }}
              />
            ))}
          </>
        )}
      />
      {error && (
        <p style={{ color: '#dc3545', fontSize: '.875em', marginTop: '.25rem' }}>{error.message}</p>
      )}
    </div>
  );
};

export default RadioButtonGroup;
