import { FormControl, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useFieldProps from '../hooks/useFieldProps';
import FieldWrapper from './FieldWrapper';

const FormInputInline = (props) => {
  const {
    label,
    fieldName,
    formFieldOptions,
    formContext,
    variant,
    labelWeight,
    type: providedType,
    className: providedClassName,
  } = props;
  const {
    errorMessage,
    isRequired,
    hasError,
    value: watchValue,
    width,
    inputAttributes,
  } = useFieldProps(props, true);
  const { register } = formContext;
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(watchValue);
  }, [watchValue]);

  return (
    <FieldWrapper {...props} showError>
      <div style={{ display: 'flex', alignItems: 'center', width }}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div style={{ display: 'inline-block', whiteSpace: 'nowrap', marginRight: '10px' }}>
            <span style={{ fontWeight: labelWeight }}>
              {label}
              {isRequired && <span>*</span>}
            </span>
          </div>
          <FormControl className={providedClassName} error={hasError} style={{ width: '100%' }}>
            <TextField
              id={fieldName}
              type={providedType || 'text'}
              defaultValue={value}
              error={hasError}
              {...inputAttributes}
              {...register(fieldName, formFieldOptions)}
              inputProps={{ maxLength: 255 }}
              variant={variant || 'outlined'}
              helperText={errorMessage}
              required={isRequired}
            />
          </FormControl>
        </div>
      </div>
    </FieldWrapper>
  );
};

export default FormInputInline;
