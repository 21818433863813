const mailformat = /^[\w%+.-]+('[\w%+.-]+)*@[\d.A-Za-z-]+\.[A-Za-z]{2,}$/;

export function validateEmail(email) {
  return mailformat.test(email);
}

export function validateEmails(emails = []) {
  let isValid = true;
  for (const email of emails) {
    if (!validateEmail(email)) {
      isValid = false;
      break;
    }
  }
  return isValid;
}
