import { read, utils } from 'xlsx';
import log from '../../../../logger';
export const blobToCsv = (blob, fileName) => {
  let csvFile;
  try {
    csvFile = new File([blob], fileName, { type: 'text/csv' });
  } catch (err) {
    log.error(err);
    csvFile = blob;
  }
  return csvFile;
};

export const readXlsx = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const csvData = utils.sheet_to_csv(workbook.Sheets[sheetName]);
      resolve(new Blob([csvData], { type: 'text/csv' }));
    };
    reader.readAsBinaryString(file);
  });
