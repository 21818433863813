import HelpIcon from '@mui/icons-material/Help';
import InfoOutlineIcon from '@mui/icons-material/Info';
import { Checkbox, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FieldWrapper from './FieldWrapper';
const color = 'rgba(0, 0, 0, 0.87)';
const FormCheckBox = (props) => {
  const {
    fieldName,
    formFieldOptions,
    formContext,
    forceCheck,
    preChecked,
    infoTooltip,
    label,
    tooltipType = 'info',
    ...inputAttributes
  } = props;

  const { register, setValue } = formContext;
  const [checked, setChecked] = useState(!!preChecked);
  const registered = register(fieldName, formFieldOptions);
  if (forceCheck) setValue(fieldName, true);

  useEffect(() => {
    if (preChecked) setChecked(preChecked);
  }, [preChecked]);

  return (
    <FieldWrapper
      {...props}
      cn={[`form-checkbox-row${forceCheck ? ' form-checkbox-row-disabled' : ''}`]}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={forceCheck || checked}
            {...inputAttributes}
            {...registered}
            onChange={() => {
              if (forceCheck) return;
              setChecked(!checked);
              setValue(fieldName, !checked, { shouldDirty: true });
            }}
            disabled={forceCheck}
          />
        }
        label={label}
      />

      {/* NOT SURE IF THIS IS NEEDED */}
      {/* <label className="form-checkbox-row-label" htmlFor={inputAttributes.id}>
        {label}
      </label> */}

      {infoTooltip && (
        <Tooltip
          title={infoTooltip}
          arrow
          placement="top"
          componentsProps={{
            tooltip: { sx: { fontSize: '16px', bgcolor: color, '& .MuiTooltip-arrow': { color } } },
          }}
        >
          <IconButton size="small" color="primary">
            {tooltipType === 'question' ? <HelpIcon /> : <InfoOutlineIcon />}
          </IconButton>
        </Tooltip>
      )}
    </FieldWrapper>
  );
};

export default FormCheckBox;
