import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import padStart from 'lodash/padStart';
import set from 'lodash/set';
import sortBy from 'lodash/sortBy';
import update from 'lodash/update';
import omit from 'lodash/omit';

const padD = (num) => padStart(`${num}`, 2, '0');
export const setRuleDefaults = (rule, rootOptions) => {
  if (rule) {
    let r = parseRule(rule, rootOptions);
    const gStr = (str) => (str ? `.${str}` : str);
    const sr = (str, val) => set(rule, `data[0]${gStr(str)}`, val);
    const ur = (str, cb) => update(rule, `data[0]${gStr(str)}`, cb);
    if (!r.optionId && r.hasQueriedPrimaryOptions && !isEmpty(r.primaryOptionsMap)) {
      const sampleOpt = first(sortBy(Object.values(r.primaryOptionsMap), 'label')) || {};
      const { id, queryParams = {}, ...restOpts } = sampleOpt;
      if (id) {
        ur('', (c = {}) => ({ ...c, id, ...omitBy(restOpts, isUndefined) }));
        ur(`logicParams`, (c = {}) => ({ ...c, ...queryParams }));
      }
    }
    r = parseRule(rule, rootOptions);
    if (
      r.hasSubOptions &&
      r.optionId &&
      !r.subOptionId &&
      r.hasQueriedSubOptions &&
      !isEmpty(r.subOptionsMap)
    ) {
      const sampleOpt = first(sortBy(Object.values(r.subOptionsMap), 'label')) || {};
      const { id, queryParams = {}, ...restOpts } = sampleOpt;
      if (id) {
        ur('data[0]', (c = {}) => ({ ...c, id, ...omitBy(restOpts, isUndefined) }));
        ur(`data[0].logicParams`, (c = {}) => ({ ...c, ...queryParams }));
      }
    }
    r = parseRule(rule, rootOptions);
    if (!r.hasSetDefaultValue && !r.fieldLogicType) {
      const defaultField = r.fields.find((f) => f.isDefault);
      if (defaultField) {
        sr('hasSetDefaultValue', true);
        sr('logicParams.fieldLogicType', defaultField.value);
      }
    }
    r = parseRule(rule, rootOptions);
    if (r.condition === undefined) {
      const defaultCondition = r.conditions.find((c) => c.isDefault);
      if (defaultCondition) {
        set(rule, 'condition', defaultCondition.value);
        sr('logicParams.condition', defaultCondition.value);
      }
    }
    r = parseRule(rule, rootOptions);
    r.additionalFields.forEach((field) => {
      const { fieldKey, defaultValue, type } = field || {};
      const currentValue = get(rule, `data[0].logicParams.${fieldKey}`);
      if (!currentValue) {
        let setDefault;
        if (type === 'date') {
          const d = new Date();
          setDefault = `${d.getFullYear()}-${padD(d.getMonth() + 1)}-${padD(d.getDate())}`;
        } else if (has(field, 'defaultValue')) setDefault = defaultValue;
        else if (type === 'number') setDefault = '0';
        if (setDefault) sr(`logicParams.${fieldKey}`, setDefault);
      }
    });
  }
  return rule;
};

const setRecursiveDefaults = (logicParam, rootOptions, updateLocation, currentLocation) => {
  if (currentLocation) currentLocation += '.';
  currentLocation += `rules`;
  return {
    ...logicParam,
    rules: get(logicParam, 'rules', []).map((rule, idx) => {
      let thisLocation = `${currentLocation}[${idx}]`;
      if (has(rule, 'logic')) {
        thisLocation += '.logic';
        setRecursiveDefaults(rule.logic, rootOptions, updateLocation, thisLocation);
      } else if (!updateLocation || thisLocation === updateLocation) {
        rule = setRuleDefaults(rule, rootOptions, updateLocation, thisLocation);
      }
      return rule;
    }),
  };
};

export const updateLogic = (newLogic, rootOptions, updateLocation) => {
  const currentLocation = '';
  return setRecursiveDefaults(cloneDeep(newLogic), rootOptions, updateLocation, currentLocation);
};
export const logicFromSavedAudience = (audience) => {
  const label = get(audience, 'name');
  const savedAudienceLogic = {
    operator: 'AND',
    rules: [
      {
        field: 'Saved Audience',
        condition: '=',
        data: [
          {
            id: audience.target_audience_id || audience.id,
            label,
            pointer: 'TargetAudience',
            hasSetDefaultTrue: true,
            logicParams: {
              fieldLogicType: 'memberStatus',
            },
          },
        ],
      },
    ],
  };
  return savedAudienceLogic;
};
export const cleanLogic = (logic) => {
  const omitRuleLocations = [
    'endpoint',
    'endpointParams',
    'hasSetDefaultValue',
    'queryParams',
    'selectedFieldLogic',
  ].flatMap((loc) => [`data[0].${loc}`, `data[0].data[0].${loc}`]);

  const recursiveCleaning = (logicParam) => ({
    ...logicParam,
    rules: get(logicParam, 'rules', []).map((rule) => {
      const r = cloneDeep(rule);
      return has(r, 'logic')
        ? {
            logic: recursiveCleaning(r.logic),
          }
        : omit(r, omitRuleLocations);
    }),
  });
  return get(logic, 'rules', []).length === 0 ? null : recursiveCleaning(logic);
};
export const parseRule = (rule = {}, rootOptions = {}) => {
  const { field, condition, data = [] } = rule;
  const {
    allowSelectAllSubOptions,
    endpoint: primaryOptionsEndpoint,
    endpointParams: primaryOptionsEndpointParams = {},
    fields = [],
    fieldsMap = {},
    hasQueried: hasQueriedPrimaryOptions = false,
    hasSubOptions = false,
    queryParams: primaryOptionsQueryParams = {},
    selectAllSubOptionsLabel = 'Select All',
    name: primaryOptionName,
    optionLabel: primaryOptionLabel,
    subOptionLabel,
    subOptionName,
    opts: primaryOptionsMap = {},
  } = get(rootOptions, field, {});
  const { logicParams = {}, id: optionId, hasSetDefaultValue, type } = get(data, '[0]', {});
  const { fieldLogicType } = logicParams;
  const subOptionData = get(data, '[0].data[0]', {});
  const { id: subOptionId } = subOptionData;
  const fieldMap = get(fieldsMap, fieldLogicType, {});
  const {
    conditions = [],
    additionalFields = [],
    conditionsMap = {},
    additionalFieldsMap = {},
  } = fieldMap;
  const {
    opts: subOptionsMap = {},
    hasQueried: hasQueriedSubOptions = false,
    endpoint: subOptionsEndpoint,
    queryParams: subOptionsQueryParams = {},
    endpointParams: subOptionsEndpointParams = {},
  } = get(primaryOptionsMap, optionId, {});
  const selectedConditionData = get(conditionsMap, condition, {});
  const primaryOptionsNotFound = hasQueriedPrimaryOptions && isEmpty(primaryOptionsMap);
  const subOptionsNotFound = hasSubOptions && hasQueriedSubOptions && isEmpty(subOptionsMap);
  return {
    additionalFields: additionalFields.filter((c) => !c?.fieldTypes || c.fieldTypes.includes(type)),
    additionalFieldsMap,
    allowSelectAllSubOptions,
    condition,
    conditions: conditions.filter((c) => !c?.fieldTypes || c.fieldTypes.includes(type)),
    conditionsMap,
    data,
    field,
    fieldLogicType,
    fieldMap,
    fields,
    fieldsMap,
    hasQueriedPrimaryOptions,
    hasQueriedSubOptions,
    hasSetDefaultValue,
    hasSubOptions,
    logicParams,
    optionId,
    primaryOptionLabel,
    primaryOptionName,
    primaryOptionsEndpoint,
    primaryOptionsEndpointParams,
    primaryOptionsMap,
    primaryOptionsNotFound,
    primaryOptionsQueryParams,
    selectAllSubOptionsLabel,
    selectedConditionData,
    subOptionData,
    subOptionId,
    subOptionLabel,
    subOptionName,
    subOptionsEndpoint,
    subOptionsEndpointParams,
    subOptionsMap,
    subOptionsNotFound,
    subOptionsQueryParams,
  };
};
