import React, { useCallback, useContext } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormFieldError from '../FormFieldError';
import Alerts from './components/Alerts';
import ImageSelector from './components/ImageSelector';
import Select from './components/Select';
import { ImageContext } from './store';

const FormImageComponent = (props) => {
  const {
    label,
    isRequired,
    fieldError,
    fontWeight,
    className: providedClassName,
    uploadButtonText,
    useCropper,
    croppedRatio,
  } = props;
  const { open } = useContext(ImageContext);
  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      open();
    },
    [open]
  );

  return (
    <div className={providedClassName}>
      <Typography fontWeight={fontWeight}>
        {label}
        {isRequired && <span>*</span>}
      </Typography>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <ImageSelector useCropper={useCropper} croppedRatio={croppedRatio} />
          </Grid>

          <Grid item xs={12} sm={6} md={8} justifyContent="flex-start">
            <Select />
            <Button
              variant="contained"
              color="primary"
              onClick={onClick}
              style={{ marginTop: '10px', width: '200px' }}
            >
              {uploadButtonText ? uploadButtonText : 'Upload'}
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Alerts />
          </Grid>
        </Grid>
      </Box>
      <FormFieldError error={fieldError} />
    </div>
  );
};

export default FormImageComponent;
