export const AppUserConstants = {
  pluginId: 'manage-app-users',
  defaultPageName: 'default',
  defaultPageId: 'app-user-list-page',
  typeName: 'AppUser',
  editorPageName: 'appUserEditorPage',
  exportPageName: 'csvExportEditorPage',
  customFieldsPageName: 'appUserCustomFields',
};
export const EMPTY_APP_USER_REVISION = {
  firstName: '',
  lastName: '',
  email: '',
  userGroups: [],
  userType: 'user',
};

export const CUSTOM_FIELD_PREFIX = '###CUSTOM_FIELD_PREFIX###';
