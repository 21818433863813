import { useQuery } from '@tanstack/react-query';
import { sortBy } from 'lodash';
import uniq from 'lodash/uniq';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSheetModalUpload from '../../../shared/cmsPage/hooks/useSheetModalUpload';
import { startWatchingJob } from '../../../shared/jobs/store';
import { getAppUserCustomFields, importAppUsers } from '../../api/appUserApi';
import ImportUsersUniqueKeyConfirm from './components/ImportUsersUniqueKeyConfirm';
import useFieldValidators, { RESTRICTED_FIELDS, UNHANDLED } from './useFieldValidators';
import rfc from 'react-fast-compare';
import { selectAppUserSettings } from '../../../store/rootSelectors';

export default function useImportUsers() {
  const { requireEmailForImport, allowAdditionalFields } = useSelector(selectAppUserSettings, rfc);
  const dispatch = useDispatch();
  const { data: fields = [] } = useQuery({
    queryKey: ['useAppUserCustomFields'],
    queryFn: getAppUserCustomFields,
  });

  const requiredHeaders = useMemo(
    () => ['firstName', 'lastName', ...(requireEmailForImport ? ['email'] : [])],
    [requireEmailForImport]
  );

  const fieldOptions = useRef([]);
  useEffect(() => {
    fieldOptions.current = sortBy(
      fields.map(({ field, title }) => ({ label: title, value: field })),
      'label'
    );
  }, [fields]);
  const { fieldValidators, customFieldsSet, fieldTypes } = useFieldValidators(fields);
  const { SheetModal, open } = useSheetModalUpload({
    onClear: () => {},
    onConfirm: async ({ data, setConfirmData, proceedToNextStep, confirmStep }) => {
      const unhandledFields = uniq(data.flatMap((r) => r[UNHANDLED] || [])).sort();
      if (unhandledFields.length > 0) {
        return `Unhandled fields found:\n\n ${unhandledFields.sort().join(', ')}\n
                By proceeding these fields will not process.
                ${
                  allowAdditionalFields
                    ? `If you would like to process these fields, please add them to the custom fields.
                This can be done by clicking the "Additional Fields" button.`
                    : ``
                }`;
      }
      if (confirmStep === 2) {
        return (
          <ImportUsersUniqueKeyConfirm
            fields={fieldOptions.current}
            title="Select Unique User Fields"
            description="Select fields that will be used to determine existing users."
            onConfirm={(selectedFields) => {
              setConfirmData({ uniqueFields: selectedFields });
              proceedToNextStep();
            }}
          />
        );
      } else if (confirmStep === 3) {
        return (
          <ImportUsersUniqueKeyConfirm
            fields={fieldOptions.current}
            title="Select Overwrite Fields"
            description="Select fields to overwrite"
            onConfirm={(selectedFields) => {
              setConfirmData({ overwriteFields: selectedFields });
              proceedToNextStep();
            }}
          />
        );
      }
      return null;
    },
    onSave: async ({ data, confirmData, reload }) => {
      const { uniqueFields, overwriteFields } = confirmData;
      const { jobId } = await importAppUsers({ data, uniqueFields, overwriteFields });

      dispatch(startWatchingJob({ jobId, name: 'Users Import' }));

      await reload();
    },
    sheetProps: {
      allowEmpty: false,
      fieldValidators,
      ignoreNoHeaderColumns: true,
      requiredHeaders,
      uniqueFields: ['email'],
      uniqueHeaders: false,
      transformRow: (row) => {
        Object.entries(row).forEach(([k, v]) => {
          let val = v;
          switch (fieldTypes[k]) {
            case 'date': {
              if (!val) break;
              val = `${new Date(v).toISOString().split('T')[0]}`;
              break;
            }
            case 'phone': {
              if (!val) break;
              val = val.replaceAll(/[\s()-]/g, '');
              break;
            }
            case 'checkbox':
            case 'multiselect': {
              if (!val) break;
              val = Array.isArray(val) ? val : JSON.parse(val);
              break;
            }
          }

          if (RESTRICTED_FIELDS.has(k) || (allowAdditionalFields && customFieldsSet.has(k))) {
            row[k] = val;
          } else {
            row[UNHANDLED] = (row[UNHANDLED] || []).concat(k);
          }
        });
        return row;
      },
    },
    title: 'Import Users',
  });

  return {
    SheetModal,
    importUsers: {
      label: 'Import Users',
      onClick: ({ reload }) => open({ reload }),
    },
  };
}
