import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { navigateToPluginPage } from '../../store/rootReducer';
import { ItrsCourseConstants } from '../definitions';

export default function useCourseActions(pageConstants) {
  const { pluginId } = pageConstants;
  const dispatch = useDispatch();

  const view = useMemo(
    () => ({
      icon: 'fa-solid fa-list-tree',
      label: 'View Mail Item',
      onClick: (context) => {
        dispatch(
          navigateToPluginPage([pluginId, ItrsCourseConstants.mailLogItemPageId, context.id])
        );
      },
    }),
    [dispatch, pluginId, ItrsCourseConstants]
  );

  return {
    view,
  };
}
