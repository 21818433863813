import React, { useCallback, useEffect, useMemo } from 'react';
import { createFilter, components } from 'react-select';
import Creatable from 'react-select/async-creatable';
import useApiWithPendingStatus from '../../util/hooks/useApiWithPendingStatus';
import FieldWrapper from './FieldWrapper';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span
        style={{
          display: 'inline-block',
          width: 0,
          height: 0,
          marginLeft: '5px',
          marginRight: '5px',
          verticalAlign: 'middle',
          borderLeft: '5px solid transparent',
          borderRight: '5px solid transparent',
          borderTop: '5px solid #888888',
        }}
      />
    </components.DropdownIndicator>
  );
};

const customComponents = {
  DropdownIndicator,
  IndicatorSeparator: () => null,
};

export default function CreatableSelect(props) {
  const {
    fieldName,
    placeholder,
    formFieldOptions,
    formContext,
    options,
    optionsLoader,
    isClearable,
  } = props;

  const { register, setValue, watch } = formContext;
  const value = watch(fieldName);
  const label = useMemo(
    () => (value ? value.charAt(0).toUpperCase() + value.slice(1) : undefined),
    [value]
  );

  const handleChange = useCallback(
    (newValue, { action }) => {
      if (['clear', 'select-option', 'create-option', 'input-change'].includes(action)) {
        setValue(fieldName, action === 'clear' ? null : newValue.value, {
          shouldTouch: true,
          shouldDirty: true,
          shouldValidate: true,
        });
      }
    },
    [fieldName, setValue]
  );
  const handleOnInputChange = useCallback(
    (inputValue, { action }) => {
      if (['set-value', 'input-change'].includes(action)) {
        setValue(fieldName, inputValue, {
          shouldTouch: true,
          shouldDirty: true,
          shouldValidate: true,
        });
      }
    },
    [setValue, fieldName]
  );
  const { ref, onBlur } = register(fieldName, formFieldOptions);
  const customStyles = useMemo(
    () => ({
      control: (provided) => ({
        ...provided,
        border: 'none', // Remove all borders
        borderRadius: 0,
        borderBottom: '1px solid #888888', // Add a bottom border
        boxShadow: 'none', // Optionally remove the box shadow
      }),
    }),
    []
  );
  const filterOption = useMemo(
    () => createFilter({ ignoreCase: true, ignoreAccents: true, trim: true, matchFrom: 'any' }),
    []
  );
  const {
    requestPending,
    result: selectOptions = [],
    callApi,
  } = useApiWithPendingStatus(optionsLoader, options);
  useEffect(() => {
    if (callApi) (async () => callApi())();
  }, [callApi]);
  const loadOptions = useCallback(async () => {
    return selectOptions || [];
  }, [selectOptions]);

  return (
    <FieldWrapper
      {...props}
      label={label}
      showLabel
      showSpinner={requestPending}
      cn={['form-group', 'creatable']}
    >
      <Creatable
        defaultOptions
        isSearchable
        isClearable={isClearable}
        filterOption={filterOption}
        value={{ label, value }}
        loadOptions={loadOptions}
        onBlur={onBlur}
        onChange={handleChange}
        onInputChange={handleOnInputChange}
        placeholder={placeholder}
        ref={ref}
        components={customComponents}
        styles={customStyles}
      />
    </FieldWrapper>
  );
}
