import React, { useEffect } from 'react';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import AudienceSelector from '../../shared/form/components/AudienceSelector';
import FormInput from '../../shared/form/components/FormInput';
import FormRadioButton from '../../shared/form/components/FormRadioButton';
import FormInputTextArea from '../../shared/form/components/FormInputTextArea';
import LinkFinder from '../../shared/form/components/LinkFinder';
import FormDate from '../../shared/form/components/FormDate';

const PushNotificationEditorForm = (props) => {
  const { form, growlId, actions, viewOnly } = props;
  const { watch, setValue, formState, trigger } = form;

  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  var hh = today.getHours();
  var m = today.getMinutes();
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  today = yyyy + '-' + mm + '-' + dd + 'T' + hh + ':' + m;

  const scheduleType = watch('scheduleType', 'now');
  const sendTimestamp = watch('sendTimestamp');

  const isSubmitDisabled =
    scheduleType === 'later' && (!sendTimestamp || formState.errors.sendTimestamp);

  useEffect(() => {
    if (scheduleType === 'now') {
      setValue('sendTimestamp', '');
    } else if (scheduleType === 'later') {
      trigger('sendTimestamp');
    }
  }, [scheduleType, setValue, trigger]);

  return (
    <div className="row">
      <form role="form" className="no-left-padding">
        <FormInput
          disabled={viewOnly}
          label="Time (in minutes) the notification is available to be received"
          fieldName="ttl"
          formContext={form}
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />

        <AudienceSelector
          disabled={viewOnly}
          formContext={form}
          label="Send Notification To"
          fieldName="audienceExpr"
          growlId={growlId}
          isRequired={false}
        />

        <label>Send Notification</label>

        <div className="push-notification-radios">
          <FormRadioButton
            disabled={viewOnly}
            id="immediate"
            key="immediate"
            name="scheduleType"
            label={
              <span>
                {'Immediately'} <span className="form-input-description"></span>
              </span>
            }
            checked={scheduleType === 'now'}
            fieldName="scheduleType"
            onChange={() => setValue('scheduleType', 'now')}
            formContext={form}
            type="radio"
            value="now"
          />

          <FormRadioButton
            disabled={viewOnly}
            id="later"
            key="later"
            name="scheduleType"
            label={
              <span>
                {'Later'} <span className="form-input-description"></span>
              </span>
            }
            checked={scheduleType === 'later'}
            fieldName="scheduleType"
            onChange={() => setValue('scheduleType', 'later')}
            formContext={form}
            type="radio"
            value="later"
          />

          {scheduleType === 'later' && (
            <FormDate
              label={`Push Notification Date/Time`}
              fieldName="sendTimestamp"
              fieldWidth="normal"
              formContext={form}
              formFieldOptions={{
                required: 'Notification date/time is required',
              }}
            />
          )}
        </div>

        <FormInput
          disabled={viewOnly}
          label="Notification Subject"
          fieldName="title"
          formContext={form}
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />
        <FormInputTextArea
          disabled={viewOnly}
          label="Text for Notification"
          maxLength={1024}
          fieldName="message"
          formContext={form}
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />
        <FormInput
          disabled={viewOnly}
          label="Notification Target Url Link Text"
          fieldName="linkText"
          formContext={form}
        />

        <LinkFinder
          disabled={viewOnly}
          defaultValue=""
          formContext={form}
          label="Notification Target Url"
          fieldName="url"
          allowEmbeddedMenus={true}
          required={false}
        />
      </form>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} isSubmitDisabled={isSubmitDisabled} />
      </div>
    </div>
  );
};

export default PushNotificationEditorForm;
