import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import React, { useEffect, useState } from 'react';
import GenericDate from '../../genericInputs/GenericDate';
import useAdditionalSearchFieldOptions from '../hooks/useAdditionalSearchFieldOptions';

const MultiSelectField = ({ fieldData, set }) => {
  const { key, defaultSelected = [], variant } = fieldData;
  const searchOptions = useAdditionalSearchFieldOptions(fieldData);
  const [selectedOptions, setSelectedOptions] = useState(defaultSelected);
  const defaultString = defaultSelected.sort().join(',');
  const setForm = (value) =>
    set((c) => ({ ...c, [key]: typeof value === 'string' ? value.split(',') : value }));

  useEffect(() => {
    if (defaultString) setForm(defaultString);
  }, [defaultString]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(typeof value === 'string' ? value.split(',') : value);
    set((c) => ({ ...c, [key]: typeof value === 'string' ? value.split(',') : value }));
  };

  return (
    <FormControl fullWidth variant={variant}>
      <Select
        multiple
        value={selectedOptions}
        onChange={handleChange}
        variant="standard"
        sx={{ width: '180.5px !important' }}
        renderValue={(i) => `${i.length} selected`}
        MenuProps={{ PaperProps: { style: { maxHeight: 400, width: 220 } } }}
      >
        {searchOptions.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              p: '4px 0px !important',
              '& .MuiCheckbox-root': { padding: '0px 8px !important' },
            }}
          >
            <Checkbox checked={selectedOptions.includes(option.value)} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const Label = ({ children }) => (
  <div style={{ color: 'grey', fontSize: '11px', marginBottom: '1px' }}>{children}</div>
);

const CheckboxField = ({ fieldData, transform, set }) => {
  const { key } = fieldData;
  return (
    <label>
      <input
        type="checkbox"
        className="form-switch"
        onChange={(e) => set((c) => ({ ...c, [key]: transform(e.target.checked) }))}
      />
      {fieldData.placeholder}
    </label>
  );
};

const SwitchField = ({ fieldData, transform, set }) => {
  const { key } = fieldData;
  return (
    <div>
      <Switch
        role="switch"
        onChange={(e) => set((c) => ({ ...c, [key]: transform(e.target.checked) }))}
      />
    </div>
  );
};

const TextFieldComponent = ({ set, transform, fieldData }) => {
  const { type, placeholder, key, variant } = fieldData;
  return (
    <GenericDate
      type={type}
      variant={variant}
      className="form-control"
      fieldWidth="tiny"
      placeholder={`${type.startsWith('date') ? `Select` : 'Enter'} ${placeholder}`}
      onChange={(e) => set((c) => ({ ...c, [key]: transform(e.target.value) }))}
    />
  );
};

const SelectField = ({ fieldData, transform, set }) => {
  const { key, noSelectText = 'None', multiple = false } = fieldData;
  const searchOptions = useAdditionalSearchFieldOptions(fieldData);
  const [selectedValue, setSelectedValue] = useState(multiple ? [] : '');

  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedValue(value);
    set((c) => ({
      ...c,
      [key]: multiple ? value.map((val) => transform(val)) : transform(value),
    }));
  };

  return (
    <FormControl fullWidth>
      <Select
        variant="standard"
        multiple={multiple}
        value={selectedValue}
        onChange={handleChange}
        style={{ minWidth: '203px' }}
        displayEmpty
      >
        {!multiple && (
          <MenuItem value="" disabled={searchOptions.length === 0}>
            {noSelectText}
          </MenuItem>
        )}
        {searchOptions.map((option, optionIndex) => (
          <MenuItem key={optionIndex} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const CmsListAdditionalFields = ({ setAdditionalFieldsSearch: set, additionalSearchFields }) =>
  additionalSearchFields.map((fieldData = {}) => {
    const { type, key, parseBool, multiple } = fieldData;
    const transform = (val) => {
      if (parseBool && val === 'true') return true;
      if (parseBool && val === 'false') return false;
      return val;
    };
    let Component = null;
    switch (type) {
      case 'text':
      case 'date': {
        Component = (
          <TextFieldComponent
            variant="standard"
            set={set}
            transform={transform}
            fieldData={fieldData}
          />
        );
        break;
      }
      case 'select': {
        Component = multiple ? (
          <MultiSelectField set={set} transform={transform} fieldData={fieldData} />
        ) : (
          <SelectField set={set} transform={transform} fieldData={fieldData} />
        );
        break;
      }
      case 'switch': {
        Component = <SwitchField set={set} transform={transform} fieldData={fieldData} />;
        break;
      }
      case 'checkbox': {
        Component = <CheckboxField set={set} transform={transform} fieldData={fieldData} />;
        break;
      }
      default: {
        Component = null;
        break;
      }
    }

    return (
      <div
        key={key}
        style={{
          marginLeft: '.5rem',
          marginRight: '.5rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        <Label>{fieldData.placeholder}</Label>
        {Component}
      </div>
    );
  });

export default CmsListAdditionalFields;
