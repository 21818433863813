import axios from 'axios';
import log from '../../logger';

const baseUrl = '/plugins/custom-plugins/iuoe/itrs/api';

const loadRecord = async (id) => {
  try {
    const { data } = await axios.get(`${baseUrl}/getMailLogItem`, { params: { id } });
    return data.mailLogItem;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load mailLogItem.');
  }
};

const loadRecords = async (params) => {
  try {
    const { sort, ...otherParams } = params;
    const { sortField, order } = sort;
    const sortReverse = sortField ? order === 'ASC' : undefined;
    const {
      data: { rows, count },
    } = await axios.get(`${baseUrl}/getAllMail`, {
      params: { ...otherParams, sortField, sortReverse },
      timeout: 10_000,
    });
    return {
      records: rows,
      numRecords: count,
    };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load MailLog records.');
  }
};

const itrsApi = {
  loadRecord,
  loadRecords,
};

export default itrsApi;
