import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useFieldProps from '../hooks/useFieldProps';
import FieldWrapper from './FieldWrapper';

const FormInput = (props) => {
  const {
    label,
    fieldName,
    placeholder,
    formFieldOptions = {},
    formContext,
    fontWeight,
    variant = 'standard',
    type: providedType,
    inputProps,
    maxLength,
    onChange: providedOnChange,
    onBlur: providedOnBlur,
    defaultValue = '',
  } = props;

  const [value, setValue] = useState(defaultValue);
  const { hasError, value: watchValue, errorMessage, inputAttributes } = useFieldProps(props, true);
  const { register } = formContext;

  const registeredProps = register(fieldName, {
    ...formFieldOptions,
    ...(providedType === 'email' && {
      pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: 'Please enter a valid email address',
      },
    }),
    ...(providedType === 'phone' && {
      pattern: {
        value: /^\d{10}$/,
        message: 'Please enter a valid 10-digit phone number',
      },
    }),
    ...(providedType === 'integer' && {
      validate: (value) => {
        if (value === '' || value === null || value === undefined) {
          return true;
        }
        const numberValue = Number(value);
        return Number.isInteger(numberValue) || 'Please enter a valid integer';
      },
    }),
    ...(providedType === 'number' && {
      validate: (value) => {
        if (value === '' || value === null || value === undefined) {
          return true;
        }
        const numberValue = Number(value);
        return !Number.isNaN(numberValue) || 'Please enter a valid number';
      },
    }),
  });

  useEffect(() => {
    setValue(watchValue ?? defaultValue);
  }, [watchValue]);

  return (
    <FieldWrapper {...props} controlProps>
      <TextField
        label={`${label}${formFieldOptions.required ? '*' : ''}`}
        fontWeight={fontWeight}
        value={value}
        placeholder={placeholder}
        type={providedType === 'integer' ? 'number' : providedType || 'text'}
        step={providedType === 'number' ? 'any' : '1'}
        error={hasError}
        helperText={errorMessage}
        variant={variant}
        {...inputAttributes}
        {...registeredProps}
        inputProps={{
          ...registeredProps.inputProps,
          ...inputAttributes.inputProps,
          ...inputProps,
          ...(maxLength && !Number.isNaN(maxLength) && { maxLength }),
          ...(providedType === 'integer' || providedType === 'number'
            ? { inputMode: 'numeric', pattern: '[0-9]*' }
            : {}),
        }}
        onChange={(e) => {
          let v = e.target.value;
          if (maxLength && !Number.isNaN(maxLength)) {
            v = v.slice(0, maxLength);
          }
          setValue(v);
          e.target.value = v;
          registeredProps.onChange(e);
          if (providedOnChange) providedOnChange(e);
        }}
        onBlur={(e) => {
          registeredProps.onBlur(e);
          if (providedOnBlur) providedOnBlur(e);
        }}
      />
    </FieldWrapper>
  );
};

export default FormInput;
