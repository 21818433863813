import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';

const UserResultsList = ({ results, selectedUserId, onSelectUser, lastSearchedVal }) => {
  return (
    <RadioGroup value={selectedUserId} onChange={onSelectUser} sx={{ mt: 2 }}>
      {results.map((u) => {
        let msg = '';
        if (!u?.hasRequiredFields) {
          msg = ' - Missing Required Fields';
        } else if (u?.approvedPending) {
          msg = ' - Already Enrolled or Pending';
        } else if (u?.completed) {
          msg = ' - Enrollment Complete';
        }
        return (
          <FormControlLabel
            key={u?.id}
            value={u?.id?.toString()}
            control={<Radio />}
            label={`${u?.name} (${lastSearchedVal})${msg}`}
            disabled={!!msg}
          />
        );
      })}
    </RadioGroup>
  );
};

export default UserResultsList;
