import React, { useState } from 'react';
import { Box, TextField, Button } from '@mui/material';
import { validateEmail } from '../../../shared/util/emailValidators';
const UserSearchForm = ({
  searchUsers,
  searchValue,
  setLastSearchedVal,
  setSearchValue,
  setSelectedUser,
  userType,
}) => {
  const [error, setError] = useState(false);

  const handleFind = async () => {
    if (!searchValue || error) return;
    setSelectedUser(null);
    setLastSearchedVal(searchValue);
    await searchUsers({ searchValue, userType });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleFind();
    }
  };

  const isStudent = userType === 'STUDENT';
  const isStaff = userType === 'ASSISTANT' || userType === 'INSTRUCTOR';

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <TextField
        label={isStudent ? 'Registration Number' : 'Email Address'}
        value={searchValue}
        required
        onChange={(e) => {
          const value = e.target.value;
          if (isStudent) {
            const regex = new RegExp(`^\\d{0,7}$`);
            if (regex.test(value)) setSearchValue(value);
          } else if (isStaff) {
            setSearchValue(value);
            setError(!validateEmail(value));
          } else {
            setSearchValue(value);
          }
        }}
        onKeyDown={handleKeyDown}
        variant="standard"
        fullWidth
        type={isStudent ? 'number' : isStaff ? 'email' : 'text'}
        {...(isStudent && {
          inputProps: { maxLength: 7, inputMode: 'numeric', pattern: '[0-9]*' },
          step: '1',
        })}
      />
      <Button onClick={handleFind} variant="contained" disabled={!searchValue || error}>
        Find
      </Button>
    </Box>
  );
};

export default UserSearchForm;
