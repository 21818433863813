import React from 'react';
import { FormControlLabel, Radio } from '@mui/material';

const FormRadioButton = ({
  id,
  label,
  value,
  checked,
  onChange,
  onBlur,
  name,
  disabled,
  labelStyle = {},
}) => {
  return (
    <FormControlLabel
      control={
        <Radio
          id={id}
          value={value}
          checked={checked}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          name={name}
        />
      }
      label={<span style={labelStyle}>{label}</span>}
    />
  );
};

export default FormRadioButton;
