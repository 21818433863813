import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { has } from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import BackButton from '../../shared/cmsPage/components/BackButton';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsPageSection from '../../shared/cmsPage/components/CmsPageSection';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import FormDate from '../../shared/form/components/FormDate';
import FormInput from '../../shared/form/components/FormInput';
import MultiSelect from '../../shared/form/components/MultiSelect';
import Select from '../../shared/form/components/Select';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import GrowlContainer from '../../shared/growl/components/GrowlContainer';
import leadGenEventApi, { getLeadGenGroups, getLeadGenIngestTypes } from '../api/leadGenEventApi';
import { LeadGenEventConstants } from '../definitions';
export default function LeadGenEventEditorPage({ leadGenEventId = 'new' }) {
  const [showExternalId, setShowExternalId] = useState(false);
  const { item, loadItem, saveItem, requestPending } = useItemPersistence(
    leadGenEventApi,
    leadGenEventId
  );
  const form = useCmsForm(item);
  const ingestType = useWatch({
    control: form.control,
    name: 'ingestType',
  });

  const startDate = useWatch({ control: form.control, name: 'startDate' });
  const endDate = useWatch({ control: form.control, name: 'endDate' });

  useEffect(() => {
    if (ingestType === 'api' || ingestType === 'both') {
      setShowExternalId(true);
      form.register('externalId', { required: 'External API ID is required.' });
    } else {
      setShowExternalId(false);
      form.unregister('externalId');
    }
  }, [ingestType, form]);

  const actions = useItemEditActions(saveItem, form, LeadGenEventConstants, leadGenEventId);
  useEffect(() => {
    if (leadGenEventId) (async () => await loadItem(leadGenEventId))();
  }, [leadGenEventId, loadItem]);

  const { pageTitle, onItemChange } = useEditPageTitle(leadGenEventId, 'LeadGenEvent', 'name');
  useEffect(() => onItemChange(item), [item, onItemChange]);
  const loadIngestTypeOptions = useCallback(async () => {
    const options = await getLeadGenIngestTypes();
    if (options.length === 1) {
      form.setValue('ingestType', options[0].value);
    }
    return options;
  }, [form]);

  useEffect(() => {
    if (ingestType === 'api' || ingestType === 'both') {
      setShowExternalId(true);
      form.register('externalId', { required: 'External API ID is required.' });
    } else {
      setShowExternalId(false);
      form.unregister('externalId');
    }
  }, [ingestType, form]);

  return (
    <CmsPage title={pageTitle} requestPending={requestPending}>
      <CmsPageSection className="cohort-editor">
        <div className="row">
          <BackButton
            pageName="Lead Gen Events"
            navToParent
            navParams={[LeadGenEventConstants.pluginId, 'default', LeadGenEventConstants.pageTitle]}
          />
        </div>
        <GrowlContainer groupId={LeadGenEventConstants.editorPageId} />
        <div className="row ">
          <form role="form" className="no-left-padding">
            <FormInput
              label="Event Name"
              fieldName="eventName"
              formContext={form}
              required={true}
              formFieldOptions={{
                required: 'This field is required.',
              }}
            />
            <FormDate
              label={`Start Date`}
              fieldName="startDate"
              localTimezone
              formContext={form}
              formFieldOptions={{
                validate: {
                  endDateBeforeStartDate: (startDate) => {
                    if (endDate && startDate && moment(startDate).isAfter(endDate)) {
                      return 'Start Date Must Be Before End Date';
                    }
                    return true;
                  },
                },
              }}
            />
            <div style={{ marginTop: '40px' }}>
              <FormDate
                label={`End Date`}
                fieldName="endDate"
                localTimezone
                formContext={form}
                formFieldOptions={{
                  validate: {
                    startDateBeforeEndDate: (endDate) => {
                      if (startDate && endDate && moment(endDate).isBefore(startDate)) {
                        return 'End Date Must Be After Start Date';
                      }
                      return true;
                    },
                  },
                }}
              />
            </div>
            <FormInput
              label="Location"
              fieldName="location"
              formContext={form}
              placeholder="Enter Location"
            />
            <MultiSelect
              fieldName="eventGroups"
              formContext={form}
              formFieldOptions={{ required: 'This field is required.' }}
              label="Event Groups"
              labelKey="name"
              limitTags={20}
              optionsLoader={getLeadGenGroups}
              placeholder="Select Groups"
              sortTagsKey="name"
              isOptionEqualToValue={(opt, value) => {
                if (has(opt, 'id')) {
                  return opt.id === value.id;
                } else if (has(opt, 'key')) {
                  return opt.key === value.key;
                }
                return isEqual(opt, value);
              }}
            />
            <Select
              label="Data Source"
              optionsLoader={loadIngestTypeOptions}
              formContext={form}
              fieldName="ingestType"
            />
            {showExternalId && (
              <FormInput
                label="External Api Id"
                fieldName="externalId"
                formContext={form}
                placeholder="Enter external cohort ID."
                formFieldOptions={{
                  required: 'External API ID is required.',
                }}
              />
            )}
          </form>
        </div>
        <div className="col col-lg-12 col-md-12 col-sm-12">
          <div className="row module-footer">
            <CmsPageSectionFooter actions={actions} />
          </div>
        </div>
      </CmsPageSection>
    </CmsPage>
  );
}
