import React, { useContext, useMemo } from 'react';
import CmsPageSection from './CmsPageSection';
import { CmsPageContext } from '../definitions';
import { useSpinner } from '../../spinner/hooks/useSpinner';
import GrowlContainer from '../../growl/components/GrowlContainer';
import useWrapPageActions from '../hooks/useWrapPageActions';
import CmsListSectionToolbar from './CmsListSectionToolbar';
import OrderedItemTable from '../../itemTable/components/OrderedItemTable';
import useLoadListItems from '../hooks/useLoadListItems';
import { useListLoaderParams } from '../../cms/hooks/useListLoaderParams';
import { useListLoader } from '../../cms/hooks/useListLoader';
import ConditionalAlert from '../../generalComponents/ConditionalAlert';
import log from '../../../logger';

const CmsSubItemListSection = (props) => {
  const {
    additionalSearchFields = [],
    memoizedAdditionalLoaderParams = {},
    alert,
    className,
    columns,
    emptyListMessage,
    itemActions,
    onAdditionalSearchFieldsChange,
    pageConstants,
    parentItemId,
    parentPageTitle,
    reorder,
    subItemApi,
    toolbarActions,
    showBreadCrumb,
    priorityFilter,
    prioritySort,
    searchHeader,
    searchPlaceholder,
    showSearchBar,
    pageId,
  } = props;
  const { pluginId, parentPageId, parentPageName, defaultPageId } = pageConstants;
  const { spinnerProvider } = useContext(CmsPageContext);
  const { loadRecords } = subItemApi;
  const { loadItems, loading, records } = useListLoader(loadRecords);
  useSpinner(spinnerProvider, loading);
  const { loaderParams, onSearchChange } = useListLoaderParams(
    pageId,
    Number.MAX_SAFE_INTEGER,
    columns,
    memoizedAdditionalLoaderParams,
    parentItemId
  );
  const reload = useLoadListItems(pageId, loadItems, loaderParams);
  const wrappedToolbarActions = useWrapPageActions(defaultPageId, toolbarActions, reload);
  const wrappedItemActions = useWrapPageActions(defaultPageId, itemActions, reload);

  const [allItems, sortItemsLength] = useMemo(() => {
    const { standardRecords, priorityRecordsUnsorted } = priorityFilter
      ? records.reduce(
          (acc, record) => {
            if (priorityFilter(record)) acc.priorityRecordsUnsorted.push(record);
            else {
              acc.standardRecords.push(record);
            }
            return acc;
          },
          { standardRecords: [], priorityRecordsUnsorted: [] }
        )
      : { standardRecords: records, priorityRecordsUnsorted: [] };

    let allItems;
    if (prioritySort) {
      const priorityRecords = priorityRecordsUnsorted.sort(prioritySort);
      allItems = priorityRecords.concat(standardRecords);
      //Check that the collection order needs to be updated
      const itemsOutOfOrder = allItems.find((item, idx) => {
        return item.position != idx;
      });
      if (itemsOutOfOrder) {
        try {
          const ids = allItems.map(({ id }) => id);
          reorder(parentItemId, ids);
        } catch (e) {
          log.error(e);
        }
      }
    } else {
      allItems = priorityRecordsUnsorted.concat(standardRecords).filter(Boolean);
    }
    return [allItems, priorityRecordsUnsorted.length];
  }, [priorityFilter, prioritySort, records]);

  return (
    <>
      <CmsPageSection className={className}>
        <div className="row">
          <CmsListSectionToolbar
            showBreadCrumb={showBreadCrumb}
            pluginId={pluginId}
            parentPageTitle={parentPageTitle}
            parentPageId={parentPageId}
            parentPageName={parentPageName}
            actions={wrappedToolbarActions}
            additionalSearchFields={additionalSearchFields}
            searchExpr={loaderParams.search}
            onSearchChange={onSearchChange}
            showSearchBar={showSearchBar}
            searchHeader={searchHeader}
            searchPlaceholder={searchPlaceholder}
            onAdditionalSearchFieldsChange={onAdditionalSearchFieldsChange}
          />
        </div>
        <GrowlContainer groupId={defaultPageId} />
        {alert && <ConditionalAlert {...alert} />}
        <div className="row">
          <OrderedItemTable
            reorderDisabled={!!loaderParams.search}
            columns={columns}
            items={allItems}
            itemActions={wrappedItemActions}
            emptyListMessage={emptyListMessage}
            reorder={reorder}
            parentItemId={parentItemId}
            growlId={defaultPageId}
            injectedRowCount={sortItemsLength}
          />
        </div>
      </CmsPageSection>
    </>
  );
};

export default CmsSubItemListSection;
