import React from 'react';
import Divider from '@mui/material/Divider';
import { Avatar, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

const PageItem = ({ number, text, highlight, showDivider = true }) => {
  return (
    <>
      <Avatar sx={{ bgcolor: highlight ? '#1B76D2' : grey[500], mr: 2 }}>{number}</Avatar>
      <Typography
        sx={{ alignSelf: 'center', mr: 2 }}
        color={highlight ? 'text.primary' : 'text.disabled'}
      >
        {text}
      </Typography>
      {showDivider && <Divider sx={{ alignSelf: 'center', width: '100px', mr: 2 }} />}
    </>
  );
};
//Add comment for name change
const NumberedPageHeader = ({ headers, selectedHeader }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', margin: '2em 0' }}>
      {headers.map((header, index) => {
        return (
          <PageItem
            key={index}
            number={index + 1}
            text={header}
            highlight={selectedHeader === header}
            showDivider={index < headers.length - 1}
          />
        );
      })}
    </div>
  );
};

export default NumberedPageHeader;
