import axios from 'axios';
import log from '../../logger';
import _ from 'lodash';

const baseUrl = '/plugins/custom-plugins/iuoe/itrs/api';
//Endpoints

export const getSessions =
  (additionalParams = {}) =>
  async (params) => {
    try {
      const { sort = {}, ...otherParams } = params;
      const { sortField, order } = sort;
      const sortReverse = sortField ? order === 'DESC' : undefined;
      const {
        data: {
          sessions: { rows, count },
        },
      } = await axios.get(`${baseUrl}/getSessionsForTravelApproval`, {
        params: { ...otherParams, sortField, sortReverse, ...additionalParams },
        timeout: 10_000,
      });

      return {
        records: rows,
        numRecords: count,
      };
    } catch (e) {
      log.error(e);
      throw new Error('Failed to load session');
    }
  };

const loadRecord = async (id) => {
  try {
    const response = await axios.get(`${baseUrl}/getTravelReq`, { params: { id } });
    return response.data.travelReq;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load travel record.');
  }
};

export function upsertTravelConfirmation(travelRequestId) {
  return async (data) => {
    try {
      const response = await axios.post(`${baseUrl}/upsertTravelConfirmation`, {
        data: { ..._.omit(data, ['departFrom', 'travelTo']), travelRequestId },
      });
      return response.data.travelConfirmation;
    } catch (e) {
      log.error(e);
      throw new Error('Failed to save confirmation.');
    }
  };
}

export default (additionalParams) => ({
  loadRecords: getSessions(additionalParams),
  loadRecord,
});
