import { Select as MUISelect, MenuItem } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import useApiWithPendingStatus from '../../util/hooks/useApiWithPendingStatus';
import useFieldProps from '../hooks/useFieldProps';
import FieldWrapper from './FieldWrapper';

const Select = (props) => {
  const {
    label,
    variant = 'standard',
    fieldName,
    formFieldOptions,
    formContext,
    options,
    optionsLoader,
  } = props;
  const { control } = formContext;
  const {
    requestPending,
    result: selectOptions,
    callApi,
  } = useApiWithPendingStatus(optionsLoader, options);
  useEffect(() => {
    if (callApi) (async () => callApi())();
  }, [callApi]);
  const { value, isRequired, inputAttributes } = useFieldProps(props, true);

  return (
    <FieldWrapper
      {...props}
      cn={['form-group']}
      showError
      showSpinner={requestPending}
      controlProps
      showLabel
    >
      <Controller
        name={fieldName}
        control={control}
        rules={formFieldOptions}
        render={({ field, fieldState }) => (
          <MUISelect
            {...field}
            {...inputAttributes}
            variant={variant}
            label={label}
            value={value}
            required={isRequired}
            error={!!fieldState.error}
            onClose={() => {
              setTimeout(() => {
                document.activeElement.blur();
              }, 300);
            }}
          >
            {selectOptions &&
              selectOptions.map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
          </MUISelect>
        )}
      />
    </FieldWrapper>
  );
};

export default Select;
