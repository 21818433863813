import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import toString from 'lodash/toString';
import React, { useEffect, useMemo, useState } from 'react';
import useApiWithPendingStatus from '../../util/hooks/useApiWithPendingStatus';
import FieldWrapper from './FieldWrapper';

const StyledTextField = styled(TextField)({
  position: 'relative',
  '& label.Mui-focused': {
    color: '#00000080',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none', // Remove the default full border
      padding: '0', // Remove the default padding
    },
    position: 'relative',
    // Add a constant bottom border
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: '1px',
      width: '100%',
      backgroundColor: '#888888', // Default color of the bottom border
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: '2px', // This will become visible on hover and focus
      width: '100%',
      backgroundColor: '#1A76D2', // Blue color for focus
      transform: 'scaleX(0)', // Initially hidden
      transformOrigin: 'center', // Shrink effect from the center
      transition: 'transform 0.3s ease, background-color 0.3s ease', // Smooth transition for blur and color
    },
    '&:hover::before': {
      transform: 'scaleX(1)', // Full width on hover
      backgroundColor: '#000000', // Black color on hover
      height: '2px', // Keep the height 2px on hover
    },
    '&.Mui-focused::before': {
      transform: 'scaleX(1)', // Full width when focused
      backgroundColor: '#1A76D2', // Blue color when focused
      transition: 'none', // No transition on focus
    },
    '&.Mui-focused:hover::before': {
      backgroundColor: '#1A76D2', // Ensure the hover doesn't override the focus color
    },
    '&:not(.Mui-focused)::before': {
      transform: 'scaleX(0)', // Shrink the border on blur
      transition: 'transform 0.3s ease', // Smooth transition for blur animation
    },
  },
});

const MultiSelect = (props) => {
  const {
    defaultValue,
    fieldName,
    findValueInOptions = false,
    formContext: { getValues, register, setValue },
    formFieldOptions,
    isSingleSelect = false,
    label,
    labelKey,
    options,
    optionsLoader,
    placeholder,
    sort = false,
    sortTagsKey,
    valueKey,
    filterFunction,
    isOptionEqualToValue: isOptionEqualToValueFunction,
    ...selectAttributes
  } = props;
  const value = get(getValues(), fieldName, isSingleSelect ? '' : []);
  const [hasSetDefaultValue, setHasSetDefaultValue] = useState(false);
  register(fieldName, formFieldOptions);
  const {
    requestPending,
    result: selectOptions = [],
    callApi,
  } = useApiWithPendingStatus(optionsLoader, options);

  useEffect(() => {
    if (callApi) (async () => callApi())();
  }, [callApi]);

  useEffect(() => {
    if (selectOptions.length > 0 && defaultValue && !hasSetDefaultValue) {
      const defaultOption = selectOptions.find((opt) => opt[valueKey] === defaultValue);
      if (defaultOption) {
        setValue(fieldName, defaultOption[valueKey], {
          shouldValidate: true,
          shouldDirty: true,
        });
        setHasSetDefaultValue(true);
      }
    }
  }, [
    selectOptions,
    defaultValue,
    setValue,
    fieldName,
    valueKey,
    hasSetDefaultValue,
    setHasSetDefaultValue,
  ]);

  const currentValue = useMemo(() => {
    if (isSingleSelect) {
      return findValueInOptions ? selectOptions.find((v) => v[valueKey] === value) : value || '';
    } else if (value && Array.isArray(value) && value.length) {
      return sortTagsKey
        ? sortBy(value, [(v) => toString(get(v, sortTagsKey, '')).toLowerCase()], ['asc'])
        : value;
    }
    return [];
  }, [value, findValueInOptions, selectOptions, isSingleSelect, sortTagsKey]);

  const filteredOpts = filterFunction
    ? (selectOptions || []).filter((val) => filterFunction(val, currentValue))
    : selectOptions || [];
  const opts = sort
    ? sortBy(
        filteredOpts,
        [(v) => toString(labelKey ? get(v, labelKey || '', '') : v).toLowerCase()],
        ['asc']
      )
    : filteredOpts;

  return (
    <FieldWrapper {...props} cn={'form-group'} showError showLabel showSpinner={requestPending}>
      <Autocomplete
        label={label}
        key={fieldName}
        {...selectAttributes}
        {...(isSingleSelect ? {} : { multiple: true })}
        disableCloseOnSelect={!isSingleSelect}
        sx={{
          '& .MuiOutlinedInput-root': {
            paddingLeft: 0, // Remove padding from the input root
            paddingTop: '5px',
            paddingBottom: '5px',
          },
          '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            paddingLeft: 0, // Remove padding from the autocomplete input
          },
          '& .MuiOutlinedInput-root .MuiInputBase-input': {
            paddingLeft: 0, // Remove padding from the base input
          },
        }}
        options={opts}
        onChange={(e, val) => {
          if (!val && isSingleSelect) val = '';
          if (isSingleSelect) {
            setValue(fieldName, valueKey ? val[valueKey] : val, {
              shouldValidate: true,
              shouldDirty: true,
            });
          } else {
            const mappedValues = val.map((v) => (valueKey && v[valueKey] ? v[valueKey] : v));
            setValue(fieldName, mappedValues, {
              shouldValidate: true,
              shouldDirty: true,
            });
          }
        }}
        {...(sortTagsKey && {
          renderTags: (tagValues, getTagProps) =>
            currentValue.map((opt, idx) => {
              return (
                <Chip key={idx} label={get(opt, labelKey, '')} {...getTagProps({ index: idx })} />
              );
            }),
        })}
        getOptionLabel={(opt) => {
          const basicLabel = get(opt, labelKey, '');
          if (basicLabel) return basicLabel;
          const targetOpt = opts.find((o) => o[valueKey] === opt);
          const { label } = targetOpt || {};
          return label;
        }}
        defaultValue={currentValue}
        isOptionEqualToValue={(opt, val) => {
          if (isOptionEqualToValueFunction) {
            return isOptionEqualToValueFunction(opt, val);
          }
          if (typeof val === 'object') {
            return opt[valueKey] === val[valueKey];
          }
          return opt[valueKey] === val;
        }}
        renderInput={(params) => <StyledTextField {...params} placeholder={placeholder} />}
      />
    </FieldWrapper>
  );
};

export default MultiSelect;
