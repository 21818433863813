import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormCheckBoxGroup from '../../../../shared/form/components/FormCheckBoxGroup';

export default function ImportUsersUniqueKeyConfirm({
  onConfirm,
  title,
  description,
  setConfirmSubmit,
  fields,
}) {
  const form = useForm({ defaultValues: { selectedFields: [] } });
  const { handleSubmit, setValue } = form;

  const [selectAll, setSelectAll] = useState(false);

  const onSubmit = ({ selectedFields }) => {
    onConfirm(selectedFields);
  };

  useEffect(() => {
    if (setConfirmSubmit) {
      setConfirmSubmit(() => {
        document
          .getElementById('unique-key-form')
          .dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      });
    }
  }, [setConfirmSubmit]);

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);

    if (isChecked) {
      setValue(
        'selectedFields',
        fields.map((f) => f.value),
        { shouldDirty: true }
      );
    } else {
      setValue('selectedFields', [], { shouldDirty: true });
    }
  };

  return (
    <form id="unique-key-form" onSubmit={handleSubmit(onSubmit)}>
      <h5>{title}</h5>
      <p>{description}</p>
      <FormControlLabel
        sx={{ '& .MuiButtonBase-root': { py: 0 } }}
        control={<Checkbox checked={selectAll} onChange={handleSelectAllChange} />}
        label="Select All"
      />

      <FormCheckBoxGroup
        columns={3}
        form={form}
        name="selectedFields"
        options={fields}
        label="Select Fields"
      />
    </form>
  );
}
