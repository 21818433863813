import React from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import useWysiwygOptions from '../../shared/form/hooks/useWysiwygOptions';

import { finalizeWysiwygContent } from '../util/froalaUtils';

const Wysiwyg = ({ value, setValue, label }) => {
  const { wysiwygOptions } = useWysiwygOptions();
  const finalized = (newValue) => {
    const final = finalizeWysiwygContent(newValue);
    setValue(final);
  };
  return (
    <div className="form-group">
      <label>{label}</label>
      {wysiwygOptions && (
        <FroalaEditor config={wysiwygOptions} model={value} onModelChange={finalized} />
      )}
    </div>
  );
};

export default Wysiwyg;
