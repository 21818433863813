export const HELP_TEXT_KEYS = {
  LABEL: 'label',
  TIMED: 'timed',
  LINK: 'link',
  DETAIL: 'detail',
  COHORT: 'cohort',
  DISPLAY_IMAGE: 'display-image',
  DESCRIPTION: 'description',
  CATEGORY: 'category',
  AUDIENCE: 'audience',
  LOCKED: 'locked',
};

export const helpText = {
  [HELP_TEXT_KEYS.LABEL]:
    'This is a collection name. It is used to create what a collection shall be called.',
  [HELP_TEXT_KEYS.TIMED]:
    'Timed/Untimed determines if the collection item should have a start date/time and end date/time.',
  [HELP_TEXT_KEYS.LINK]:
    "Link will create a hyperlink named by default 'View'. This is useful for linking to external resources or to other areas of the app.",
  [HELP_TEXT_KEYS.DETAIL]:
    'This creates a description area on the collection item, allowing for more detailed information to be displayed.',
  [HELP_TEXT_KEYS.COHORT]:
    'This allows for collections to be linked to a cohort. This is useful for grouping collections together.',
  [HELP_TEXT_KEYS.DISPLAY_IMAGE]:
    'This allows for an image to be displayed on the collection item. If no image is provided, a default image will be displayed.',
  [HELP_TEXT_KEYS.DESCRIPTION]:
    'This is a description of the collection item. It is used to provide a brief overview of what the collection item is.',
  [HELP_TEXT_KEYS.CATEGORY]:
    'This is another way to organize collection items. It is used to categorize collections.',
  [HELP_TEXT_KEYS.AUDIENCE]:
    'This is used to determine who the collection item is intended for. It is used to help filter collections.',
  [HELP_TEXT_KEYS.LOCKED]:
    'This is used to lock a collection item. This is useful for collections that are not yet ready to be viewed.',
};
