import { createSlice } from '@reduxjs/toolkit';
import set from 'lodash/set';
import update from 'lodash/update';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { errorGrowl, infoGrowl, successGrowl } from './rootThunks';
export const reducerName = 'root';
const DEFAULT_SNACKBAR = { duration: 6000, message: 'Error', severity: 'error', open: false };
export const { actions, reducer } = createSlice({
  name: reducerName,
  initialState: {
    config: {},
    growls: {},
    snackbar: DEFAULT_SNACKBAR,
    navigation: { pluginName: '', pageName: '', args: '', key: uuid() },
  },
  reducers: {
    setConfig: (state, { payload = {} }) => {
      state.config = payload.config;
    },
    showSnackbar: (state, { payload = {} }) => {
      state.snackbar = { ...DEFAULT_SNACKBAR, ...payload, open: true };
    },
    hideSnackbar: (state) => {
      state.snackbar = {};
    },
    dismissGrowl: (state, { payload: { growl } }) => {
      if (!growl.dismissed) {
        update(state, `growls.${growl.groupId}`, (growls = []) =>
          growls.filter((g) => g.id !== growl.id)
        );
      }
    },
    dismissAllGrowls: (state, { payload: { groupId } }) => {
      set(state, `growls.${groupId}`, undefined);
    },
    navigateToParentPage: (state, { payload = [] }) => {
      const [pluginName, pageName, parentPageId, ...args] = payload;
      sessionStorage.setItem(
        `restore-state-${parentPageId}`,
        moment().millisecond(500).format('x')
      );
      set(state, `navigation`, {
        pluginName,
        pageName,
        args: args ? args.join(',') : '',
        key: uuid(),
      });
    },
    navigateToChildPage: (state, { payload = [] }) => {
      const [pluginName, pageName, parentPageId, savedParams, ...args] = payload;
      sessionStorage.setItem(`page-state-${parentPageId}`, JSON.stringify(savedParams));
      set(state, `navigation`, {
        pluginName,
        pageName,
        args: args ? args.join(',') : '',
        key: uuid(),
      });
    },
    navigateToPluginPage: (state, { payload = [] }) => {
      const [pluginName, pageName, ...args] = payload;
      set(state, `navigation`, {
        pluginName,
        pageName,
        args: args ? args.join(',') : '',
        key: uuid(),
      });
    },
  },
  extraReducers(builder) {
    const fulfill = (funcName, cb) => builder.addCase(funcName.fulfilled, cb);
    [infoGrowl, errorGrowl, successGrowl].map((thunk) => {
      fulfill(thunk, (state, { payload: { growl } }) => {
        update(state, `growls.${growl.groupId}`, (groupGrowls = []) => [...groupGrowls, growl]);
      });
    });
  },
});
export const {
  dismissAllGrowls,
  setConfig,
  dismissGrowl,
  navigateToChildPage,
  navigateToParentPage,
  navigateToPluginPage,
  showSnackbar,
  hideSnackbar,
} = actions;
export default reducer;
