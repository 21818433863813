import axios from 'axios';
import log from '../../logger';
const baseUrl = '/user-api';

export const loadRecords = async ({
  sort: { sortField, order },
  groupName,
  groupId,
  additionalFieldsSearch,
  ...other
}) => {
  const condition = additionalFieldsSearch.inGroup === false ? '!=' : '=';
  const logic = {
    operator: 'AND',
    rules: [
      {
        field: 'Group',
        data: [
          {
            label: groupName.toString(),
            id: Number.parseInt(groupId),
            logicParams: { condition, fieldLogicType: 'memberStatus' },
            hasSetDefaultValue: true,
          },
        ],
        condition,
      },
    ],
  };

  let result;
  try {
    const { data } = await axios.post(`${baseUrl}/getAllUsersFromLogic`, {
      ...other,
      order,
      sortReverse: sortField ? order === 'DESC' : undefined,
      sortField,
      limit: 10,
      logic,
      timeout: 10_000,
    });
    const { appUsers: records, count: numRecords, offset } = data;
    result = { records, numRecords, offset, logic };
  } catch (e) {
    log.error(e);
    throw new Error('Failed to load users');
  }
  return result;
};

const addUser = async (groupId, groupKey, userId, action = 'add') => {
  try {
    const { data } = await axios.post(`${baseUrl}/updateAppUserGroups`, {
      groupId,
      groupKey,
      userId,
      action,
      timeout: 10_000,
    });
    if (data.status === 'success') {
      log.info('success');
    }
    return data;
  } catch (e) {
    const noOccupancy = e?.response?.data?.error === 'Group Has Reached Maximum Occupancy';
    if (noOccupancy) return { noOccupancy: true };
    log.error(e);
    throw new Error('Failed to remove appUser.');
  }
};

const removeUser = async (groupId, groupKey, userId, action = 'remove') => {
  try {
    const { data } = await axios.post(`${baseUrl}/updateAppUserGroups`, {
      groupId,
      groupKey,
      userId,
      action,
      timeout: 10_000,
    });
    if (data.status === 'success') {
      log.info('success');
    }
    return data;
  } catch (e) {
    log.error(e);
    throw new Error('Failed to remove appUser.');
  }
};

const manageGroupsEditApi = {
  loadRecords,
  addUser,
  removeUser,
};

export default manageGroupsEditApi;
