import React, { useMemo } from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { AppUserConstants } from '../definitions';
import useModifiedItemPersistance from '../hooks/useModifiedItemPersistance';
import AppUserEditorForm from './AppUserEditorForm';
import { CUSTOM_FIELD_PREFIX } from '../definitions';
const AppUsersEditorPage = ({ appUserId }) => {
  const { item, fields: apiFields, saveItem } = useModifiedItemPersistance(appUserId);
  const form = useCmsForm(item);
  const fields = useMemo(() => {
    if (apiFields && apiFields.length) {
      return apiFields.map((f) => {
        const {
          field,
          id,
          required,
          title,
          value,
          options = [],
          default: def,
          canRemove,
          maxLength,
        } = f;
        const fieldName = canRemove === false ? field : `${CUSTOM_FIELD_PREFIX}${id}`;
        let defaultValue;
        if (![null, undefined, ''].includes(value)) {
          defaultValue = value;
          form.setValue(fieldName, defaultValue, { shouldDirty: true, shouldTouch: true });
        } else if (![null, undefined, ''].includes(def)) {
          defaultValue = def;
          form.setValue(fieldName, defaultValue, { shouldDirty: true, shouldTouch: true });
        }

        const fieldProps = {
          formFieldOptions: { ...(required && { required: `${title} is required` }) },
          label: title,
          fieldName,
          name: fieldName,
          defaultValue,
          options: (options || []).map((o) => ({ label: o, value: o })),
          fieldWidth: 'normal',
          ...(!Number.isNaN(maxLength) && { maxLength }),
        };
        return { ...f, fieldProps };
      });
    }
    return [];
  }, [apiFields, form.setValue]);

  const actions = useItemEditActions(saveItem, form, AppUserConstants);

  return (
    <CmsPage title={`${appUserId === 'new' ? 'Add' : 'Edit'} User`}>
      <AppUserEditorForm
        appUserId={appUserId}
        form={form}
        fields={fields}
        growlId={AppUserConstants.editorPageName}
      />
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </CmsPage>
  );
};

export default AppUsersEditorPage;
