import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function GenericSelect(props) {
  const { label, variant = 'standard', size = {}, onChange, value, menuItems } = props;

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          variant={variant}
          value={value}
          onChange={onChange}
          sx={{ width: size.width || '100%', height: size.height || 'auto' }}
        >
          {menuItems.map((item, index, id) => (
            <MenuItem key={index || id} value={item.value || item.id}>
              {item.label || item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
