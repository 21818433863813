import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import useLoadListItems from '../../shared/cmsPage/hooks/useLoadListItems';
import usePublishActionHandler from '../../shared/cmsPage/hooks/usePublishActionHandler';
import { navigateToPluginPage } from '../../store/rootReducer';
import collectionApi from '../api/collectionApi';
import { CollectionConstants, CollectionEditorConstants } from '../definitions';
export default function useCollectionEditorActions(props) {
  const {
    hasItemsPendingPublish,
    item,
    loadItem,
    loadItems,
    collectionId,
    pageTitle,
    publishDisabled,
    setHasReordered,
    loaderParams,
  } = props;
  const dispatch = useDispatch();

  const editSettings = useMemo(
    () => ({
      label: 'Collection Settings',
      onClick: async () => {
        dispatch(
          navigateToPluginPage([
            CollectionConstants.pluginId,
            CollectionConstants.settingsPageName,
            item.collection_id,
          ])
        );
      },
      visible: true,
    }),
    [item, dispatch]
  );
  const reload = useLoadListItems(CollectionEditorConstants.defaultPageId, loadItems, loaderParams);
  const publishHandler = usePublishActionHandler(
    CollectionConstants.pluginId,
    CollectionConstants.typeName,
    CollectionConstants.publishPageName,
    CollectionEditorConstants.defaultPageId,
    CollectionEditorConstants.defaultPageName,
    pageTitle,
    CollectionEditorConstants.defaultPageId,
    collectionApi
  );
  const publish = useMemo(
    () => ({
      disabled: publishDisabled,
      label: 'Publish',
      onClick: async () => {
        await publishHandler(collectionId, hasItemsPendingPublish, async () => {
          setHasReordered(false);
          await Promise.all([loadItem(collectionId), reload()]);
        });
      },
      useSnapshotCheck: true,
    }),
    [
      hasItemsPendingPublish,
      publishDisabled,
      publishHandler,
      reload,
      loadItem,
      collectionId,
      setHasReordered,
    ]
  );
  return { editSettings, publish };
}
