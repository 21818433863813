import React, { useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import GrowlContainer from '../../shared/growl/components/GrowlContainer';
import { addCalendar, listAccountCalendars } from '../api/calendarListApi';

const CalendarModal = ({ accounts, onClose, growlId, insertCalendar }) => {
  const [selectedAccount, setSelectedAccount] = useState('');
  const [allCalendars, setAllCalendars] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [selectedCalendarName, setSelectedCalendarName] = useState('');
  const [replaceMode] = useState(false);
  const growlRef = useRef(null);

  // Function to handle account change
  const changeAccount = async (id) => {
    // Fetch or update calendars based on selectedAccount
    const acctCalendars = await listAccountCalendars(id);
    setAllCalendars(acctCalendars);
    setSelectedCalendar(null);
    setSelectedCalendarName('');
  };

  // Function to handle cancel
  const cancel = () => {
    // Reset state or perform other actions
    onClose();
  };

  // Function to handle save
  const save = async (stayOpen = false) => {
    // Perform save logic
    const data = {
      accountId: selectedAccount,
      calendarId: selectedCalendar ? selectedCalendar.id : null,
      name: selectedCalendarName,
    };
    const newCalendar = await addCalendar(data);
    if (newCalendar) {
      insertCalendar(newCalendar);
    }
    if (!stayOpen) {
      onClose();
    }
  };

  // Sorting function for accounts and calendars
  const sortByName = (a, b) => a.name.localeCompare(b.name);
  const sortBySummary = (a, b) => a.summary.localeCompare(b.summary);

  return (
    <Modal show={true} animation={false}>
      <GrowlContainer groupId={growlId} />
      <div className="module red">
        {/* Modal Header */}
        <div className="modal-header module-header">
          <button type="button" className="btn btn-default close" onClick={onClose}>
            <i className="fa fa-times"></i>
          </button>
          <span className="modal-title">Add a Calendar</span>
        </div>
        {/* Modal Body */}
        <div className="modal-body module-body left-margin-15">
          {/* Notification component or placeholder */}
          <div id="calendarModalGrowl" ref={growlRef}></div>
          {/* Form */}
          <form name="calendarDialog" role="form">
            {/* Account Selection */}
            <div className="row no-bottom-margin">
              <div className="col-xs-12">
                <div className="form-horizontal">
                  <div className="form-group">
                    <label htmlFor="account" className="col-sm-3 control-label">
                      Accounts *
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        name="account"
                        id="account"
                        value={selectedAccount}
                        onChange={(e) => {
                          setSelectedAccount(e.target.value);
                          changeAccount(e.target.value);
                        }}
                        required
                      >
                        {!selectedAccount && <option value="">Select an Account...</option>}
                        {accounts &&
                          accounts.sort(sortByName).map((account) => (
                            <option key={account.id} value={account.id}>
                              {account.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Calendar Selection */}
            <div className="row no-bottom-margin">
              <div className="col-xs-12">
                <div className="form-horizontal">
                  <div className="form-group">
                    <label htmlFor="calendar" className="col-sm-4 control-label">
                      Available Calendars *
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        name="calendar"
                        id="calendar"
                        value={selectedCalendar ? selectedCalendar.id : ''}
                        onChange={(e) => {
                          const calendarId = e.target.value;
                          const calendar = allCalendars.find((c) => c.id === calendarId);
                          setSelectedCalendar(calendar);
                          setSelectedCalendarName(calendar ? calendar.summary : '');
                        }}
                        disabled={!allCalendars || allCalendars.length === 0}
                        required
                      >
                        {!selectedCalendar && <option value="">Select a Calendar...</option>}
                        {allCalendars &&
                          allCalendars.sort(sortBySummary).map((calendar) => (
                            <option key={calendar.id} value={calendar.id}>
                              {calendar.summary}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Calendar Name */}
            <div className="row no-bottom-margin">
              <div className="col-xs-12">
                <div className="form-horizontal">
                  <div className="form-group">
                    <label htmlFor="calendarName" className="col-sm-3 control-label">
                      Calendar Name *
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        required
                        disabled={!selectedCalendar}
                        value={selectedCalendarName}
                        onChange={(e) => setSelectedCalendarName(e.target.value)}
                        id="calendarName"
                        name="calendarName"
                        placeholder="Calendar Name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* Modal Footer */}
        <div className="modal-footer">
          <div className="text-center">
            <button type="button" className="btn" onClick={cancel}>
              Cancel
            </button>
            {!replaceMode && (
              <button type="button" className="btn btn-primary" onClick={() => save(true)}>
                Save
              </button>
            )}
            <button type="button" className="btn btn-primary" onClick={() => save()}>
              Save and Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CalendarModal;
