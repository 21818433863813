import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { getColumnSortOrder } from '../itemTableUtils';
import ItemTableHeaderCell from './ItemTableHeaderCell';
import ItemTableRow from './ItemTableRow';
import PaginationControl from './PaginationControl';
import ApplyModal from './ApplyModal';

const ItemTable = ({
  NestedComponent = null,
  applyCallback = async () => {},
  applyModalButtonText = 'Apply',
  applyModalCancelButtonText = 'Cancel',
  applyModalText = 'Are you sure you want to apply the selected items?',
  applyModalTitle = 'Apply Items',
  applyText = 'Apply',
  cancelText = 'Cancel',
  clientSort = false,
  columns,
  isSelectable = false,
  itemActions,
  itemSort = (i) => i,
  items = [],
  loaderParams,
  onPageSelected,
  onSortChange,
  pagination,
  reload,
  selectableText = 'Select',
  setSelectedCallback = () => {},
  showTotal,
  sort,
  style = {},
  tableFooter,
  disableSelect = () => false,
  tableRowCustomClass = () => '',
  useApplyModal = false,
}) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [clientSortConfig, setClientSortConfig] = useState({ key: null, direction: 'ASC' });
  const [isInSelectionMode, setIsInSelectionMode] = useState(false);
  const [selectedRows, setSelectedRowsRoot] = useState([]);
  const [openApplyModal, setOpenApplyModal] = useState(false);
  const setSelectedRows = (rows) => {
    setSelectedRowsRoot(rows);
    setSelectedCallback(rows);
  };

  const onColumnClick = useCallback(
    (column) => {
      const sortField = column.valueField;
      if (clientSort) {
        setClientSortConfig((prevConfig) => ({
          key: sortField,
          direction:
            prevConfig.key === sortField && prevConfig.direction === 'ASC' ? 'DESC' : 'ASC',
        }));
      } else {
        const order = getColumnSortOrder(column, sort);
        const newOrder = order === 'ASC' ? 'DESC' : 'ASC';
        onSortChange({ order: newOrder, sortField: sortField });
      }
    },
    [clientSort, sort, onSortChange]
  );

  const isNumeric = (value) => {
    return !Number.isNaN(Number.parseFloat(value)) && Number.isFinite(value);
  };

  const compareValues = (a, b) => {
    if (isNumeric(a) && isNumeric(b)) {
      return Number.parseFloat(a) - Number.parseFloat(b);
    }
    return String(a).localeCompare(String(b), undefined, { numeric: true, sensitivity: 'base' });
  };

  const sortedItems = useMemo(() => {
    if (!clientSort) return itemSort(items);

    return [...items].sort((a, b) => {
      if (clientSortConfig.key === null) return 0;
      const aValue = a[clientSortConfig.key];
      const bValue = b[clientSortConfig.key];
      const comparison = compareValues(aValue, bValue);
      return clientSortConfig.direction === 'ASC' ? comparison : -comparison;
    });
  }, [clientSort, items, itemSort, clientSortConfig]);

  const { offset = 0, numRecords: total = 0, limit = 1 } = pagination || {};
  const min = offset + 1;
  const max = Math.min(total, min + limit - 1);

  const isExpanded = (index) => index === expandedRow;

  return (
    <>
      <Box style={style}>
        <table className="table table-striped no-margin" style={{ marginBottom: '0px !important' }}>
          <thead>
            <tr>
              {!!(isSelectable && isInSelectionMode) && <th />}
              {NestedComponent && <ItemTableHeaderCell sortOrder={null} column={{}} />}
              {columns.map((column, index) => (
                <ItemTableHeaderCell
                  key={index}
                  column={column}
                  onClick={() => onColumnClick(column)}
                  sortOrder={
                    clientSort
                      ? clientSortConfig.key === column.valueField
                        ? clientSortConfig.direction
                        : 'NONE'
                      : getColumnSortOrder(column, sort)
                  }
                />
              ))}
              {itemActions?.length > 0 && <th />}
            </tr>
          </thead>
          {sortedItems.length > 0 ? (
            <tbody>
              {sortedItems.map((item, i) => (
                <React.Fragment key={i}>
                  <tr
                    className={`${tableRowCustomClass(item)} ${
                      isExpanded(i) ? 'selected-row' : ''
                    }`}
                  >
                    {isSelectable && isInSelectionMode && (
                      <td>
                        <input
                          type="checkbox"
                          disabled={disableSelect(item)}
                          checked={selectedRows.includes(item)}
                          onChange={() => {
                            const newSelectedRows = selectedRows.includes(item)
                              ? selectedRows.filter((i) => i !== item)
                              : [...selectedRows, item];
                            setSelectedRows(newSelectedRows);
                            setSelectedCallback(newSelectedRows);
                          }}
                        />
                      </td>
                    )}
                    {NestedComponent && (
                      <td
                        onClick={() => {
                          i === expandedRow ? setExpandedRow(null) : setExpandedRow(i);
                        }}
                      >
                        {isExpanded(i) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </td>
                    )}
                    <ItemTableRow item={item} columns={columns} itemActions={itemActions} />
                  </tr>
                  {isExpanded(i) && (
                    <tr>
                      <td
                        colSpan={
                          columns.length +
                          (isSelectable && isInSelectionMode ? 1 : 0) +
                          (NestedComponent ? 1 : 0) +
                          (itemActions?.length > 0 ? 1 : 0)
                        }
                      >
                        <NestedComponent
                          parentItem={item}
                          reloadParent={reload}
                          additionalFieldsSearch={loaderParams?.additionalFieldsSearch || {}}
                        />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td
                  className="table-message"
                  colSpan={
                    columns.length +
                    (isSelectable && isInSelectionMode ? 1 : 0) +
                    (NestedComponent ? 1 : 0) +
                    (itemActions?.length > 0 ? 1 : 0)
                  }
                >
                  {`There are no items that match your criteria.`}
                </td>
              </tr>
            </tbody>
          )}
        </table>
        {!clientSort && (
          <Box style={{ display: 'flex', width: '100%', alignItems: 'center', marginTop: '.5rem' }}>
            <Box style={{ flex: 1, textAlign: 'left' }}>{tableFooter?.left || ''}</Box>
            <Box
              style={{
                flexGrow: 0,
                flexShrink: 1,
                flexBasis: 'auto',
                textAlign: 'center',
                margin: '0 auto',
              }}
            >
              {showTotal && (
                <span style={{ paddingTop: '0.75em', display: 'inline-block' }}>
                  {` Showing ${min} - ${max} of `} <strong>{total}</strong>
                </span>
              )}
              <PaginationControl {...pagination} onPageSelected={onPageSelected} />
            </Box>
            <Box style={{ flex: 1, textAlign: 'right' }}>{tableFooter?.right || ''}</Box>
          </Box>
        )}
      </Box>
      <Box sx={{ mt: '1.5rem' }}>
        {isSelectable &&
          (isInSelectionMode ? (
            <>
              <Button
                sx={{ mr: '2rem' }}
                variant="contained"
                disabled={!selectedRows.length}
                onClick={async () => {
                  if (useApplyModal) {
                    setOpenApplyModal(true);
                  } else {
                    await applyCallback(selectedRows);
                    setIsInSelectionMode(false);
                    setSelectedRows([]);
                  }
                }}
              >
                {applyText}
              </Button>
              <Button
                color="inherit"
                onClick={() => {
                  setIsInSelectionMode(false);
                  setSelectedRows([]);
                }}
              >
                {cancelText}
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              disabled={!items.length}
              onClick={() => setIsInSelectionMode(true)}
            >
              {selectableText}
            </Button>
          ))}
      </Box>
      <ApplyModal
        applyCallback={applyCallback}
        applyModalButtonText={applyModalButtonText}
        applyModalCancelButtonText={applyModalCancelButtonText}
        applyModalText={applyModalText}
        applyModalTitle={applyModalTitle}
        open={openApplyModal}
        selectedRows={selectedRows}
        setIsInSelectionMode={setIsInSelectionMode}
        setOpen={setOpenApplyModal}
        setSelectedRows={setSelectedRows}
      />
    </>
  );
};

export default ItemTable;
