import React, { useMemo } from 'react';
import { Typography, TextField, MenuItem } from '@mui/material';
import { capitalize, upperCase } from 'lodash';
const types = ['student', 'instructor', 'assistant'];
const UserTypeSelector = ({ enrollments, userType, onChange }) => {
  const isFullMap = useMemo(
    () =>
      enrollments
        .filter((e) => e.attendeeType)
        .reduce(
          (a, e) => ({
            ...a,
            [e?.attendeeType?.toLowerCase()]: (e?.totalEnrolled || 0) >= (e?.maxSeats || 0),
          }),
          {}
        ),
    [enrollments]
  );

  return (
    <>
      <Typography variant="body2">
        What type of user would you like to enroll in this session?
      </Typography>
      <TextField
        select
        label="User to Enroll"
        value={userType}
        onChange={onChange}
        fullWidth
        variant="standard"
      >
        <MenuItem value="">
          <em>Select user type</em>
        </MenuItem>
        {types.map((t) => {
          const atCapacity = isFullMap[t] || isFullMap[t] === undefined;
          return (
            <MenuItem key={t} value={upperCase(t)} disabled={atCapacity}>
              {`${capitalize(t)}${atCapacity ? ' (at capacity)' : ''}`}
            </MenuItem>
          );
        })}
      </TextField>
    </>
  );
};

export default UserTypeSelector;
