import React from 'react';
import FormCheckBoxGroup from './components/FormCheckBoxGroup';
import FormDateOnly from './components/FormDateOnly';
import FormInput from './components/FormInput';
import FormInputTextArea from './components/FormInputTextArea';
import RadioButtonGroup from './components/FormRadioButtonGroup';
import FormSwitch from './components/FormSwitch';
import MultiSelect from './components/MultiSelect';

const FormWrapper = ({ wrapForm = false, children }) =>
  wrapForm ? (
    <form role="form" className="no-left-padding">
      {children}
    </form>
  ) : (
    children
  );

const DynamicFormFields = ({ form, fields = [], wrapForm }) => (
  <FormWrapper wrapForm={wrapForm}>
    <div>
      {(fields || []).map(({ field, title, type, fieldProps = {} }, i) => {
        const key = `${field}-${title}-${i}`;
        fieldProps.formContext = form;
        fieldProps.form = form;
        switch (type) {
          case 'header': {
            return <h3 key={key}>{title}</h3>;
          }
          case 'text':
          case 'email':
          case 'phone':
          case 'integer':
          case 'number': {
            return <FormInput key={key} {...fieldProps} type={type} />;
          }
          case 'checkbox': {
            return <FormCheckBoxGroup key={key} {...fieldProps} />;
          }
          case 'textarea': {
            return <FormInputTextArea key={key} {...fieldProps} />;
          }
          case 'date': {
            return <FormDateOnly key={key} {...fieldProps} />;
          }
          case 'switch': {
            return <FormSwitch switchLabel={title} key={key} {...fieldProps} />;
          }
          case 'radio': {
            return <RadioButtonGroup key={key} {...fieldProps} />;
          }

          case 'select': {
            if (Array.isArray(fieldProps.defaultValue))
              fieldProps.defaultValue = fieldProps.defaultValue[0];
            return (
              <MultiSelect
                key={key}
                isSingleSelect
                labelKey="label"
                placeholder={'Select'}
                valueKey="value"
                findValueInOptions
                {...fieldProps}
              />
            );
          }
          default: {
            return null;
          }
        }
      })}
    </div>
  </FormWrapper>
);

export default DynamicFormFields;
