import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import { navigateToPluginPage } from '../../store/rootReducer';
import itrsApi from '../api/itrsApi';
import { ItrsCourseConstants } from '../definitions';
import { BackToCourses } from './BackButtons';
import SessionTable from './SessionTable';

const ItemView = ({ title, value, render }) => {
  return (
    <li style={{ marginBottom: 10 }}>
      <Typography style={{ fontWeight: 'bold' }}>{title}:</Typography>
      {render ? (
        <Typography dangerouslySetInnerHTML={{ __html: value }} style={{ marginLeft: 15 }} />
      ) : (
        <Typography style={{ marginLeft: 10 }}>{value}</Typography>
      )}
    </li>
  );
};

const { loadRecord, removeRecord } = itrsApi;

const handleEdit = (courseId, dispatch) => () => {
  dispatch(
    navigateToPluginPage([ItrsCourseConstants.pluginId, ItrsCourseConstants.editorPageId, courseId])
  );
};

const handleDelete = (courseId, dispatch) => async () => {
  await removeRecord(courseId);
  dispatch(navigateToPluginPage([ItrsCourseConstants.pluginId, ItrsCourseConstants.coursePageId]));
};

const ItrsCourseViewPage = ({ courseId }) => {
  const [course, setCourse] = useState(null);
  const [categories, setCategories] = useState(null);
  const [programs, setPrograms] = useState(null);

  useEffect(() => {
    const getCats = async () => itrsApi.getCategories();
    const getPrgs = async () => itrsApi.getPrograms();
    Promise.all([getCats(), getPrgs()]).then(([cats, prgs]) => {
      setCategories(cats);
      setPrograms(prgs);
    });
  }, [courseId]);

  useEffect(() => {
    const setRecords = async () => {
      const course = await loadRecord(courseId);
      setCourse(course);
    };
    if (courseId) setRecords();
  }, [courseId]);

  const dispatch = useDispatch();

  if (!course) {
    return <div>Loading...</div>;
  }

  const capitalize = (str) => {
    if (typeof str !== 'string' || !str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const category = _.get(
    categories?.find((cat) => cat.value === course.category),
    'label',
    ''
  );

  const program = course?.program?.map
    ? course.program
        .map((p) => {
          const program = _.get(
            programs?.find((prg) => prg.value === p),
            'label',
            ''
          );
          return program;
        })
        .join(', ')
    : course.program;

  return (
    <>
      <BackToCourses />
      <CmsPage>
        <div style={{ marginTop: '-30px' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
            <Typography style={{ fontWeight: 'bold', marginRight: 12, fontSize: 26 }}>
              {course.name}
            </Typography>

            <IconButton
              onClick={handleEdit(courseId, dispatch)}
              sx={{
                backgroundColor: '#1A76D2',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#1565C0',
                },
                borderRadius: '50%',
              }}
              size="small"
            >
              <EditIcon style={{ fontSize: '16px' }} />
            </IconButton>
            <IconButton
              onClick={handleDelete(courseId, dispatch)}
              disabled={!!course?.sessions?.length}
              sx={{
                backgroundColor: '#1A76D2',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#1565C0',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#93b2c2',
                  color: '#FFFFFF80',
                },
                borderRadius: '50%',
                ml: 1,
              }}
              size="small"
            >
              <DeleteIcon style={{ fontSize: '16px' }} />
            </IconButton>
          </div>

          <ul style={{ paddingLeft: 0 }}>
            <ItemView title="Course Name" value={course.name} />
            <ItemView title="Course Status" value={capitalize(course.status)} />
            <ItemView title="Course Description" value={course.description} render={true} />
            <ItemView title="Course Summary" value={course.summary} render={true} />
            <ItemView title="Duration" value={course.duration} />
            <ItemView title="Category / Department" value={category} />
            <ItemView title="Program(s)" value={program} />
            <ItemView title="Enrollment Approval" value="Unknown" />
            <ItemView title="Enrollment Cut-Off" value={course.enrollmentCutOff} />
            <ItemView title="Course earns a Certificate" value={capitalize(course.certificate)} />
            <ItemView
              title="Course earns Continuing Education Credit (CEU)"
              value={capitalize(course.ceu)}
            />
            <ItemView title="Prerequisite(s)" value={course.prereqs} render={true} />
          </ul>
          <div style={{ width: '100%', backgroundColor: '#345784', padding: 5, marginBottom: 10 }}>
            <Typography style={{ color: 'white', fontWeight: 'bold', marginLeft: 15 }}>
              Sessions
            </Typography>
          </div>
          <SessionTable courseId={course.id} />
        </div>
      </CmsPage>
    </>
  );
};

export default ItrsCourseViewPage;
