(function () {
  angular
    .module('cmsAdmin', [
      'ui.router',
      'ui.bootstrap',
      'ui.select',
      'ngFileUpload',
      'ngAnimate',
      'ngMessages',
      'http-interceptor',
      'common-directives',
      'input-directives',
      'common-filters',
      'social-inputs-directives',
      'angular-growl',
      'field-error-service',
      'root',
      'templates',
      'config-service',
      'message-service',
      'navigation-service',
      'manage-content',
      'manage-groups',
      'view-group',
      'edit-group',
      'edit-group-members',
      'manage-subtenants',
      'manage-admins',
      'head-controller',
      'sidebar-controller',
      'tenant-selector',
      'constants',
      'auth',
      'oc.lazyLoad',
      'plugin-service',
      'plugin-wrapper',
      'angularUUID2',
      'usa-state-service',
      'angular-confirm',
      'ui.bootstrap.showErrors',
      'data-record-service',
      'dndLists',
      'angular-multi-select',
      'angularMoment',
      'datePicker',
      'colorpicker.module',
      'import-module',
      'ngTagsInput',
      'patch-http',
      'react-components',
    ])
    .config([
      'stConfig',
      function (stConfig) {
        stConfig.pagination.template = 'modules/manageContent/manageContentPaginationTemplate.html';
        stConfig.sort.delay = 0;
      },
    ])
    .config([
      'growlProvider',
      function (growlProvider) {
        growlProvider.globalTimeToLive(5000);
        growlProvider.globalInlineMessages(true);
      },
    ])
    .run([
      'Upload',
      function (Upload) {
        // Fix for breaking change in ng-file-upload.  Setting arrayKey to ''
        // preserves previous behavior of uploading multiple files with the same field
        // using the fieldName vs fieldName[0], fieldName[1], etc.
        const oldUpload = Upload.upload;
        Upload.upload = (params) => {
          return oldUpload({ ...params, arrayKey: '' });
        };
      },
    ])
    .run([
      '$templateCache',
      function ($templateCache) {
        $templateCache.put(
          'templates/growl/growl.html',
          '<div class="growl-container" ng-class="wrapperClasses()">' +
            '<div offset="120" container-id="{{message.referenceId}}" class="growl-item alert" ng-repeat="message in growlMessages.directives[referenceId].messages" ng-class="alertClasses(message)" ng-click="stopTimeoutClose(message)">' +
            '<button type="button" class="close" data-dismiss="alert" aria-hidden="true" ng-click="growlMessages.deleteMessage(message)" ng-show="!message.disableCloseButton">&times;</button>' +
            '<button type="button" class="close" aria-hidden="true" ng-show="showCountDown(message)">{{message.countdown}}</button>' +
            '<h4 class="growl-title" ng-show="message.title" ng-bind="message.title"></h4>' +
            '<div class="growl-message" ng-bind-html="message.text"></div>' +
            '</div>' +
            '</div>'
        );
      },
    ])
    .config([
      '$stateProvider',
      '$urlRouterProvider',
      '$locationProvider',
      function ($stateProvider, $urlRouterProvider, $locationProvider) {
        $urlRouterProvider.otherwise('/main/');

        $stateProvider
          .state('main', {
            url: '/main',
            abstract: true,
            views: {
              '': {
                templateUrl: 'modules/root/root.html',
                controller: 'RootCtrl',
              },
              'sidebar@main': {
                templateUrl: 'modules/sidebar/sidebar.html',
                controller: 'SideBarCtrl',
              },
            },
            resolve: {
              pluginServicePromise: [
                'pluginService',
                function (pluginService) {
                  return pluginService.initialize();
                },
              ],
            },
          })
          .state('main.root', {
            url: '/',
            templateUrl: 'modules/root/rootContent.html',
            controller: 'RootInitCtrl',
          })
          .state('main.manage-admins', {
            url: '/manage-admins',
            templateUrl: 'modules/manageAdmins/manageAdmins.html',
            controller: 'ManageAdminsCtrl',
          })
          .state('main.manage-subtenants', {
            url: '/manage-subtenants',
            templateUrl: 'modules/manageSubtenants/manageSubtenants.html',
            controller: 'ManageSubtenantsCtrl',
          })
          .state('main.manage-assets', {
            url: '/manage-assets',
            templateUrl: 'modules/manageContent/manageAssets.html',
            controller: 'ManageAssetsCtrl',
          })
          .state('main.manage-groups', {
            url: '/manage-groups',
            templateUrl: 'modules/manageGroups/manageGroups.html',
            controller: 'ManageGroupsCtrl',
          })
          .state('main.view-group', {
            url: '/manage-groups/view-group',
            templateUrl: 'modules/manageGroups/viewGroup/viewGroupModal.html',
            controller: 'ViewGroupCtrl',
          })
          .state('main.edit-group', {
            url: '/manage-groups/edit-group',
            templateUrl: 'modules/manageGroups/editGroup/editGroupModal.html',
            controller: 'EditGroupCtrl',
          })
          .state('main.edit-group-members', {
            url: '/manage-groups/edit-group-members',
            templateUrl: 'modules/manageGroups/editGroupMembers/editGroupMembers.html',
            controller: 'EditGroupMembersCtrl',
          })
          .state('main.new-css', {
            url: '/new-css',
            templateUrl: 'modules/manageContent/cssPlainEdit.html',
            controller: 'newPlainEditFileController',
            data: {
              defaultEditable: 1,
            },
          })
          .state('main.edit-css', {
            url: '/edit-css/:assetId/:editable',
            templateUrl: 'modules/manageContent/cssPlainEdit.html',
            controller: 'newPlainEditFileController',
          })
          .state('main.new-js', {
            url: '/new-js',
            templateUrl: 'modules/manageContent/jsPlainEdit.html',
            controller: 'newPlainEditFileController',
            data: {
              defaultEditable: 1,
            },
          })
          .state('main.edit-js', {
            url: '/edit-js/:assetId/:editable',
            templateUrl: 'modules/manageContent/jsPlainEdit.html',
            controller: 'newPlainEditFileController',
          })
          .state('main.new-html', {
            url: '/new-html',
            templateUrl: 'modules/manageContent/htmlPlainEdit.html',
            controller: 'newPlainEditFileController',
            data: {
              defaultEditable: 1,
            },
          })
          .state('main.edit-html', {
            url: '/edit-html/:assetId/:editable',
            templateUrl: 'modules/manageContent/htmlPlainEdit.html',
            controller: 'newPlainEditFileController',
          })
          .state('main.new-wysiwyg', {
            url: '/new-wysiwyg',
            templateUrl: 'modules/manageContent/wysiwygEditPage.html',
            controller: 'newWysiwygHtmlFileController',
            data: {
              defaultEditable: 1,
            },
            resolve: {
              wysiwygParams: [
                'wysiwygConfigService',
                function (wysiwygConfigService) {
                  return wysiwygConfigService.getWysiwygOptions();
                },
              ],
            },
          })
          .state('main.edit-wysiwyg', {
            url: '/edit-wysiwyg/:assetId/:editable',
            templateUrl: 'modules/manageContent/wysiwygEditPage.html',
            controller: 'newWysiwygHtmlFileController',
            resolve: {
              wysiwygParams: [
                'wysiwygConfigService',
                function (wysiwygConfigService) {
                  return wysiwygConfigService.getWysiwygOptions();
                },
              ],
            },
          })
          .state('main.new-json', {
            url: '/new-json',
            templateUrl: 'modules/manageContent/jsonPlainEdit.html',
            controller: 'newPlainEditFileController',
            data: {
              defaultEditable: 1,
            },
          })
          .state('main.edit-json', {
            url: '/edit-json/:assetId/:editable',
            templateUrl: 'modules/manageContent/jsonPlainEdit.html',
            controller: 'newPlainEditFileController',
          })
          .state('main.manage-versions', {
            url: '/manage-versions',
            templateUrl: 'modules/manageContent/manageVersions.html',
          })
          /*
          .state('main.push-notifications', {
            url: '/push-notifications',
            templateUrl: 'modules/push/pushNotification.html',
            controller: 'newPushNotificationController',
          })
          */
          .state('main.software-update', {
            url: '/softwareUpdate',
            templateUrl: 'modules/softwareUpdate/softwareUpdate.html',
          })
          .state('main.plugin', {
            url: '/plugin/:pluginName/:pageName/:params',
            templateUrl: 'modules/plugin/pluginWrapper.html',
            controller: 'pluginWrapperController',
          });

        $locationProvider.html5Mode(true); //Use html5Mode so your angular routes don't have #/route
      },
    ]);
})();
