import React, { useCallback, useContext } from 'react';
import { CmsContext } from '../../../context/definitions';
import FormInput from './FormInput';

const LinkFinder = (props) => {
  const { label, fieldName, formContext, required, allowEmbeddedMenus, disabled } = props;
  const formFieldOptions = required
    ? {
        required: 'This field is required.',
      }
    : {};
  const { showLinkSearch } = useContext(CmsContext);

  const findLink = useCallback(async () => {
    const currentValue = formContext.getValues()[fieldName];
    try {
      const { returnVal } = await showLinkSearch(currentValue, allowEmbeddedMenus);
      if (returnVal !== undefined) {
        formContext.setValue(fieldName, returnVal, {
          shouldDirty: !!returnVal,
          shouldTouch: true,
          shouldValidate: true,
        });
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error('Error occurred during link search:', error);
    }
  }, [formContext, fieldName, showLinkSearch, allowEmbeddedMenus]);
  return (
    <div className="link-finder">
      <FormInput
        fieldWidth={500}
        label={label}
        fieldName={fieldName}
        formContext={formContext}
        formFieldOptions={formFieldOptions}
        disabled={disabled}
      />
      <i className="fa fa-search clickable" onClick={findLink} />
    </div>
  );
};

export default LinkFinder;
