import React, { useState } from 'react';
import DenialModal from './DenialModal.js';

const Denial = ({
  appContext,
  approvalStatus,
  handleApproval,
  handleDeny,
  openModal,
  setAppContext,
  setOpenModal,
}) => {
  const [denialReason, setDenialReason] = useState('');

  const handleClose = () => {
    setOpenModal(false);
    setDenialReason('');
    setAppContext({});
  };

  const handleConfirm = () => {
    approvalStatus === 'APPROVED'
      ? handleApproval(appContext)
      : handleDeny(appContext, denialReason);
    setOpenModal(false);
    setDenialReason('');
    setAppContext({});
  };
  const approved = approvalStatus === 'APPROVED';
  return (
    <DenialModal
      open={openModal}
      handleClose={handleClose}
      title={approved ? 'Approve Request?' : 'Deny Request?'}
      description={
        approved
          ? 'Are you sure you want to approve this training request?'
          : 'Are you sure you want to deny this training request? if so, enter a reason for denial.'
      }
      hasInput={approved ? false : true}
      inputLabel={approved ? '' : 'Reason for Denial'}
      actionTitle={approved ? 'Approve Request' : 'Deny Request'}
      handleSave={handleConfirm}
      handleChange={(e) => setDenialReason(e.target.value)}
    />
  );
};

export default Denial;
