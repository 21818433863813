import countBy from 'lodash/countBy';
import get from 'lodash/get';
import has from 'lodash/has';
import map from 'lodash/map';
import omitBy from 'lodash/omitBy';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import uniqWith from 'lodash/uniqWith';
import update from 'lodash/update';
import Papa from 'papaparse';
import { useCallback, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { compressCsvJsonCollection } from '../csvUtils';

const indexToColName = (idx) => {
  let col = '';
  while (idx >= 0) {
    const remainder = idx % 26;
    col = String.fromCharCode(65 + remainder) + col; //eslint-disable-line
    idx = Math.floor(idx / 26) - 1;
  }
  return col;
};

const emailIsValid = (email) => /^[\w%+.-]+('[\w%+.-]+)*@[\d.A-Za-z-]+\.[A-Za-z]{2,}$/.test(email);

export default function useCsvReader(opts = {}) {
  const {
    allowEmpty,
    fieldValidators = {},
    ignoreNoHeaderColumns,
    mapHeaders,
    requiredHeaders,
    shouldParseColumnsAsJson,
    transformResults,
    transformRow,
    uniqueFields,
    uniqueHeaders,
    postUniqueFilters,
    validateData,
  } = opts;
  const [fileData, setFileData] = useState([]);
  const [compressedFileData, setCompressedFileData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [hasErrors, setHasErrors] = useState(false);
  const [processedFile, setProcessedFile] = useState(null);
  const processFile = useCallback(
    (f, event) => {
      const fileToProcess = f;
      let shouldSetHasErrors = false;
      let foundErrors = [];
      const colErrors = {};
      if (fileToProcess) {
        Papa.parse(fileToProcess, {
          skipEmptyLines: 'greedy',
          ...opts,
          header: true,
          ...(ignoreNoHeaderColumns && {
            transformHeader: (h, idx) => (h.trim() ? h : `REMOVE_HEADER_${idx}`),
          }),

          complete: (r) => {
            setProcessedFile(fileToProcess);
            foundErrors = get(r, 'errors', []);
            if (shouldParseColumnsAsJson) {
              r.data = r.data.map((row) => {
                shouldParseColumnsAsJson.forEach((colToParse) => {
                  let extractValueKey;
                  if (typeof colToParse === 'object') {
                    [colToParse, extractValueKey] = Object.entries(colToParse)[0];
                  }
                  if (has(row, colToParse)) {
                    try {
                      const parsedData = JSON.parse(row[colToParse]);
                      row[colToParse] = extractValueKey ? parsedData[extractValueKey] : parsedData;
                    } catch {
                      // do nothing
                    }
                  }
                });
                return row;
              });
            }
            const data = get(r, 'data', []);
            const headers = get(r, 'meta.fields', []);
            const fieldMap = headers.reduce((a, f, i) => ({ ...a, [f]: indexToColName(i) }), {});
            const addErrors = (errors) => {
              foundErrors.push(...(Array.isArray(errors) ? errors : [errors]));
              shouldSetHasErrors = true;
            };
            if (foundErrors.length > 0) shouldSetHasErrors = true;
            const fieldValidEntries = Object.entries(fieldValidators || {});
            const validateFields = fieldValidEntries.length > 0;
            const enforceUniqueness = uniqueFields && uniqueFields.length > 0;
            if (uniqueHeaders) {
              const duplicateHeaders = map(countBy(headers), (count, header) =>
                count > 1 ? header : null
              ).filter(Boolean);
              if (duplicateHeaders.length > 0) {
                addErrors(`Duplicate headers found:\n ${duplicateHeaders.join(', ')}`);
              }
            }
            const missingHeaders = [];
            if (!shouldSetHasErrors && requiredHeaders) {
              for (const header of requiredHeaders) {
                if (!headers.includes(header)) {
                  missingHeaders.push(header);
                }
              }
            }
            if (missingHeaders.length > 0) {
              addErrors(`Missing required headers:\n ${missingHeaders.join(', ')}`);
            }

            if (!validateFields || enforceUniqueness) {
              const uniqSets = (uniqueFields || []).reduce(
                (a, field) => ({ ...a, [field]: new Map() }),
                {}
              );
              const createSetErr =
                (row, col, hideCellLocation = false) =>
                (msg, invalidVal = '') => {
                  const cellLoc = `${fieldMap[col]}${row}`;
                  const locationMessage = hideCellLocation
                    ? invalidVal
                    : `${cellLoc}${invalidVal ? `- ${invalidVal}` : ''}`;
                  update(colErrors, `${col}.${msg}`, (arr = []) => [...arr, locationMessage]);
                };

              for (const [idx, row] of (data || []).entries()) {
                if (validateFields) {
                  fieldValidEntries.forEach(([field, validator]) => {
                    const setErr = createSetErr(idx + 2, field);
                    const val = row[field];
                    if (typeof validator === 'function') {
                      const resp = validator(val);
                      if (resp && typeof resp === 'string') setErr(resp, val);
                    } else {
                      switch (validator) {
                        case 'email': {
                          if (!val) setErr(`Email cannot be blank`);
                          else if (!emailIsValid(val)) setErr(`Invalid email`, val);
                          break;
                        }
                        case 'emailOrEmpty': {
                          if (val && val.trim() && !emailIsValid(val)) setErr(`Invalid email`, val);
                          break;
                        }
                        case 'truthy': {
                          if (!(val || '').trim()) setErr(`Cannot be blank`);
                          break;
                        }
                        default:
                        //do nothing
                      }
                    }
                  });
                }

                if (enforceUniqueness) {
                  uniqueFields.forEach((field) => {
                    const val = row[field];
                    if (val) {
                      const cellLocation = `${fieldMap[field]}${idx + 2}`;
                      if (uniqSets[field].has(val)) {
                        uniqSets[field].get(val).push(cellLocation);
                      } else {
                        uniqSets[field].set(val, [cellLocation]);
                      }
                    }
                  });
                  Object.entries(uniqSets).forEach(([field, valueMap]) => {
                    valueMap.forEach((locations, val) => {
                      if (locations.length > 1) {
                        const formattedLocations = locations.join(', ');
                        const setErr = createSetErr(locations[0].match(/\d+/)[0], field, true);
                        setErr(`Duplicate values`, `${formattedLocations} - ${val}`);
                      }
                    });
                  });
                }
              }
            }
            const cellErrors = Object.entries(colErrors)
              .reduce((a, [col, msgs]) => {
                const formattedMsgs = Object.entries(msgs)
                  .map(([msg, cells]) => `${msg} - ${uniq(sortBy(cells)).join(', ')}`)
                  .join('\n');
                return [...a, `Column Name - "${col}":\n${formattedMsgs}`];
              }, [])
              .sort();
            if (cellErrors?.length > 0) addErrors(cellErrors);

            if (validateData) {
              const errors = validateData(r.data);
              if (Array.isArray(errors) && errors.length > 0) addErrors(...errors);
            }
            setErrors(foundErrors);
            setHasErrors(shouldSetHasErrors);
            let dataToSet = [];
            if (shouldSetHasErrors) {
              if (event) event.target.value = null;
            } else {
              r.data = r.data.map((row) => {
                if (ignoreNoHeaderColumns) {
                  row = omitBy(row, (v, k) => k.startsWith('REMOVE_HEADER_'));
                }
                let newRow = mapHeaders ? {} : { ...row };
                if (mapHeaders) {
                  Object.entries(row).forEach(([k, v]) => {
                    newRow[mapHeaders[k] || k] = v;
                  });
                }
                if (transformRow) newRow = transformRow(newRow);
                return newRow;
              });

              if (transformResults) r.data = transformResults(r.data);

              dataToSet = r.data;
            }
            if (postUniqueFilters) {
              dataToSet = uniqWith(dataToSet, (a, b) =>
                postUniqueFilters.every((field) => isEqual(a[field], b[field]))
              );
            }
            setFileData(dataToSet);
            setCompressedFileData(compressCsvJsonCollection(dataToSet));
          },
        });
      } else {
        setFileData([]);
        setProcessedFile(null);
      }
    },
    [setFileData, setProcessedFile, opts]
  );

  const isValid = useMemo(
    () => !hasErrors && (allowEmpty || fileData.length > 0),
    [hasErrors, fileData, allowEmpty]
  );
  const onClear = useCallback(() => {
    setFileData([]);
    setProcessedFile(null);
    setHasErrors(false);
    setErrors([]);
    setCompressedFileData([]);
  }, [setFileData, setProcessedFile, setHasErrors, setErrors, setCompressedFileData]);
  return {
    compressedFileData,
    errors,
    fileData,
    hasErrors,
    isValid,
    onClear,
    processFile,
    processedFile,
  };
}
