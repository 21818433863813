import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React from 'react';
import { useTableEditorContext } from './TableContext';
const SortIcon = ({ isAsc }) => {
  const size = '8px';
  return (
    <span
      style={{
        display: 'inline-block',
        marginLeft: `${size}`,
        verticalAlign: 'middle',
        borderLeft: `${size} solid transparent`,
        borderRight: `${size} solid transparent`,
        borderTop: `${size} solid black`,
        transform: isAsc ? 'rotate(0deg)' : 'rotate(180deg)',
      }}
    />
  );
};
export default function TableHeader() {
  const { columns, sort, requestSort, hasDragged, setHasDragged, fontSize } =
    useTableEditorContext();
  return (
    <TableHead
      sx={{
        '& .MuiTableCell-head': { fontWeight: '500', padding: '8px', backgroundColor: 'white' },
      }}
    >
      <TableRow sx={{ '& .MuiTableCell-root': { py: 0 } }}>
        {columns.map((column, idx) => {
          const { field, noSort, width, name, hidden } = column;
          const isActive = sort.key === field && !hasDragged;
          const isAsc = sort.dir === 'asc';
          return (
            <TableCell
              key={`${name}-${idx}`}
              sx={{
                width: width,
                maxWidth: width,
                fontSize,
                wordWrap: 'break-word',
                '& .MuiTableSortLabel-root .MuiSvgIcon-root': {
                  display: 'none',
                },
                ...(hidden && { display: 'none' }),
              }}
              sortDirection={sort.key === field ? sort.dir : false}
            >
              {noSort ? (
                name
              ) : (
                <TableSortLabel
                  active={sort.key === field}
                  direction={sort.key === field ? sort.dir : 'asc'}
                  onClick={() => {
                    setHasDragged(false);
                    requestSort(field);
                  }}
                  sx={() => {
                    const color = 'black';
                    return {
                      fontSize,
                      '&.Mui-active': { color, '& .MuiSvgIcon-root': { color } },
                      '&:hover': { color, '& .MuiSvgIcon-root': { color } },
                      '& .MuiSvgIcon-root': {
                        color: sort.key === field ? color : '',
                      },
                    };
                  }}
                >
                  <div
                    style={{
                      cursor: 'pointer',
                      fontSize,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {name}
                    {!noSort && isActive && <SortIcon isAsc={isAsc} />}
                  </div>
                </TableSortLabel>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
