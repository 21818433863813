import { Grid } from '@mui/material';
import { Autocomplete } from '@react-google-maps/api';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import GenericTextInput from '../../../../shared/genericInputs/GenericTextInput';
import log from '../../../../logger';
export default function AddMarker({ onAddMarker, markers = [], googleMapsApiKey }) {
  const [autocomplete, setAutocomplete] = useState(null);
  const [marker, setMarker] = useState(null);
  const [searchText, setSearchText] = useState('');
  const names = new Set(markers.map((m) => m.name));

  const onPlaceChanged = () => {
    if (autocomplete) {
      const locationInfo = autocomplete.getPlace();
      if (locationInfo?.geometry) {
        const { geometry, formatted_address, place_id, address_components: ac } = locationInfo;
        const { lat, lng } = { lat: geometry.location.lat(), lng: geometry.location.lng() };
        const id = place_id || markers?.length || Date.now();
        const name = formatted_address || ac?.[0]?.long_name || `Marker ${id}`;
        if (!names.has(name)) {
          setMarker({ id, latitude: lat, longitude: lng, name, locationInfo });
        }
      } else {
        log.error('Selected locationInfo does not have geometry.');
      }
    } else {
      log.error('Autocomplete is not loaded yet!');
    }
  };

  const handleInputChange = (event) => {
    setSearchText(event.target.value); // Update the search text value
  };

  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      justifyContent={'flex-start'}
      sx={{ marginBottom: '1rem', width: '100%' }}
    >
      <div style={{ flexGrow: '1' }}>
        <Autocomplete
          onLoad={setAutocomplete}
          onPlaceChanged={onPlaceChanged}
          apiKey={googleMapsApiKey}
        >
          <GenericTextInput
            label="Search"
            fieldWidth="normal"
            defaultValue={searchText}
            onChange={handleInputChange}
          />
        </Autocomplete>
      </div>
      <Button
        color="primary"
        style={{ justifySelf: 'flex-end', marginLeft: '8px', marginRight: '-8px' }}
        onClick={() => {
          onAddMarker({ ...marker });
          setMarker(null);
          setSearchText('');
        }}
        disabled={!marker?.name || names.has(marker?.name)}
      >
        Add Marker
      </Button>
    </Grid>
  );
}
