import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import toString from 'lodash/toString';
import React, { useMemo } from 'react';
import QRCode from 'react-qr-code';
import log from '../../logger';
import FormSwitch from '../../shared/form/components/FormSwitch';
import MultiSelect from '../../shared/form/components/MultiSelect';
import ProvidedSelect from '../../shared/form/components/ProvidedSelect';
import DynamicFormFields from '../../shared/form/DynamicFormFields';
const AppUserEditorForm = ({ form, fields = [], appUserId }) => {
  const userId = toString(form.getValues()['id']);

  const { data: groups = [] } = useQuery({
    queryKey: ['fetchAssignableGroups'],
    queryFn: async () => {
      const { data } = await axios.get('/user-api/getAllGroups');
      return (data?.groups || []).filter((g) => g.canAssign);
    },
    onError: (error) => log.error('Error Loading User Groups', error),
  });

  const { data: subtenants = [] } = useQuery({
    queryKey: ['fetchSubtenants'],
    queryFn: async () => {
      const { data } = await axios.get('/subtenant-api/getAllSubtenants');
      return data?.subtenants || [];
    },
    onError: (error) => log.error('Error Loading Subtenants', error),
  });

  const showSubtenants = useMemo(
    () => appUserId !== 'new' && subtenants.some((sub) => !sub.isDefault),
    [appUserId, subtenants]
  );

  return (
    <div className="row">
      <form role="form" className="no-left-padding">
        <DynamicFormFields form={form} fields={fields} />

        {(!userId || userId === 'new') && (
          <>
            <div style={{ height: '1rem' }} />
            <FormSwitch
              switchLabel="Send Email Invite"
              fieldName="sendInvite"
              selectedName="sendInvite"
              formContext={form}
            />
          </>
        )}

        {!!groups.length && typeof form.getValues('userGroups')?.length === 'number' && (
          <MultiSelect
            fieldName="userGroups"
            formContext={form}
            label="User Groups"
            labelKey="name"
            options={groups}
            style={{ maxWidth: '32em', width: '32em' }}
            placeholder={'Select User Groups'}
            isOptionEqualToValue={(opt, value) => {
              if (has(opt, 'id')) {
                return opt.id === value.id;
              } else if (has(opt, 'key')) {
                return opt.key === value.key;
              }
              return isEqual(opt, value);
            }}
            formFieldOptions={{
              required: false,
            }}
          />
        )}

        {showSubtenants && subtenants?.length > 0 && (
          <ProvidedSelect
            label={'Subtenant'}
            fieldName={'subtenantId'}
            fieldWidth="normal"
            fontWeight={500}
            formContext={form}
            placeholder="Choose a subtenant"
            defaultValue={1}
            value={form.getValues('subtenantId') || 1}
            selectOptions={subtenants.map((subtenant) => {
              return { label: subtenant.name, value: subtenant.id };
            })}
            selectChange={(selected) => form.setValue('subtenantId', selected.value)}
            formFieldOptions={{
              required: false,
            }}
          />
        )}

        {userId && userId !== 'new' && (
          <div style={{ display: 'flex', justifyContent: 'start', marginBottom: '1rem' }}>
            <div style={{ height: 'auto', margin: '0', maxWidth: 200 }}>
              <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={userId}
                viewBox={`0 0 256 256`}
              />
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default AppUserEditorForm;
