import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateRuleLogicParamsField } from '../../../../../../store';
import { selectAdditionalFieldsProps } from '../../../../../../store/selectors';
import DateInput, { defaultDate } from './components/DateInput';
import NumberInput from './components/NumberInput';
import SelectInput from './components/SelectInput';
import TextInput from './components/TextInput';
const AdditionalInputs = ({ location, fieldName }) => {
  const {
    additionalFields,
    currentFieldType,
    fieldKey,
    fieldOptions,
    fieldOptionsValue,
    fieldType,
    isCustomField,
    isDisabled,
    field: customField,
  } = selectAdditionalFieldsProps(fieldName, location);
  const dispatch = useDispatch();
  const setInput = useCallback(
    (field, value) => {
      let extraKeyValues;
      if (isCustomField) {
        extraKeyValues = { fieldType, value: fieldOptions ? value?.value : value, customField };
      }
      dispatch(updateRuleLogicParamsField({ field, value, location, fieldName, extraKeyValues }));
    },
    [
      location,
      dispatch,
      customField,
      fieldType,
      fieldOptions,
      fieldName,
      isCustomField,
      fieldOptionsValue,
    ]
  );

  useEffect(() => {
    if (isCustomField && fieldType === 'date' && !currentFieldType) {
      setInput(fieldKey, defaultDate);
    }
  }, [currentFieldType, fieldKey, isCustomField, fieldType]);
  return fieldOptions ? (
    <SelectInput
      options={fieldOptions.map((value) => ({ label: value, value }))}
      isDisabled={isDisabled}
      label={'Select Option'}
      fieldKey={'fieldOptionsValue'}
      setInput={setInput}
      value={fieldOptionsValue}
    />
  ) : (
    additionalFields.map((field) => {
      const { fieldKey: key, type, ruleValue: value } = field;
      const prps = { ...field, setInput, value, isDisabled, fieldName };
      const isInt = type === 'integer';
      return (
        <React.Fragment key={key}>
          {(type === 'number' || isInt) && <NumberInput {...prps} isInt={isInt} />}
          {type === 'date' && <DateInput {...prps} />}
          {type === 'select' && <SelectInput {...prps} />}
          {(type === 'text' || type === 'email') && <TextInput {...prps} />}
        </React.Fragment>
      );
    })
  );
};

export default memo(AdditionalInputs);
