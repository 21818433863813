import { useSelector } from 'react-redux';
import useArray from '../../../shared/util/hooks/useArray';
import { selectHasPrivileges } from '../../../store/rootSelectors';

const useItemActions = ({ setApprovalStatus, setAppContext, setOpenModal }) => {
  const [hasTrainingPrivileges] = useSelector(selectHasPrivileges(['itrs.training']));

  const openApprovalAction = {
    label: `Approve Request`,
    icon: 'fa-solid fa-circle-check',
    disabled: () => !hasTrainingPrivileges,
    onClick: async (context) => {
      setApprovalStatus('APPROVED');
      setAppContext(context);
      setOpenModal(true);
    },
  };
  const openDenialAction = {
    label: `Deny Request`,
    icon: 'fa-solid fa-circle-minus',
    disabled: () => !hasTrainingPrivileges,
    onClick: async (context) => {
      setApprovalStatus('DENIED');
      setAppContext(context);
      setOpenModal(true);
    },
  };

  return useArray(openApprovalAction, openDenialAction);
};

export default useItemActions;
