angular.module('navigation-service', []).service('navigationService', [
  '$state',
  '$modal',
  '$rootScope',
  'growl',
  'configService',
  'contentApiService',
  'previewService',
  function ($state, $modal, $rootScope, growl, configService, contentApiService, previewService) {
    function navigateToFirstMenuItem() {
      configService.getConfig().then(function (config) {
        for (const menuItem of config.sideMenu) {
          if (menuItem.isHomePage && menuItem.navigation) {
            navigateToMenuItem(menuItem);
            break;
          }
        }
      });
    }
    function navigateToMain() {
      $state.go('main.root');
    }
    function navigateToMenuItem(menuItem) {
      const navigation = menuItem.navigation;
      if (navigation) {
        if (navigation.state) {
          $state.go(navigation.state, navigation.stateParams);
          window.scrollTo(0, 0);
        } else if (navigation.clickHandler) {
          const clickHandler = this[navigation.clickHandler];
          if (clickHandler) clickHandler();
        } else if (navigation.href) window.location = navigation.href;
      }
    }
    function exportContent() {
      contentApiService
        .exportContent()
        .success(function (data) {
          const modalScope = $rootScope.$new();
          modalScope.title = 'Content exported';
          modalScope.message =
            'Content has been exported successfully.  Click the button below to download.';
          const modalInstance = $modal.open({
            templateUrl: 'modules/manageContent/fileDownloadModal.html',
            scope: modalScope,
            animation: false,
          });
          modalInstance.result.then(function () {
            window.open(data.packageDownloadUrl, '_self');
          });
        })
        .error(function () {
          growl.error('Content export failed.  Please try again later.');
        });
    }
    function previewApp() {
      previewService.launchPreview();
    }
    function previewAppLegacy() {
      previewService.launchPreview(null, 'legacy');
    }
    return {
      navigateToMain,
      navigateToFirstMenuItem,
      navigateToMenuItem,
      exportContent,
      previewApp,
      previewAppLegacy,
    };
  },
]);
