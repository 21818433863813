import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function BasicTextFields(props) {
  const {
    label,
    variant = 'standard',
    defaultValue = '',
    fieldWidth = 'normal',
    value,
    onChange = { onChange },
  } = props;

  const width = (() => {
    switch (fieldWidth) {
      case 'tiny': {
        return '8em';
      }
      case 'short': {
        return '16em';
      }
      case 'normal': {
        return '32em';
      }
      case 'long':
      case 'full': {
        return '100%';
      }
      default: {
        if (typeof fieldWidth === 'number') {
          return `${fieldWidth}px`;
        }
        if (![null, undefined, ''].includes(fieldWidth)) {
          return fieldWidth;
        }
      }
    }
    return '32em';
  })();

  return (
    <Box component="form" sx={{ '& > :not(style)': { m: 1, width } }} noValidate autoComplete="off">
      <TextField
        size={fieldWidth}
        defaultValue={defaultValue}
        label={label}
        value={value}
        variant={variant}
        onChange={onChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      />
    </Box>
  );
}
