import { TableRow } from '@mui/material';
import React, { Fragment, useState } from 'react';
import TableCellRemove from './TableCellRemove';
import TableCellTextField from './TableCellTextField';
import { useTableEditorContext } from './TableContext';
import TableCellDropDown from './TableCellDropDown';
import TableCellAutocomplete from './TableCellAutocomplete';

export default function TableBodyRow({ row, provided, rowIdx }) {
  const { columns } = useTableEditorContext();
  const [isChipAreaHovered, setIsChipAreaHovered] = useState(false);

  const handleChipAreaHover = (isHovered) => {
    setIsChipAreaHovered(isHovered);
  };

  return (
    <TableRow
      ref={provided.innerRef}
      sx={{
        '&:nth-of-type(odd)': { backgroundColor: 'rgb(242,242,242)' },
        '& .MuiTableCell-root': { py: 0, border: 'none' },
        '& .MuiTableCell-body': { py: '8px', pr: '15px' },
      }}
      {...(provided?.draggableProps || {})}
      {...(!isChipAreaHovered && (provided?.dragHandleProps || {}))}
    >
      {columns.map((col, colIndex) => {
        const props = {
          setCursor: row.setCusor,
          viewOnly: row.viewOnly,
          idx: rowIdx,
          colIndex: colIndex,
          cellAlign: col.cellAlign || 'left',
          error: row?.FIELD_ERRORS?.[col.field],
          cellWidth: `${col.width}%`,
          field: col.field,
          value: row[col.field],
          defaultViewValue: col.defaultViewValue,
          options: col.options || [],
          hidden: col.hidden,
          tableCellProps: col.tableCellProps || {},
          textFieldProps: col.textFieldProps || {},
          transformValue: col.transformValue,
        };
        if (col.hideIf && col.hideIf(row)) {
          props.disabled = true;
        }

        return (
          <Fragment key={col.name + colIndex}>
            {col.isRemove ? (
              <TableCellRemove rowIdx={rowIdx} confirm={col.confirm} disabled={props.disabled} />
            ) : col.inputType === 'dropdown' ? (
              <TableCellDropDown {...props} />
            ) : col.inputType === 'autocomplete' ? (
              <TableCellAutocomplete {...props} onChipAreaHover={handleChipAreaHover} />
            ) : (
              <TableCellTextField {...props} inputType={col.inputType || 'text'} />
            )}
          </Fragment>
        );
      })}
    </TableRow>
  );
}
