import React from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import manageGroupsApi from '../api/manageGroupsApi';
import { ManageGroupsConstants } from '../definitions';
import useManageGroupActions from '../hooks/useManageGroupActions';

const columns = [
  {
    initialSort: 'ASC',
    label: 'Group',
    sortable: true,
    valueField: 'name',
  },
  { label: 'Memo', sortable: true, valueField: 'subtitle' },
  { label: 'Count', sortable: false, valueField: 'count' },
  {
    label: 'Expected',
    valueField: 'cohortMemberCount',
    sortable: false,
  },
  { label: 'Max Occupancy', sortable: false, valueField: 'maximumOccupancy' },
  {
    label: 'Type',
    sortable: false,
    renderer: (item) => {
      if (item?.groupType === 'registration') {
        return `Registration`;
      } else if (item?.groupType === 'cohort' || item?.key?.includes('#cohort#')) {
        return `Cohort`;
      }
      return '';
    },
  },
];

const ManageGroupsListPage = () => {
  const { defaultPageId, typeName } = ManageGroupsConstants;
  const { add, edit, hideGroup, remove, manageMembers } =
    useManageGroupActions(ManageGroupsConstants);
  const pageSize = 10;

  const toolbarActions = useArray(add);
  const itemActions = useArray(edit, hideGroup, remove, manageMembers);

  return (
    <CmsPage title={'Groups'}>
      <CmsItemListSection
        showSelect={false}
        showTotal={true}
        pageSize={pageSize}
        pageId={defaultPageId}
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={columns}
        itemApi={manageGroupsApi}
        additionalSearchFields={[
          {
            key: 'canAssign',
            parseBool: true,
            noSelectText: 'Show All',
            placeholder: 'Editable',
            type: 'select',
            variant: 'standard',
            options: [
              { label: 'Editable Groups', value: true },
              { label: 'Non-Editable Groups', value: false },
            ],
          },
          {
            key: 'groupType',
            noSelectText: 'Show All',
            placeholder: 'Type',
            type: 'select',
            variant: 'standard',
            options: [
              { label: 'Cohort', value: 'cohort' },
              { label: 'Registration', value: 'registration' },
              { label: 'No Type', value: 'none' },
            ],
          },
          { key: 'hidden', placeholder: 'Show Hidden', type: 'switch' },
        ]}
        searchPlaceholder={'Search by Group Name'}
      />
    </CmsPage>
  );
};

export default ManageGroupsListPage;
