import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';
import { Controller } from 'react-hook-form';
import useFieldProps from '../hooks/useFieldProps';
import FieldWrapper from './FieldWrapper';

const SelectSwitch = (props) => {
  const {
    defaultValue,
    fieldName,
    formContext,
    formFieldOptions,
    label,
    options,
    placeholder,
    updateView,
    variant = 'standard',
    disabled,
  } = props;
  const { control } = formContext;
  const { isRequired } = useFieldProps(props);
  return (
    <FieldWrapper {...props} showError controlProps showLabel>
      <Controller
        name={fieldName}
        control={control}
        rules={formFieldOptions}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Select
            {...field}
            variant={variant}
            label={label}
            required={isRequired}
            onChange={(e) => {
              field.onChange(e);
              updateView(e.target.value);
            }}
            disabled={disabled}
          >
            {placeholder && (
              <MenuItem value="" disabled>
                {placeholder}
              </MenuItem>
            )}
            {(options || []).map(({ value, label }) => (
              <MenuItem key={value} value={value} disabled={disabled}>
                {label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FieldWrapper>
  );
};

export default SelectSwitch;
