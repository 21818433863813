import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useFieldProps from '../hooks/useFieldProps';
import FieldWrapper from './FieldWrapper';
import FormFieldError from './FormFieldError';

const FormInput = (props) => {
  const {
    label,
    fieldName,
    isDateOnly = false,
    placeholder,
    formFieldOptions = {},
    formContext,
    fieldWidth,
  } = props;
  const [inputValue, setInputValue] = useState('');
  const {
    width,
    fieldError,
    value,
    isRequired,
    inputAttributes,
    variant = 'standard',
  } = useFieldProps(props, true);
  const { register, setValue } = formContext;
  const { onBlur, name } = register(fieldName, { validate: () => true, ...formFieldOptions });

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setInputValue(value);
    }
  }, [value]);

  const handleChange = async (e) => {
    const v = e.target.value;
    setInputValue(v);
    await setValue(fieldName, v, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  return (
    <FieldWrapper {...props}>
      <TextField
        label={label}
        variant={variant}
        value={inputValue}
        type={isDateOnly ? 'date' : 'datetime-local'}
        fullWidth={fieldWidth === 'full'}
        placeholder={placeholder}
        error={!!fieldError}
        helperText={fieldError ? <FormFieldError error={fieldError} /> : ''}
        onBlur={onBlur}
        name={name}
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
        {...inputAttributes}
        sx={{ width }}
        required={isRequired}
      />
    </FieldWrapper>
  );
};

export default FormInput;
