import { Box } from '@mui/material';
import React from 'react';
import ActionButton from '../../shared/actionButton/components/ActionButton';
import BackButton from '../../shared/cmsPage/components/BackButton';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import { AppUserConstants } from '../definitions';
import useAppUserCustomFields from '../hooks/useAppUserCustomFields';
import Spinner from '../../shared/spinner/components/Spinner';

const AppUserCustomFields = () => {
  const { addRow, handleSave, isLoading, PreRenderedTable } = useAppUserCustomFields();
  return (
    <CmsPage title={`App User Custom Fields`}>
      <div className="row">
        <BackButton
          pageName="App Users"
          navToParent
          navParams={[AppUserConstants.pluginId, 'default', AppUserConstants.editorPageName]}
        />
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Box sx={{ mt: 2 }}>
            <ActionButton type="button" label="Add Custom Field" onClick={addRow} />
          </Box>
          {PreRenderedTable}
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <ActionButton type="button" label="Save Custom Fields" onClick={handleSave} />
          </Box>
        </>
      )}
    </CmsPage>
  );
};

export default AppUserCustomFields;
