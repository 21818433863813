import React, { useState, useEffect } from 'react';
import qrScannerApi from '../api/qrScannerApi';
import { errorGrowl } from '../../store/rootThunks';
import log from '../../logger';
import { QrScannerConstants } from '../definitions';
import { useDispatch } from 'react-redux';
import { navigateToPluginPage } from '../../store/rootReducer';
import GenericSelect from '../../shared/genericInputs/GenericSelect';
import GenericDate from '../../shared/genericInputs/GenericDate';

export default function ScanLog() {
  const today = new Date().toISOString().split('T')[0];
  const [allScanners, setAllScanners] = useState([]);
  const [exportOptions, setExportOptions] = useState({
    startDate: today,
    endDate: today,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchScanners() {
      try {
        const { records } = await qrScannerApi.getScanners(0, undefined, {
          sort: {
            predicate: 'name',
          },
          search: {},
        });
        setAllScanners(records);
        setExportOptions({ ...exportOptions, scannerId: records[0].id });
      } catch (error) {
        log.error('Failed to fetch scanners.', error);
      }
    }

    fetchScanners();
  }, []);

  const handleStartDateChange = (e) => {
    setExportOptions({ ...exportOptions, startDate: e.target.value });
  };

  const handleEndDateChange = (e) => {
    setExportOptions({ ...exportOptions, endDate: e.target.value });
  };

  async function handleExportScans(event) {
    event.preventDefault();

    if (!exportOptions.scannerId) {
      errorGrowl({ message: 'No scanner specified', type: 'error' });
      return;
    }
    if (!exportOptions.startDate) {
      errorGrowl({ message: 'No Start Date Specified', type: 'error' });
      return;
    }
    if (!exportOptions.endDate) {
      errorGrowl({ message: 'No End Date Specified', type: 'error' });
      return;
    }
    if (exportOptions.endDate < exportOptions.startDate) {
      errorGrowl({ message: 'End Date must be later than Start Date', type: 'error' });
      return;
    }

    try {
      dispatch(
        navigateToPluginPage([
          QrScannerConstants.pluginId,
          QrScannerConstants.exportPageName,
          JSON.stringify(exportOptions),
        ])
      );
    } catch (error) {
      log.error('Failed to export scan log.', error);
      errorGrowl({ message: 'Failed to export scan log.', type: 'error' });
    }
  }

  return (
    <div>
      <div className="header row">
        <h1 className="page-title">QR Scanner Logs</h1>
      </div>

      <div className="module red row">
        <div className="module-header">
          <span className="module-title subtitle"> Export Scan Log </span>
        </div>
        <div className="col-xs-12">
          <div className="module-body">
            <form name="exportScanLogForm" onSubmit={handleExportScans}>
              <div className="row border-top border-bottom content">
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <GenericSelect
                    className="form-control"
                    label="Scanner"
                    value={exportOptions.scannerId || ''}
                    onChange={(e) =>
                      setExportOptions({ ...exportOptions, scannerId: e.target.value })
                    }
                    menuItems={allScanners}
                    size={{ width: '22em', height: 40 }}
                  />

                  <GenericDate
                    label="Start Date"
                    value={exportOptions.startDate}
                    onChange={handleStartDateChange}
                    fieldWidth="short"
                    required={true}
                  />

                  <GenericDate
                    label="End Date"
                    value={exportOptions.endDate}
                    min={exportOptions.startDate}
                    onChange={handleEndDateChange}
                    fieldWidth="short"
                    required={true}
                  />

                  <div style={{ paddingTop: '1.5em', marginLeft: 'auto' }}>
                    <button type="submit" className="btn">
                      Export
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
