import React from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsItemEditSection from '../../shared/cmsPage/components/CmsItemEditSection';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import { NavbarEditorConstants } from '../definitions';
import MenuItemEditor from './NavbarItemEditor';
import BreadcrumbLink from '../../shared/actionButton/components/BreadcrumbLink';

const MenuItemEditorPage = ({ menuId, itemId }) => {
  const { editorPageId, pluginId } = NavbarEditorConstants;
  const { pageTitle, onItemChange } = useEditPageTitle(itemId, 'NavBar Item', 'label');
  const parentPageTitle = 'NavBar';
  return (
    <>
      <BreadcrumbLink
        pluginId={pluginId}
        parentPageTitle={parentPageTitle}
        parentPageName={'menuEditorPage'}
        parentPageId={'navbar-editor-page'}
        parentPageArgs={[menuId]}
      />
      <CmsPage title={`${pageTitle} for ${parentPageTitle}`}>
        <CmsItemEditSection pageId={editorPageId}>
          <MenuItemEditor
            menuId={menuId}
            itemId={itemId}
            onItemChange={onItemChange}
            growlId={editorPageId}
          />
        </CmsItemEditSection>
      </CmsPage>
    </>
  );
};

export default MenuItemEditorPage;
