import * as React from 'react';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';

export default function GenericDate(props) {
  const {
    value,
    label,
    min,
    max,
    onChange,
    defaultValue = '',
    variant = 'standard',
    fieldWidth = 'normal',
    required = false,
  } = props;

  const width = (() => {
    switch (fieldWidth) {
      case 'tiny': {
        return '8em';
      }
      case 'short': {
        return '16em';
      }
      case 'normal': {
        return '32em';
      }
      case 'long':
      case 'full': {
        return '100%';
      }
      default: {
        if (typeof fieldWidth === 'number') {
          return `${fieldWidth}px`;
        }
        if (![null, undefined, ''].includes(fieldWidth)) {
          return fieldWidth;
        }
      }
    }
    return '32em';
  })();

  return (
    <Box component="form" sx={{ '& > :not(style)': { m: 1, width } }} noValidate autoComplete="off">
      <TextField
        label={label}
        InputLabelProps={{ shrink: true }}
        inputProps={{ min, max }}
        value={value}
        type="date"
        onChange={onChange}
        defaultValue={defaultValue}
        variant={variant}
        size={fieldWidth}
        required={required}
      />
    </Box>
  );
}
