import React from 'react';
import FieldWrapper from './FieldWrapper';

const FormInputTextArea = (props) => {
  const {
    fieldName,
    maxLength = 255,
    placeholder,
    formFieldOptions,
    cols,
    formContext,
    ...inputAttributes
  } = props;
  return (
    <FieldWrapper {...props} showError showLabel>
      <textarea
        className="form-control"
        placeholder={placeholder}
        maxLength={maxLength || 255}
        {...(!Number.isNaN(cols) && { cols })}
        {...inputAttributes}
        {...formContext.register(fieldName, formFieldOptions)}
      />
    </FieldWrapper>
  );
};

export default FormInputTextArea;
