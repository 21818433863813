import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import itrsMailLogApi from '../api/itrsMailLogApi';
import { BackToMailLog } from './BackButtons';

const ItemView = ({ title, value, render }) => {
  return (
    <li>
      <Typography variant="h6">{title}:</Typography>
      <br />
      {render ? (
        <Typography dangerouslySetInnerHTML={{ __html: value }} />
      ) : (
        <Typography>{value}</Typography>
      )}
    </li>
  );
};

const { loadRecord } = itrsMailLogApi;

const ItrsMailLogItemPage = ({ itemId }) => {
  const [item, setItem] = useState(null);

  useEffect(() => {
    const setRecords = async () => {
      const item = await loadRecord(itemId);
      setItem(item);
    };
    if (itemId) setRecords();
  }, [itemId]);

  if (!item) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <BackToMailLog />
      <CmsPage title={'Outgoing Mail Log Entry'}>
        <ul>
          <ItemView title="Mail Type" value={item.templateId} />
          <ItemView title="Date Sent" value={item.createdAt} />
          <ItemView title="From" value={item.from} />
          <ItemView title="To" value={item.to} />
          <ItemView title="Subject" value={item.subject} />
          <ItemView title="CC" value={item.cc} />
          <ItemView title="BCC" value={item.bcc} />
          <ItemView title="Body" value={item.body} render={true} />
        </ul>
      </CmsPage>
    </>
  );
};

export default ItrsMailLogItemPage;
