import * as yup from 'yup';

yup.addMethod(yup.array, 'unique', function (message, mapper = (a) => a, additionalItems = []) {
  return this.test('unique', message, function (list, context) {
    if (!Array.isArray(list) || list.length === 0) {
      return true;
    }

    const mappedList = list.map(mapper).concat(additionalItems);
    const uniqueItems = new Set();
    const duplicates = [];
    mappedList.forEach((item, index) => {
      if (uniqueItems.has(item)) {
        duplicates.push(index);
      } else {
        uniqueItems.add(item);
      }
    });

    if (duplicates.length > 0) {
      const errors = list.map((_, index) => {
        if (duplicates.includes(index)) {
          return context.createError({
            path: `${context.path}[${index}].field`,
            message,
          });
        }
        return true;
      });

      return new yup.ValidationError(errors, null, context.path);
    }

    return true;
  });
});
